import { useMemo } from 'react';
import { css } from '@emotion/react';
import { CartIcon, spacing, useTheme } from '@instacart/ids-core';
import { CartWidgetConfig } from 'widget-wrappers/types';
import { CartTotal } from 'shop-api-sdk';
import { getCurrencySymbol, centsToDollarsString } from '../utils';
import { useIsMobile } from '../hooks';
import { imageConfigToComponent } from '../utils';

export type CartButtonProps = {
  cartTotal?: CartTotal;
  cartItemsCount: number;
  openCart: () => void;
  configOverrides?: CartWidgetConfig;
};

const useStyles = (overrides?: CartWidgetConfig) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return useMemo(() => {
    const { styles } = overrides ?? {};
    const { colors } = theme;

    const btnHeight = isMobile
      ? styles?.button?.mobile?.height ??
        styles?.button?.height ??
        `${spacing.s48}px`
      : styles?.button?.height ?? `${spacing.s48}px`;
    const bgColor = isMobile
      ? styles?.button?.mobile?.backgroundColor ??
        styles?.button?.backgroundColor ??
        colors.brandSecondaryLight
      : styles?.button?.backgroundColor ?? colors.brandSecondaryLight;
    const btnPadding = isMobile
      ? styles?.button?.mobile?.padding ?? styles?.button?.padding ?? '0px 6px'
      : styles?.button?.padding ?? '0px 6px';
    const borderColor = styles?.button?.borderColor ?? colors.systemGrayscale80;
    const borderWidth = styles?.button?.borderWidth ?? `${spacing.s4 / 2}px`;
    const borderRadius =
      styles?.button?.borderRadius ?? `${theme.radius.r24}px`;

    const iconColor = isMobile
      ? styles?.cartIcon?.mobile?.fill ??
        styles?.cartIcon?.fill ??
        colors.brandSecondaryDark
      : styles?.cartIcon?.fill ?? colors.brandSecondaryDark;

    const badgeBgColor =
      styles?.badge?.backgroundColor ?? colors.systemSuccessExtraDark;
    const badgeBorderColor =
      styles?.badge?.borderColor ?? colors.systemGrayscale00;
    const badgeBorderWidth = styles?.badge?.borderWidth ?? '2px';
    const badgePadding = styles?.badge?.padding ?? '0px 7px';

    const floatingBadgeStyles = overrides?.floatingBadge
      ? css`
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${styles?.badge?.width ?? `${spacing.s24}px`};
          height: ${styles?.badge?.height ?? `${spacing.s24}px`};
          min-width: ${styles?.badge?.width ?? `${spacing.s24}px`};
          box-sizing: border-box;
          top: 0px;
          left: 100%;
          transform: translate(-60%, -40%);
          border: ${badgeBorderWidth} solid ${badgeBorderColor};
          background-color: ${badgeBgColor};
          padding: ${badgePadding};
        `
      : '';

    return {
      button: css`
        height: ${btnHeight};
        width: ${styles?.button?.width ?? '85px'};
        border-radius: ${borderRadius};
        border: ${borderWidth} solid ${borderColor};
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${styles?.button?.gap ?? `${spacing.s8}px`};
        background-color: ${bgColor};
        position: relative;
        margin: 0px;
        user-select: none;
        touch-action: manipulation;
        cursor: pointer;
        padding: ${btnPadding};

        &:focus {
          border: ${borderWidth} solid ${borderColor};
        }
        & > svg {
          fill: ${iconColor};
        }
        &:disabled: {
          cursor: default;
        }
        &:hover {
          background-color: ${overrides?.styles?.buttonHoverColor
            ?.backgroundColor ?? colors.systemGrayscale10};

          & > svg {
            fill: ${overrides?.styles?.buttonHoverColor?.fill ?? 'inherit'};
          }
          & > span {
            color: ${overrides?.styles?.buttonHoverColor?.fill ?? 'inherit'};
          }
        }
      `,
      badge: css`
        color: ${styles?.badge?.color ?? colors.brandSecondaryDark};
        font-size: ${styles?.badge?.fontSize ?? '15px'};
        font-weight: ${styles?.badge?.fontWeight ?? '15px'};
        ${floatingBadgeStyles}
      `,
      text: css`
        font-size: ${styles?.text?.fontSize ?? '14px'};
        font-weight: ${styles?.text?.fontWeight ?? '700'};
        color: ${styles?.text?.color ?? colors.brandSecondaryDark};
        padding: ${styles?.text?.padding ?? ''};
      `,
    };
  }, [overrides, theme, isMobile]);
};

export default function CartWidget({
  cartItemsCount,
  cartTotal,
  openCart,
  configOverrides,
}: CartButtonProps) {
  const styles = useStyles(configOverrides);
  const isMobile = useIsMobile();

  const total = useMemo(() => {
    if (isMobile || !configOverrides?.showText) return '';
    if (!cartTotal) return '$0.00';

    const symbol = getCurrencySymbol(cartTotal.currency);
    const amount = centsToDollarsString(cartTotal.amount_cents);
    return `${symbol}${amount}`;
  }, [cartTotal, configOverrides?.showText, isMobile]);

  const icon = useMemo(() => {
    if (isMobile && configOverrides?.cartMobileIcon) {
      const Icon = imageConfigToComponent(configOverrides.cartMobileIcon);
      return <Icon />;
    }
    if (configOverrides?.cartDesktopIcon) {
      const Icon = imageConfigToComponent(configOverrides.cartDesktopIcon);
      return <Icon />;
    }

    return <CartIcon size={24} />;
  }, [
    isMobile,
    configOverrides?.cartMobileIcon,
    configOverrides?.cartDesktopIcon,
  ]);

  return (
    <button
      css={styles.button}
      role="button"
      onClick={openCart}
      aria-label={`View Cart. Items in cart: ${cartItemsCount}`}
    >
      {icon}

      {configOverrides?.showText && !isMobile ? (
        <span css={styles.text}>{total}</span>
      ) : null}

      {(configOverrides?.showText &&
        configOverrides?.floatingBadge &&
        cartItemsCount > 0) ||
      !configOverrides?.showText ? (
        <span css={styles.badge}>{cartItemsCount}</span>
      ) : null}
    </button>
  );
}
