import { css } from '@emotion/react';
import { ButtonBase, responsive, spacing, useTheme } from '@instacart/ids-core';
import { RatingsConfig } from 'widget-wrappers/types';
import { memo, useCallback, useContext, useMemo } from 'react';
import StarRatings from './common/StarRatings';
import { roundRating } from './utils';
import { ITEM_RATINGS_REVIEWS_CONTAINER_ID } from './ItemRatingsReviews';
import ShadowRootContext from '../context/ShadowRootContext';
import { ProductRating } from 'shop-api-sdk';

export type ItemDetailRatingsProps = {
  productRating?: ProductRating;
  configOverrides?: RatingsConfig;
};

export const CONTAINER_TEST_ID = 'product-detail-ratings-container';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        display: flex;
        align-items: center;
        column-gap: ${spacing.s4}px;
        width: 100%;
        margin-top: ${spacing.s4}px;
      `,
      rating: css`
        ${theme.typography.bodyMedium1};

        ${responsive.down('r')} {
          ${theme.typography.bodySmall1}
        }
      `,
      ratingNumber: css`
        ${theme.typography.bodyMedium2};
        color: ${theme.colors.systemGrayscale50};
        background: transparent;
        text-decoration-line: underline;

        ${responsive.down('r')} {
          ${theme.typography.bodySmall2};
        }
      `,
    }),
    [theme],
  );
};

export default memo(function ItemDetailRatings({
  productRating,
  configOverrides,
}: ItemDetailRatingsProps) {
  const styles = useStyles();
  const { shadowRoot } = useContext(ShadowRootContext);

  const clickRating = useCallback(() => {
    const parent = (shadowRoot ?? document) as Document;
    const ratingComponent = parent?.getElementById(
      ITEM_RATINGS_REVIEWS_CONTAINER_ID,
    );
    if (ratingComponent) {
      ratingComponent.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [shadowRoot]);

  if (!productRating) return null;

  const { value, amount } = productRating;

  return (
    <div css={styles.root} data-testid={CONTAINER_TEST_ID}>
      <StarRatings rating={value} configOverrides={configOverrides} />

      <span css={styles.rating}>{roundRating(value)}</span>

      <ButtonBase css={styles.ratingNumber} onClick={clickRating}>
        {amount}
      </ButtonBase>
    </div>
  );
});
