import { useMemo } from 'react';
import useStoreInitializer from '../../hooks/useStoreInitializer';
import { StoreContext, StoreContextValue } from './StoreContext';

export default function StoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data } = useStoreInitializer();

  const contextValue = useMemo<StoreContextValue>(() => {
    if (!data || !data.shops?.length) return undefined; // AKA: unfetched

    // Per IDP, the first option represents the active shop based on SFX's session and context selection logic
    // Certain retailers have a specific warehouse for liquor and grocery due to requirements we should leverage
    // the default store behavior for that here, however, for the temp solution we will use the first shop
    // that matches the correct warehouse slug.
    const activeShop =
      data.shops.find(
        (shop) => shop.retailer_key === import.meta.env.VITE_RETAILER_SLUG,
      ) ?? data.shops[0];
    return {
      shops: data.shops,
      activeShopId: activeShop.id,
      activeFulfillmentOption: activeShop.fulfillment_option,
      postalCode: data.postal_code,
    };
  }, [data]);

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
}
