import { useContext, useMemo } from 'react';
import { useIsMobile } from 'sfx-app/hooks';
import ScrollableList from './ScrollableList';
import PaginatedList from './PaginatedList';
import { config } from '../../config';
import { StoreContext } from '../../common/context/store/StoreContext';
import { useProductIdList } from './custom-hooks';

type Props = {
  collectionId: string;
  hideViewAll: boolean;
  viewAllCustomLink: string;
};

export default function CollectionWrapper({
  collectionId,
  hideViewAll,
  viewAllCustomLink,
}: Props) {
  const isMobile = useIsMobile();

  const activeShopId = useContext(StoreContext)?.activeShopId;

  const productIds = useProductIdList(collectionId, activeShopId);

  const viewAllLink = useMemo(() => {
    const { apiBaseUrl, retailerSlug } = config;
    return hideViewAll !== true
      ? viewAllCustomLink ??
          `${apiBaseUrl}/store/${retailerSlug}/collections/${collectionId}`
      : null;
  }, [hideViewAll, viewAllCustomLink, collectionId]);

  return isMobile ? (
    <ScrollableList
      viewAllLink={viewAllLink}
      productIds={productIds}
      shopId={activeShopId}
    />
  ) : (
    <PaginatedList
      viewAllLink={viewAllLink}
      productIds={productIds}
      shopId={activeShopId}
    />
  );
}
