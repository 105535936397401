import { useMemo } from 'react';
import { css } from '@emotion/react';
import { responsive, spacing, useTheme } from '@instacart/ids-core';
import DetailLine from './DetailLine';
import { useProductDetail } from '../context/useProductDetail';
import ItemDetailRatings from '../../RatingsReviews/ItemDetailRatings';
import { RatingsConfig } from 'widget-wrappers/types';
import { ProductRating } from 'shop-api-sdk';

export type ItemDetailProps = {
  ratingsConfigOverrides?: RatingsConfig;
  productRating?: ProductRating;
};

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        margin-bottom: ${spacing.s16}px;
        color: ${theme.colors.systemGrayscale70};
      `,
      header: css`
        margin: 0px;

        span {
          display: flex;
          ${theme.typography.titleMedium}

          ${responsive.down('c')} {
            ${theme.typography.bodyMedium1}
          }
        }
      `,
      itemSize: css`
        ${theme.typography.bodyMedium1};
        color: ${theme.colors.systemGrayscale50};
        margin-right: ${spacing.s8}px;
        ${responsive.down('c')} {
          ${theme.typography.bodySmall1}
        }
      `,
      detailLines: css`
        margin-top: ${spacing.s16}px;
        display: none;
        ${responsive.up('l')} {
          display: block;
        }
      `,
      detailLine: css`
        border-bottom: 1px solid ${theme.colors.systemGrayscale20};
      `,
    }),
    [theme],
  );
};

export default function Details({
  ratingsConfigOverrides,
  productRating,
}: ItemDetailProps) {
  const styles = useStyles();
  const { detailAttrs, quantitySize, productName } = useProductDetail();
  const singleAttr = detailAttrs.length === 1;

  return (
    <div css={styles.root}>
      <h2 css={styles.header}>
        <span>{productName}</span>
      </h2>

      <ItemDetailRatings
        productRating={productRating}
        configOverrides={ratingsConfigOverrides}
      />

      {quantitySize ? (
        <div>
          <span css={styles.itemSize}>{quantitySize}</span>
        </div>
      ) : null}

      {Boolean(detailAttrs.length) && (
        <div css={styles.detailLines}>
          {detailAttrs.map((item, index) =>
            item.bodyString ? (
              <div css={!singleAttr && styles.detailLine} key={index}>
                <DetailLine
                  headerString={item.headerString}
                  showBody
                  bodyString={item.bodyString}
                  lineLimit={10}
                />
              </div>
            ) : null,
          )}
        </div>
      )}
    </div>
  );
}
