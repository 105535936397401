import SearchBarWidget from 'sfx-app/SearchBarWidget';
import { useEffect } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { useAutosuggestions, useTermFromSearchString } from './custom-hooks';
import { useConfig } from '../../common/hooks/useConfig';

export default function SearchBarWidgetWrapper() {
  const { configOverride } = useConfig();
  const { initTerm, submitTerm } = useTermFromSearchString();
  const { isLoading, suggestions, doFetch } = useAutosuggestions();

  const handleNewSearchTerm = useDebounceCallback((value: string) => {
    void doFetch(value);
  }, 300);

  useEffect(() => {
    void doFetch(initTerm);
  }, [doFetch, initTerm]);

  return (
    <SearchBarWidget
      initTerm={initTerm}
      configOverride={configOverride.searchBar}
      isLoadingSuggestions={isLoading}
      autosuggestions={suggestions}
      onSubmit={submitTerm}
      onChangeValue={handleNewSearchTerm}
    />
  );
}
