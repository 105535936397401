import { useCallback, useEffect, useRef, useState } from 'react';

// this is modified from lives in import { useTransitionIntoView } from '@instacart/ids-core/src/molecules/OverflowingStack/useTransitionIntoView';
// added a new prop slide Count that determines how many elements should be scrolled, and included the necessary factors to use the original hook in this hook.
// @see the original source: // https://github.com/instacart/instacart-design-system-web/blob/master/packages/core/src/molecules/OverflowingStack/useTransitionIntoView.ts
export function useTransitionIntoView<T extends HTMLElement = HTMLUListElement>(
  gap?: number,
) {
  const containerRef = useRef<T>(null);
  const currentIndex = useRef<number>(0);
  const [offset, setOffset] = useState<number | null>(null);

  /**
   * Scrolls the element into view.
   * @param determineScrollTarget Returns the target which will be scrolled into view
   */
  const transitionIntoView = useCallback(
    (elementIndex: number) => {
      if (!containerRef.current) return;

      const scrollLeftBeforeMove = containerRef.current.scrollLeft;

      const childrenArray = Array.from(
        containerRef.current.children ?? [],
      ) as HTMLElement[];

      const isForward = currentIndex.current < elementIndex;

      const scrollToMove = childrenArray
        .slice(
          isForward ? currentIndex.current : elementIndex,
          isForward ? elementIndex : currentIndex.current,
        )
        .reduce((acc, curr) => acc + curr.scrollWidth + (gap ?? 0), 0);

      if (scrollToMove <= 0) return;

      currentIndex.current = elementIndex;

      containerRef.current.scrollLeft =
        scrollLeftBeforeMove + scrollToMove * (isForward ? 1 : -1);

      if (window.matchMedia('(prefers-reduced-motion)').matches) {
        return;
      }

      const leftPosAfterMove = containerRef.current.scrollLeft;
      const scrollAmount = leftPosAfterMove - scrollLeftBeforeMove;

      setOffset(scrollAmount);
    },
    [gap],
  );

  const getTileProps = useCallback(
    (index: number) => {
      return index === 0
        ? {
            onTransitionEnd: () => setOffset(null),
            style: {
              ...(offset !== null && {
                marginLeft: offset,
              }),
              ...(offset === 0 && {
                transition: 'margin-left .2s ease-in-out',
              }),
            },
          }
        : {};
    },
    [offset],
  );

  useEffect(() => {
    if (offset !== 0 && offset !== null) {
      requestAnimationFrame(() => {
        setOffset(0);
      });
    }
  }, [offset]);

  return {
    scrollContainerRef: containerRef,
    offsetForTransition: offset,
    getTileProps,
    transitionIntoView,
  };
}
