import { fetchJson } from './fetch-utils';

const apiBaseUrl =
  import.meta.env.DEV && window.location.hostname.includes('localhost')
    ? ''
    : import.meta.env.VITE_SHOP_HOST_URL;

export type LocationData = {
  postal_code: string;
};

export type AddressData = {
  street_address: string;
  city?: string;
  state?: string;
  postal_code: string;
  country_code: 'US' | 'CA';
};

export type ShopContext = 'delivery' | 'pickup' | 'instore';

export type ShopData = {
  name: string;
  id: string;
  retailer_key?: string;
  fulfillment_option: ShopContext;
  address: AddressData;
  phone_number: string;
};

export type ShopsData = {
  shops: Array<ShopData>;
};

export const getUserLocation = async () => {
  return await fetchJson<LocationData>(
    `${apiBaseUrl}/idp/v1/users/self/location`,
    {
      credentials: 'include',
    },
  );
};

export const getShops = async (postalCode: string) => {
  return await fetchJson<ShopsData>(
    `${apiBaseUrl}/idp/v1/shops?${new URLSearchParams({ postal_code: postalCode }).toString()}`,
    {
      credentials: 'include',
    },
  );
};
