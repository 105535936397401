import { useMemo } from 'react';
import {
  CartIcon,
  DeliveryIcon,
  LoadingGenericAnimation,
  PickupIcon,
  StoreIcon,
  useTheme,
} from '@instacart/ids-core';
import { css } from '@emotion/react';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      loadingContainer: css`
        background-color: ${theme.colors.systemGrayscale00};
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 240px;
      `,
    }),
    [theme],
  );
};

export default function LoadingProduct() {
  const styles = useStyles();
  return (
    <div css={styles.loadingContainer}>
      <LoadingGenericAnimation
        icons={[
          <DeliveryIcon key="delivery" />,
          <StoreIcon key="store" />,
          <PickupIcon key="pickup" />,
          <CartIcon key="cart" />,
        ]}
      />
    </div>
  );
}
