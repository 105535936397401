import { css } from '@emotion/react';
import { spacing, useTheme } from '@instacart/ids-core';
import { memo, useMemo } from 'react';
import { RatingsConfig } from 'widget-wrappers/types';
import { useIsMobile } from '../../hooks';
import { roundRating } from '../utils';
import StarRatings from './StarRatings';
import RatingItem from './RatingItem';
import { ProductRating, ProductUnitRatings } from 'shop-api-sdk';

export type ItemRatingsProps = {
  productRating?: ProductRating;
  productUnitRatings?: ProductUnitRatings;
  configOverrides?: RatingsConfig;
};

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        flex: 5;
      `,
      title: css`
        ${theme.typography.titleMedium};
        margin-bottom: ${spacing.s24}px;
      `,
      averageRating: css`
        ${theme.typography.subtitleLarge};
      `,
      ratingsOverview: css`
        display: flex;
        justify-content: space-between;
      `,
      ratings: css`
        margin-right: ${spacing.s16}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `,
      ratingValue: css`
        ${theme.typography.bodyLarge1};
        font-weight: 600;
        font-size: 31px;
        margin-bottom: ${spacing.s16}px;
      `,
      ratingDiagram: css`
        margin: 0px;
        padding: 0px;
        list-style: none;
        width: 100%;
      `,
    }),
    [theme],
  );
};

export default memo(function ItemRatings({
  productRating,
  productUnitRatings,
  configOverrides,
}: ItemRatingsProps) {
  const styles = useStyles();
  const isMobile = useIsMobile();

  if (!productRating && !productUnitRatings) return null;

  return (
    <div css={styles.root}>
      <div css={styles.title}>Customer Reviews</div>
      {!isMobile && productRating && (
        <div>
          <span css={styles.averageRating}>
            Average rating: {roundRating(productRating.value)}
          </span>
          <span> ({productRating.amount})</span>
        </div>
      )}
      <div css={styles.ratingsOverview}>
        {isMobile && productRating && (
          <div css={styles.ratings}>
            <div css={styles.ratingValue}>
              {roundRating(productRating.value)}
            </div>
            <StarRatings
              rating={productRating.value}
              configOverrides={configOverrides}
            />
            <div>({productRating.amount})</div>
          </div>
        )}

        {productUnitRatings ? (
          <ul css={styles.ratingDiagram} data-testid="item-unit-ratings-list">
            {productUnitRatings.map((item) => (
              <li key={item.value}>
                <RatingItem data={item} configOverrides={configOverrides} />
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
});
