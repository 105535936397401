import { useCallback, useEffect, useState } from 'react';
import { Autosuggestions } from 'shop-api-sdk';
import { useSearchContext } from '../searchContext';

export const useHighlightedEntry = (autosuggestions: Autosuggestions) => {
  const { lastTypedText, setTypedText } = useSearchContext();
  const [highlightedEntryIndex, setHighlightedEntryIndex] = useState(-1);

  const setNextHighlightEntry = useCallback(() => {
    const index = highlightedEntryIndex + 1;
    const newIndex = index < autosuggestions.length ? index : 0;
    setTypedText(autosuggestions[newIndex].text);
    setHighlightedEntryIndex(newIndex);
  }, [autosuggestions, highlightedEntryIndex, setTypedText]);

  const setPrevHighlightEntry = useCallback(() => {
    const index = highlightedEntryIndex - 1;
    const newIndex = index >= 0 ? index : autosuggestions.length - 1;
    setTypedText(autosuggestions[newIndex].text);
    setHighlightedEntryIndex(newIndex);
  }, [autosuggestions, highlightedEntryIndex, setTypedText]);

  const resetHighlightEntry = useCallback(() => {
    setHighlightedEntryIndex(-1);
  }, []);

  useEffect(() => {
    setHighlightedEntryIndex(-1);
  }, [lastTypedText]);

  return {
    highlightedEntryIndex,
    setNextHighlightEntry,
    setPrevHighlightEntry,
    resetHighlightEntry,
  };
};
