import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ChevronDownIcon, spacing, useTheme } from '@instacart/ids-core';
import { IconButton, IconButtonProps } from '@instacart/ids-customers';
import { SideNavSectionContent, SideNavSectionDropdown } from '../../config';
import { imageConfigToComponent } from 'sfx-app/utils';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      shopMenuItemIcon: css`
        height: 24px;
        width: 24px;
        margin-right: ${spacing.s8}px;
      `,
      hr: css`
        display: block;
        border-top: 0.5px solid #ccc;
        margin: ${spacing.s8}px 0;
        padding: 0;
      `,
      itemName: css`
        padding-top: ${spacing.s8}px;
        padding-bottom: ${spacing.s8}px;
        margin-left: ${spacing.s16}px;
        margin-right: ${spacing.s8}px;
      `,
      itemLink: css`
        text-decoration: none;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        cursor: pointer;
        color: ${theme.colors.systemGrayscale80};
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: ${spacing.s4}px;
      `,
      shopMenuItemDropdownIcon: css`
        width: 100%;
        display: flex;
        justify-content: end;
      `,
      shopMenuItemSubmenu: css`
        display: flex;
        flex-direction: column;
        margin-left: ${spacing.s32}px;
        > a {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          width: 100%;
          color: ${theme.colors.systemGrayscale80};
          position: relative;
          text-decoration: none;
          cursor: pointer;
          padding-top: ${spacing.s4}px;
          padding-bottom: ${spacing.s4}px;
        }
      `,
    }),
    [theme],
  );
};

export default function SideNavSection({
  name,
  dropdown,
  icon,
  link,
}: SideNavSectionContent) {
  const styles = useStyles();
  const [showDropdown, setShowDropdown] = useState(false);

  const Icon = useMemo(() => imageConfigToComponent(icon), [icon]);

  const hasDropdown = Boolean(dropdown?.length);

  const handleOnClick = () => {
    if (hasDropdown) {
      setShowDropdown(!showDropdown);
    } else {
      if (link) window.location.assign(link);
    }
  };

  return (
    <div data-testid="side-nav-section">
      <div key={name} css={styles.itemName}>
        <div onClick={handleOnClick} css={styles.itemLink}>
          {icon && (
            <IconButton
              icon={Icon as IconButtonProps['icon']}
              accessibleLabel={name ?? ''}
              css={styles.shopMenuItemIcon}
            />
          )}
          {name}
          {hasDropdown && (
            <div css={styles.shopMenuItemDropdownIcon}>
              <ChevronDownIcon width={12} height={12} />
            </div>
          )}
        </div>
        {hasDropdown && showDropdown && (
          <div css={styles.shopMenuItemSubmenu}>
            {dropdown?.map((subItem: SideNavSectionDropdown) => (
              <a key={subItem?.name} href={subItem?.link}>
                {subItem?.name}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
