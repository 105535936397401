import { css } from '@emotion/react';
import { CloseIcon, responsive, spacing, useTheme } from '@instacart/ids-core';
import { useCallback, useMemo } from 'react';
import { ProfileMenuConfig } from 'widget-wrappers/types';
import ProfileWidgetSheetItem from './ProfileWidgetSheetItem';

type IProps = {
  baseUrl: string;
  overrides?: ProfileMenuConfig;
  onClose: (value: boolean) => void;
};

const useStyles = (overrides?: ProfileMenuConfig) => {
  const theme = useTheme();

  return useMemo(() => {
    const { systemGrayscale00 } = theme.colors;
    const { styles } = overrides ?? {};

    return {
      dropdownContainer: css`
        z-index: 9999;
        position: absolute;

        ${responsive.down('c')} {
          position: fixed;
          inset: 0;
        }
        ${styles?.sheet};
      `,
      dropdownPanel: css`
        align-items: flex-start;
        flex-direction: column;
        border-width: 2px;
        display: flex;
        width: 222px;
        box-shadow: 0px 0px ${spacing.s16}px rgba(0, 0, 0, 0.16);
        margin-bottom: ${spacing.s16}px;
        padding: ${spacing.s8}px;
        background-color: ${systemGrayscale00};
        position: relative;
        border-radius: ${styles?.sheetPanel?.borderRadius ?? '0px'};
        box-sizing: border-box;

        ${responsive.down('c')} {
          border-radius: ${spacing.s12}px;
          border-radius: 0px;
          width: 100%;
          height: 100%;
        }
      `,
      closeIconContainer: css`
        display: none;

        ${responsive.down('c')} {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 2px;
        }
      `,
      closeIcon: css`
        width: ${spacing.s24}px;
        height: ${spacing.s24}px;
        cursor: pointer;
      `,
    };
  }, [overrides, theme]);
};

export default function ProfileWidgetSheet({
  baseUrl,
  overrides,
  onClose,
}: IProps) {
  const styles = useStyles(overrides);

  const navigateTo = useCallback(
    (link: string, type?: string) => {
      if (type) {
        if (type === 'logout') {
          const logoutUrl = `${baseUrl}${link}?return_to=${encodeURIComponent(
            window.location.href,
          )}`;
          window.open(logoutUrl, '_self');
        } else if (type === 'external') {
          window.open(link, '_self');
        }
      } else {
        window.open(`${baseUrl + link}`, '_self');
      }
    },
    [baseUrl],
  );

  if (!overrides?.profileOptions?.length) return null;

  return (
    <div data-testid="dropdown-menu" css={styles.dropdownContainer}>
      <div
        aria-labelledby="dropdown-menu-panel"
        css={styles.dropdownPanel}
        id="dropdown"
        role="menu"
      >
        <span css={styles.closeIconContainer}>
          <CloseIcon css={styles.closeIcon} onClick={() => onClose(false)} />
        </span>
        {overrides?.profileOptions?.map((item, index) => (
          <ProfileWidgetSheetItem
            item={item}
            overrides={overrides}
            onClick={(link, type) => {
              if (link) navigateTo(link, type);
            }}
            key={`${item.label}-${index}`}
          />
        ))}
      </div>
    </div>
  );
}
