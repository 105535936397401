import { css } from '@emotion/react';
import { spacing, useTheme } from '@instacart/ids-core';
import { memo, useMemo } from 'react';
import { RatingsConfig } from 'widget-wrappers/types';
import StarRatings from './common/StarRatings';
import { ProductRating } from 'shop-api-sdk';

export type ItemCardRatingsProps = {
  productRating: ProductRating;
  configOverrides?: RatingsConfig;
};

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        display: flex;
        align-items: center;
        margin: ${spacing.s4}px 0;
        column-gap: ${spacing.s4}px;
        width: 100%;
      `,
      reviewCountLabel: css`
        color: ${theme.colors.systemGrayscale50};
        font-weight: ${theme.typography.bodySmall2.fontWeight};
        font-size: ${theme.typography.bodySmall2.fontSize}px;

        @supports (font-size: 1cqw) {
          font-size: min(${theme.typography.bodySmall2.fontSize}px, 7.5cqw);
        }
      `,
    }),
    [theme],
  );
};

export default memo(function ItemCardRatings({
  configOverrides,
  productRating,
}: ItemCardRatingsProps) {
  const styles = useStyles();
  const { amount, value } = productRating;

  return (
    <div css={styles.root} data-testid="product-rating-container">
      <StarRatings rating={value} configOverrides={configOverrides} />

      {amount > 0 && (
        <span
          css={styles.reviewCountLabel}
          aria-label={amount ? `${amount} reviews` : ''}
          data-test="reviewCountLabel"
        >
          ({amount})
        </span>
      )}
    </div>
  );
});
