import { useEffect, useState, useCallback } from 'react';
import {
  LocationData,
  ShopsData,
  getShops,
  getUserLocation,
} from 'shop-api-sdk';

export default function useStoreInitializer() {
  const [data, setData] = useState<(ShopsData & LocationData) | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchStoreData = useCallback(async () => {
    try {
      // First API call to get zip code/postal code for the user
      const location = await getUserLocation();

      // Second API call to get store using the location ID
      const listOfShops = await getShops(location.postal_code);
      // Set the data received from the second API call
      setData({ ...listOfShops, ...location });
      setLoading(false);
    } catch (err) {
      let message = 'Store context initialization failed';
      if (err instanceof Error) {
        message = err.message;
      }
      setError(message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void fetchStoreData();
  }, [fetchStoreData]);

  return { data, loading, error };
}
