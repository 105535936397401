import * as ReactDOM from 'react-dom/client';
import AppWrappers from './AppWrappers';
import WidgetPortalGun from './WidgetPortalGun';

/** NOTE: import this file for side-effects only */

/**
 * An auto-executing process that sets up the library so that it populates our
 * instacart elements with the corresponding widget's React code.
 */

if (
  // Don't emit the version number inside the IPP preview `<iframe>`.
  !window.frameElement
) {
  console.log('version---->', import.meta.env.VITE_VERSION);
}

function initialize() {
  const root = document.createElement('div');
  document.body.appendChild(root);

  ReactDOM.createRoot(root).render(
    <AppWrappers>
      <WidgetPortalGun />
    </AppWrappers>,
  );
}

// We only need the document.body to be available, this adds the root app for
// the rest of the component, to initialize the IDP session as soon as possible
if (document.body) {
  initialize();
} else {
  const observer = new MutationObserver(function () {
    if (document.body) {
      initialize();
      observer.disconnect();
    }
  });
  observer.observe(document.documentElement, { childList: true });
}
