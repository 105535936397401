import { MouseEvent, useMemo } from 'react';
import { css } from '@emotion/react';
import { SearchIcon, spacing, useTheme } from '@instacart/ids-core';
import { Autosuggestion } from 'shop-api-sdk';
import { useSearchContext } from '../searchContext';

function sanitizeStringForRegex(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      wrapper: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing.s16}px;
        color: ${theme.colors.systemGrayscale70};
      `,
      icon: css`
        flex-shrink: 0;
      `,
      image: css`
        width: ${spacing.s40}px;
        height: ${spacing.s40}px;
        border-radius: ${spacing.s4}px;
      `,
      textWrapper: css`
        display: flex;
        align-items: center;
        gap: ${spacing.s16}px;
        overflow: hidden;
      `,
      text: css`
        display: flex;
        align-items: center;
        overflow: hidden;

        > span {
          white-space: pre;

          &:last-child {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      `,
    }),
    [theme],
  );
};

type Props = {
  item: Autosuggestion;
};

export const SuggestionItem = ({ item }: Props) => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    lastTypedText,
    typedText,
    submitForm,
    setTypedText,
    setDropdownVisible,
    setLastTypedText,
  } = useSearchContext();

  const textArray = useMemo(() => {
    const finalValue = !lastTypedText ? typedText : lastTypedText;
    const { bodyMedium1, bodyMedium2 } = theme.typography;
    if (!finalValue) return [{ text: item.text, style: bodyMedium2 }];
    const safeString = sanitizeStringForRegex(finalValue);
    const regex = new RegExp(`(${safeString})`, 'ig');
    return item?.text
      ?.split(regex)
      .filter(Boolean)
      .map((letters) => {
        const index = letters.toLowerCase().indexOf(safeString.toLowerCase());
        return {
          text: letters,
          style: index >= 0 ? bodyMedium1 : bodyMedium2,
        };
      });
  }, [item.text, lastTypedText, theme.typography, typedText]);

  const handleClick = (evt: MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    setTypedText(item.text);
    setDropdownVisible(false);
    setLastTypedText(item.text);
    submitForm(item.text);
  };

  return (
    <div
      css={styles.wrapper}
      onClick={handleClick}
      data-testid="autosuggestion-item"
    >
      <div css={styles.textWrapper}>
        <img src={item.image_url} alt="" css={styles.image} />
        <div css={styles.text}>
          {textArray.map((ctx, i) => (
            <span key={i} css={ctx.style}>
              {ctx.text}
            </span>
          ))}
        </div>
      </div>
      <SearchIcon size={18} css={styles.icon} />
    </div>
  );
};
