import { css } from '@emotion/react';
import { useTheme, spacing } from '@instacart/ids-core';
import { useMemo } from 'react';
import { DietaryTagIconConfig } from 'widget-wrappers/types';
import { imageConfigToComponent } from '../utils';

type Props = {
  iconConfig?: DietaryTagIconConfig;
  labels: string[];
  /** Must 1-to-1 match `labels` length to be used */
  tags?: string[];
} & React.HTMLAttributes<HTMLDivElement>;

export default function DietaryTagBadge({
  iconConfig = {},
  labels,
  tags,
  ...divProps
}: Props) {
  const theme = useTheme();

  const styles = useMemo(
    () => ({
      dietaryTagGroup: css`
        color: ${theme.colors.systemGrayscale80};
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
      `,
      dietaryTag: css`
        background: ${theme.colors.brandSecondaryLight};
        border-radius: 4px;
        padding: 2px;
        margin-right: 4px;
        margin-top: 2px;
        display: inline-flex;
      `,
      iconGroup: css`
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: ${spacing.s4}px;
      `,
      tagText: css`
        white-space: nowrap;
      `,
    }),
    [theme],
  );

  // Sprouts rules - show one tag, then, e.g., ", +2" if there are more

  const firstLabel = labels[0];
  const firstTag = tags?.[0];
  if (firstTag && !(firstTag in iconConfig)) {
    console.warn(
      `Tag "${firstTag}" was not in the itemCard dietaryTagIcons config`,
    );
    // Catch tag errors in DEV
  }

  const tagIcon = firstTag != null && iconConfig[firstTag];
  const { icon, color } = tagIcon || {};

  const Icon = useMemo(() => imageConfigToComponent(icon), [icon]);

  if (labels.length === 0) return null;

  const additionalCountSuffix =
    labels.length > 1 ? `, +${labels.length - 1}` : '';

  return (
    <div css={styles.dietaryTagGroup} {...divProps}>
      <span css={styles.dietaryTag}>
        {Icon && (
          <span
            css={[
              styles.iconGroup,
              css`
                background-color: ${color};
              `,
            ]}
          >
            <Icon />
          </span>
        )}
        <span css={styles.tagText}>
          {firstLabel}
          {additionalCountSuffix}
        </span>
      </span>
    </div>
  );
}
