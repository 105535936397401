import { fetchJson } from './fetch-utils';

const apiBaseUrl =
  import.meta.env.DEV && window.location.hostname.includes('localhost')
    ? ''
    : import.meta.env.VITE_SHOP_HOST_URL;

export type UserData = {
  user_id: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  guest: boolean;
  metadata?: Record<string, unknown>;
};

export const getUser = async () => {
  return await fetchJson<UserData>(`${apiBaseUrl}/idp/v1/users/self`, {
    credentials: 'include',
  });
};
