import { useContext } from 'react';
import { SearchContext } from './SearchContext';

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('SearchContext is required');
  }

  return context;
};
