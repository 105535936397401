import { css } from '@emotion/react';
import { responsive, spacing, useTheme } from '@instacart/ids-core';
import { useMemo } from 'react';
import { useProductDetail } from '../../context/useProductDetail';

const useStyles = () => {
  const theme = useTheme();

  return useMemo(
    () => ({
      price: css`
        display: flex;
        align-items: flex-end;
        margin-bottom: ${spacing.s4}px;
        color: ${theme.colors.systemGrayscale70};
        ${theme.typography.titleMedium};
      `,
      onSalePrice: css`
        color: ${theme.colors.systemDetrimentalDark};
      `,
      onSaleBadge: css`
        color: ${theme.colors.systemDetrimentalDark};
        padding: ${spacing.s4 / 2}px ${spacing.s8}px;
        margin-top: -2px;
        border: 2px solid;
        border-radius: ${theme.radius.r4}px;
        ${theme.typography.bodyMedium1}
        ${responsive.down('c')} {
          padding: ${spacing.s4 / 2}px ${spacing.s4}px;
        }
      `,
      pricePerMeasure: css`
        ${theme.typography.bodyMedium1};
        color: ${theme.colors.systemGrayscale50};
      `,
      strikeThroughText: css`
        color: ${theme.colors.systemGrayscale50};
        text-decoration: line-through;
        padding: 0px ${spacing.s8}px;
        ${theme.typography.bodyLarge1};

        ${responsive.down('c')} {
          ${theme.typography.bodyMedium1}
        }
      `,
    }),
    [theme],
  );
};

export default function ItemPrice() {
  const { priceDetails } = useProductDetail();
  const { basePrice, finalPrice, offerLabel, pricePerMeasure } = priceDetails;

  const styles = useStyles();

  return (
    <div>
      <div css={styles.price}>
        {finalPrice ? (
          <span css={offerLabel && styles.onSalePrice}>{finalPrice}</span>
        ) : null}
        {basePrice ? (
          <span css={styles.strikeThroughText}>{basePrice}</span>
        ) : null}
        {offerLabel ? <span css={styles.onSaleBadge}>{offerLabel}</span> : null}
      </div>
      {pricePerMeasure ? (
        <div css={styles.pricePerMeasure}>{pricePerMeasure}</div>
      ) : null}
    </div>
  );
}
