import { type Image, type SVGImage } from '../../../widget-wrappers/config';
import SanitizedSvg, { type Props as SanitizedSvgProps } from './SanitizedSvg';

export const imageConfigToComponent = (
  image?: Image,
  fallback: SVGImage = () => null,
): SVGImage => {
  if (!image) {
    return fallback;
  } else if (typeof image === 'function') {
    return image;
  } else if (typeof image === 'object' && image.html) {
    const Component = (props: Omit<SanitizedSvgProps, 'html'>) => (
      <SanitizedSvg {...image} {...props} />
    );
    return Component;
  } else {
    throw new Error('Failed to convert image to component');
  }
};
