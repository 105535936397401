const apiBaseUrl =
  import.meta.env.DEV && window.location.hostname.includes('localhost')
    ? ''
    : import.meta.env.VITE_SHOP_HOST_URL;

export const init = async () => {
  const res = await fetch(`${apiBaseUrl}/idp/v1/init`, {
    method: 'POST',
    body: '{}',
    credentials: 'include',
  });
  if (!res.ok) {
    throw new Error(res.statusText);
  }
};
