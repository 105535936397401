import { createContext } from 'react';
import { ProductAvailability } from 'shop-api-sdk';

export type DetailLineItem = {
  headerString: string;
  bodyString: string;
};

export type PriceDetails = {
  basePrice: string | null;
  finalPrice: string | null;
  offerLabel: string | null;
  pricePerMeasure: string | null;
};

export type AddItemDetails = {
  selectOptions: {
    label: string;
    value: number;
  }[];
  onSubmit: () => void;
  onSelectQuantity: (value: number | string) => void;
  disableSubmitButton: boolean;
  currentQty?: number;
  customInput: boolean;
  selectButtonLabel: string;
  submitButtonLabel: string;
  increment: number;
  isInCartValue: boolean;
  cartQuantity: number;
};

type ContextState = {
  availability?: ProductAvailability;
  productName: string;
  detailAttrs: DetailLineItem[];
  quantitySize?: string;
  priceDetails: PriceDetails;
  addItemDetails: AddItemDetails;
};

export const ProductDetailContext = createContext<ContextState>({
  detailAttrs: [],
  priceDetails: {
    basePrice: null,
    finalPrice: null,
    offerLabel: null,
    pricePerMeasure: null,
  },
  productName: '',
  addItemDetails: {
    selectOptions: [],
    disableSubmitButton: false,
    customInput: false,
    selectButtonLabel: '',
    onSelectQuantity: () => {},
    onSubmit: () => {},
    increment: 1,
    isInCartValue: false,
    submitButtonLabel: '',
    cartQuantity: 0,
  },
});
