/// <reference types="../../src/vite-env.d.ts" />

// Config file generated by build process per retailer
import {
  type CSSProperties,
  type ComponentType,
  type FunctionComponent,
  type SVGProps as ReactSVGProps,
} from 'react';
import { type SVGIconProps, type ThemeOverrides } from '@instacart/ids-core';

export interface SVGProps extends ReactSVGProps<SVGSVGElement> {
  title?: string;
}

export type SVGImage =
  // Images imported from `*.svg?react`
  | FunctionComponent<SVGProps>
  // Icons imported from `@instacart/ids-core`
  | ComponentType<Omit<SVGIconProps, 'component'>>;

export type Image =
  // TODO: Remove `SVGImage` once all the retailers are migrated to IPP. It's
  // only possible to transfer images as `{ html: '...' }` with the remote
  // configs, so `SVGImage` would become unnecessary and add extra overhead to
  // type-checking and the JSON schema generated with `gen-carrot-config`.
  | SVGImage
  | {
      html: string;
    };

export interface Config {
  apiBaseUrl: string;
  retailerName: string;
  searchUrl: string;
  retailerSlug?: string;
}

export type RatingsConfig = Partial<{
  enableRatingsAndReviews: boolean;
  styles: Partial<{
    icon: Pick<CSSProperties, 'width' | 'height'>;
    filled: Pick<CSSProperties, 'fill'>;
    unfilled: Pick<CSSProperties, 'fill'>;
  }>;
}>;

export type AuthConfig = Partial<{
  label: string;
  avatarIcon: Image;
  showAvatarIcon: boolean;
  showUnderlineOnHover: boolean;
  styles: Partial<{
    button: Pick<
      CSSProperties,
      | 'backgroundColor'
      | 'borderColor'
      | 'borderStyle'
      | 'borderWidth'
      | 'borderRadius'
      | 'color'
      | 'fontSize'
      | 'fontWeight'
      | 'lineHeight'
      | 'padding'
      | 'height'
      | 'width'
    > &
      Partial<{
        mobile: Pick<CSSProperties, 'padding' | 'fontSize'>;
      }>;
    icon: Pick<CSSProperties, 'fill' | 'width' | 'height'>;
  }>;
}>;

export type IProfileOptionType = 'logout' | 'external';

export type IProfileOptions = Partial<{
  icon: Image;
  label: string;
  link: string;
  type: IProfileOptionType;
}>;

export type ProfileMenuConfig = Partial<{
  profileOptions: IProfileOptions[];
  showProfileIcon: boolean;
  showUserName: boolean;
  showUnderlineOnHover: boolean;
  showChevronIcon: boolean;
  clickUrlOnMobile: string;
  profileIcon: Image;
  styles: Partial<{
    container: Pick<
      CSSProperties,
      'backgroundColor' | 'width' | 'height' | 'padding'
    > &
      Partial<{
        mobile: Pick<CSSProperties, 'padding'>;
      }>;
    avatar: Pick<CSSProperties, 'fill' | 'width' | 'height'>;
    chevronIcon: Pick<CSSProperties, 'width' | 'height' | 'fill'>;
    name: Pick<CSSProperties, 'fontSize' | 'fontWeight' | 'color'>;
    sheet: Pick<CSSProperties, 'right'>;
    sheetPanel: Pick<CSSProperties, 'borderRadius'>;
    sheetItem: Partial<{
      mobile: Pick<CSSProperties, 'justifyContent'>;
    }>;
    sheetItemIcon: Pick<CSSProperties, 'width' | 'height' | 'fill'>;
    sheetItemLabel: Pick<CSSProperties, 'fontSize' | 'fontWeight' | 'color'>;
  }>;
  ippPreview: Partial<{
    isMenuOpen: boolean;
  }>;
}>;

export type SearchBarConfig = Partial<{
  placeholder: string;
  enableDropdownOnMobile: boolean;
  styles: Partial<{
    text: Pick<CSSProperties, 'color' | 'fontSize' | 'fontWeight'>;
    inputField: Pick<
      CSSProperties,
      'borderRadius' | 'backgroundColor' | 'height' | 'borderColor'
    >;
  }>;
  ippPreview: Partial<{
    isDropdownOpen: boolean;
    query: string;
  }>;
}>;

export type CartWidgetConfig = Partial<{
  cartItemsCount: number;
  cartDesktopIcon: Image;
  cartMobileIcon: Image;
  showShopCtaOnEmptyCart: string;
  showText: boolean;
  floatingBadge: boolean;
  styles: Partial<{
    button: Pick<
      CSSProperties,
      | 'backgroundColor'
      | 'borderColor'
      | 'borderWidth'
      | 'borderRadius'
      | 'padding'
      | 'height'
      | 'gap'
      | 'width'
    > &
      Partial<{
        mobile: Pick<CSSProperties, 'backgroundColor' | 'height' | 'padding'>;
      }>;
    buttonHoverColor: Pick<CSSProperties, 'backgroundColor' | 'fill'>;
    cartIcon: Partial<{
      mobile: Pick<CSSProperties, 'fill'>;
    }> &
      Pick<CSSProperties, 'fill'>;
    badge: Pick<
      CSSProperties,
      | 'width'
      | 'height'
      | 'backgroundColor'
      | 'borderColor'
      | 'borderWidth'
      | 'padding'
      | 'fontSize'
      | 'fontWeight'
      | 'color'
    >;
    text: Pick<CSSProperties, 'color' | 'fontSize' | 'fontWeight' | 'padding'>;
    ctaButton: Pick<
      CSSProperties,
      'backgroundColor' | 'color' | 'fontSize' | 'width'
    >;
  }>;
}>;

export type ProductCollectionsConfig = Partial<{
  styles: Partial<{
    chevronIcon: Pick<CSSProperties, 'fill' | 'width' | 'height'>;
  }>;
}>;

export type DietaryTagIconConfigEntry = Partial<{
  icon: Image;
  color: string;
}>;

/** Map from an enum "value" to label, icon, etc */
export type DietaryTagIconConfig = Partial<
  Record<string, DietaryTagIconConfigEntry>
>;

export type ItemCardConfig = Partial<{
  /** Ordinarily, only mobile has a collapsable add-to-cart button. This enables
   * it on desktop too. */
  alwaysCollapseAddToCart: boolean;
  /** Use if the price background color is too dark */
  useLightSalePriceBoxColor: boolean;
  /** Default if unspecified: 'line-through' */
  basePriceStyle: 'line-through' | 'prefix-reg';
  /** default: false */
  showSnapEligibility: boolean;
  /** Provide dietary tags as a way to map product detail data to labels,
   * colors, and icons */
  dietaryTagIcons: DietaryTagIconConfig;
  openProductDetailModalOnItemClick: boolean;
}>;

export type PublicApiConfig = Partial<{
  enableIcCartChangeEvent: boolean;
}>;

export type FulFillmentSelectorConfig = Partial<{
  displayOnly: boolean;
  ippPreview: Partial<{
    isDropdownOpen: boolean;
  }>;
}>;

export type SideNavSectionDropdown = Partial<{
  link: string;
  name: string;
}>;

export type SideNavSectionContent = Partial<{
  name: string;
  dropdown: Array<SideNavSectionDropdown>;
  icon: Image;
  link: string;
}>;

export type SideNavConfig = Partial<{
  sections: Array<Array<SideNavSectionContent | undefined>>;
  ippPreview: Partial<{
    isOpen: boolean;
  }>;
}>;

// Using `Partial<...>` to ensure that all config object properties are
// optional. Each nested type declaration should also use `Partial<...>`
// to communicate that each field is meant to be optional.
export type ThemeWithoutStyles = Partial<{
  authWidget: AuthConfig;
  cartWidget: CartWidgetConfig;
  fulfillmentSelector: FulFillmentSelectorConfig;
  itemCard: ItemCardConfig;
  mobileBreakpoint: number;
  productCollections: ProductCollectionsConfig;
  profileMenu: ProfileMenuConfig;
  ratings: RatingsConfig;
  searchBar: SearchBarConfig;
  sideNav: SideNavConfig;
}>;

// Make every nested object partial except for `Image` and `ThemeOverrides`.
// Source: https://stackoverflow.com/a/51365037
type RecursivelyPartialConfig<T> = {
  [P in keyof T]?: T[P] extends Image | ThemeOverrides | undefined
    ? T[P]
    : T[P] extends (infer U)[] | undefined
      ? RecursivelyPartialConfig<U>[]
      : T[P] extends object | undefined
        ? RecursivelyPartialConfig<T[P]>
        : T[P];
};

export type RootConfigs = RecursivelyPartialConfig<
  ThemeWithoutStyles & {
    enableRemoteConfig: boolean;
    styles: ThemeOverrides;
    publicApi: PublicApiConfig;
  }
>;

export const config: Config = {
  /* localhost server requests starting with '/idp' are proxied on to the real VITE_SHOP_HOST_URL value */
  apiBaseUrl:
    import.meta.env.DEV && window.location.hostname.includes('localhost')
      ? ''
      : import.meta.env.VITE_SHOP_HOST_URL,
  retailerName: import.meta.env.VITE_RETAILER_NAME,
  searchUrl: import.meta.env.VITE_SEARCH_URL,
  retailerSlug:
    import.meta.env.VITE_RETAILER_SLUG || import.meta.env.VITE_RETAILER_NAME,
};
