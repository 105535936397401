import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@instacart/ids-core';
import { css } from '@emotion/react';
import ProductDetail from 'sfx-app/ProductDetail';
import { CartContext } from '../context/carts/CartContext';
import useStoreInitializer from '../hooks/useStoreInitializer';
import { AddRatingReviewParams, Product, getProducts } from 'shop-api-sdk';
import { useConfig } from '../hooks/useConfig';
import { useAuth } from '../hooks/useAuth';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      widgetWrapper: css`
        position: relative;
        background-color: ${theme.colors.systemGrayscale00};
      `,
    }),
    [theme],
  );
};

type Props = {
  productId: string;
};

export default function ProductDetailCardWrapper({ productId }: Props) {
  const styles = useStyles();
  const {
    configOverride: { ratings: ratingsConfigOverrides },
  } = useConfig();
  const { authState, handleAuth } = useAuth();

  const { data } = useStoreInitializer();
  const [loading, setLoading] = useState(true);
  const [product, setProductDetail] = useState<Product | null>(null);
  const cartContextValue = useContext(CartContext);

  const loadProductDetail = useCallback(async () => {
    if (data?.shops[0].id) {
      try {
        setLoading(true);
        const { products } = await getProducts(data.shops[0].id, [productId]);
        setProductDetail(products[0]);
      } catch (error) {
        console.error('Failed to fetch product detail:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [data?.shops, productId]);

  const submitRatingReview = useCallback(
    (data?: AddRatingReviewParams) => {
      if (authState && data) {
        // TODO: submit rating and review data
      } else if (!authState) {
        handleAuth();
      }
    },
    [authState, handleAuth],
  );

  const loadMoreProductReviews = useCallback(() => {
    // TODO: call backend to load more product reviews
  }, []);

  const changeOrderForReview = useCallback(() => {
    // TODO: call backend to change order for reviews. value: string
  }, []);

  useEffect(() => {
    void loadProductDetail();
  }, [loadProductDetail]);

  return (
    <div css={styles.widgetWrapper}>
      <ProductDetail
        authorized={authState}
        item={product}
        cartContext={cartContextValue}
        loading={loading}
        ratingsConfigOverrides={ratingsConfigOverrides}
        onSubmitRatingReview={submitRatingReview}
        onLoadMoreReviews={loadMoreProductReviews}
        onChangeOrderForReviews={changeOrderForReview}
      />
    </div>
  );
}
