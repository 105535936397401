import { memo, useEffect, useRef } from 'react';
import { type SVGProps } from '../../../widget-wrappers/config';

// A list of potentially unsafe SVG tag names.
// Source: https://github.com/cure53/DOMPurify/blob/d507666f489d351d491c2abd10c6170a691f2e22/src/tags.js#L202-L225
const disallowedTagNames = [
  'animate',
  'color-profile',
  'cursor',
  'discard',
  'font-face',
  'font-face-format',
  'font-face-name',
  'font-face-src',
  'font-face-uri',
  'foreignobject',
  'hatch',
  'hatchpath',
  'mesh',
  'meshgradient',
  'meshpatch',
  'meshrow',
  'missing-glyph',
  'script',
  'set',
  'solidcolor',
  'unknown',
  'use',
];

export interface Props extends SVGProps {
  html: string;
}

// Renders `<svg>` from the provided HTML. It's a bit more complex than just
// using `dangerouslySetInnerHTML` because, with the latter, you can't get rid
// of the wrapping tag on which `dangerouslySetInnerHTML` is declared.
// See: https://github.com/facebook/react/issues/12014
export default memo(function SanitizedSvg({ html, ...props }: Props) {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const { current } = ref;
    if (!current) return;

    const template = document.createElement('template');
    template.innerHTML = html;

    const svg = template.content.firstElementChild as SVGSVGElement;

    // Remove disallowed elements from the template.
    const selector = disallowedTagNames.join(', ');
    const disallowedElements = svg.querySelectorAll(selector);
    for (const element of [...disallowedElements]) {
      element.remove();
    }

    // Remove attributes starting with `on` from all elements in the tempalte.
    const elements = [svg, ...svg.querySelectorAll('*')];
    for (const element of elements) {
      for (const { name } of element.attributes) {
        if (name.startsWith('on')) element.removeAttribute(name);
      }
    }

    // Reset the root resulting element by removing attributes and children.
    current.innerHTML = '';
    for (const { name } of [...current.attributes]) {
      if (name in props || (name === 'class' && 'className' in props)) continue;
      current.removeAttribute(name);
    }

    // Get the list of the remaining attributes defined by props.
    const propAttributes = [...current.attributes];

    // Remove the remaining attributes defined by props to append them later at the end for a higher priority.
    for (const { name } of [...current.attributes]) {
      current.removeAttribute(name);
    }

    // Copy attributes from the root template element to the root resulting element.
    for (const { name, value } of [...svg.attributes, ...propAttributes]) {
      current.setAttribute(name, value);
    }

    // Move children from the root template element to the root resulting element.
    for (const child of [...svg.children]) {
      current.append(child);
    }
  }, [props, html]);

  return <svg ref={ref} {...props} />;
});
