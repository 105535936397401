import { css } from '@emotion/react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import AuthWidgetWrapper from '../authWidgetWrapper/AuthWidgetWrapper';
import SideNavSection from './SideNavSection';
import { type SideNavConfig } from '../../config';
import { spacing } from '@instacart/ids-core';

type SideNavDrawerProps = {
  isOpen?: boolean;
  onClose: () => void;
} & SideNavConfig;

const useStyles = (
  isScrolling: boolean,
  isOpen?: boolean,
  overrides?: SideNavConfig,
) => {
  return useMemo(
    () => ({
      backdrop: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #00000080;
        opacity: ${isOpen ? 1 : 0};
        visibility: ${isOpen ? 'visible' : 'hidden'};
        transition:
          opacity 0.3s ease,
          visibility 0.3s;
        z-index: 9999;
      `,
      sideNav: css`
        position: ${!overrides?.ippPreview?.isOpen ? 'fixed' : 'absolute'};
        top: 0;
        left: 0;
        width: 70%;
        max-width: 70%;
        height: ${!overrides?.ippPreview?.isOpen ? '100vh' : undefined};
        background-color: white;
        box-shadow: 2px 0 5px #00000080;
        transform: ${isOpen ? 'translateX(0)' : 'translateX(-100%)'};
        transition: transform 0.3s ease;
        z-index: 99999;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
          &-thumb {
            background-color: ${isScrolling ? '#A1A1A1' : 'transparent'};
          }
        }
      `,
      closeBtn: css`
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 10px;
      `,
      content: css`
        padding-top: ${spacing.s16}px;
        line-height: 1.6;
        height: auto;
      `,
      section: css`
        margin: ${spacing.s12}px ${spacing.s16}px;
      `,
    }),
    [isOpen, isScrolling, overrides],
  );
};

export default memo(function SideNavDrawer({
  isOpen,
  onClose,
  ...overrides
}: SideNavDrawerProps) {
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const styles = useStyles(isScrolling, isOpen, overrides);
  const { sections } = overrides;

  const handleScroll = () => {
    if (!isScrolling) setIsScrolling(true);
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(scrollTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <>
          <div css={styles.backdrop} onClick={onClose}></div>
          <div
            css={styles.sideNav}
            ref={scrollContainerRef}
            onScroll={handleScroll}
          >
            <div css={styles.content}>
              <div css={styles.section}>
                <AuthWidgetWrapper />
              </div>
              <hr />
              {sections?.map((section, index) => (
                <div key={index}>
                  {section.map((item, index) => (
                    <SideNavSection key={index} {...item} />
                  ))}
                  {index < sections.length - 1 && <hr />}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
});
