import { useMemo } from 'react';
import { css } from '@emotion/react';
import {
  AddIcon,
  ButtonBase,
  SubtractIcon,
  TrashIcon,
  spacing,
  useTheme,
} from '@instacart/ids-core';
import { PrimaryButton } from '@instacart/ids-customers';
import { useProductDetail } from '../../context/useProductDetail';

const flexStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        ${flexStyle}
        gap: ${spacing.s12}px;
        margin: ${spacing.s16}px 0px;
      `,
      submitButton: css`
        max-width: 50%;
      `,
      stepper: css`
        ${flexStyle}
        gap: ${spacing.s4}px;
        height: 56px;
        background: ${theme.colors.systemGrayscale10};
        border-radius: ${theme.radius.r12}px;
      `,
      button: css`
        background: ${theme.colors.systemGrayscale10};
        padding: 0px ${spacing.s16}px;
      `,
      disabledButton: css`
        opacity: 0.4;
      `,
      quantity: css`
        ${theme.typography.bodyLarge1};
        color: ${theme.colors.systemGrayscale70};
        white-space: nowrap;
      `,
    }),
    [theme],
  );
};

export default function QuantityStepper() {
  const styles = useStyles();
  const { addItemDetails } = useProductDetail();
  const {
    cartQuantity,
    currentQty,
    disableSubmitButton,
    increment,
    isInCartValue,
    onSelectQuantity,
    onSubmit,
    selectButtonLabel,
    submitButtonLabel,
  } = addItemDetails;

  const allowDecrement = useMemo(
    () =>
      currentQty &&
      (cartQuantity > 0
        ? currentQty - increment >= 0
        : currentQty - increment > 0),
    [cartQuantity, currentQty, increment],
  );

  const allowIncrement = useMemo(
    () => !currentQty || currentQty < 100,
    [currentQty],
  );

  const onDecrement = () => {
    if (allowDecrement && currentQty) {
      onSelectQuantity(currentQty - increment);
    }
  };

  const onIncrement = () => {
    if (allowIncrement) {
      onSelectQuantity((currentQty ?? 0) + increment);
    }
  };

  const DecrementIcon = useMemo(
    () =>
      cartQuantity > 0 && (!currentQty || currentQty <= increment)
        ? TrashIcon
        : SubtractIcon,
    [currentQty, increment, cartQuantity],
  );

  return (
    <div css={styles.root}>
      <div css={styles.stepper}>
        <ButtonBase
          css={[styles.button, !allowDecrement && styles.disabledButton]}
          onClick={onDecrement}
          disabled={!allowDecrement}
          data-testid="decrement-button"
        >
          <DecrementIcon
            color={allowDecrement ? 'systemGrayscale70' : 'systemGrayscale30'}
            size={24}
          />
        </ButtonBase>
        <span css={styles.quantity}>{selectButtonLabel}</span>
        <ButtonBase
          css={[styles.button, !allowIncrement && styles.disabledButton]}
          onClick={onIncrement}
          disabled={!allowIncrement}
          data-testid="increment-button"
        >
          <AddIcon color="systemGrayscale70" size={24} />
        </ButtonBase>
      </div>
      <PrimaryButton
        css={styles.submitButton}
        onClick={onSubmit}
        disabled={disableSubmitButton || isInCartValue}
        data-testid="submit-button"
      >
        {submitButtonLabel}
      </PrimaryButton>
    </div>
  );
}
