import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { type SearchBarConfig } from 'widget-wrappers/types';
import { SearchContext } from './SearchContext';

type Props = {
  term: string;
  configOverride?: SearchBarConfig;
  onSubmit: (value: string) => void;
};

export const SearchBarContextProvider = ({
  children,
  term,
  configOverride,
  onSubmit,
}: PropsWithChildren<Props>) => {
  const [typedText, setTypedText] = useState(term);
  const [lastTypedText, setLastTypedText] = useState(term);
  const [dropdownVisible, setDropdownVisible] = useState(
    configOverride?.ippPreview?.isDropdownOpen ?? false,
  );

  const submitForm = useCallback(
    (value: string) => {
      onSubmit(value);
    },
    [onSubmit],
  );

  const contextState = useMemo(
    () => ({
      typedText,
      setTypedText,
      lastTypedText,
      setLastTypedText,
      dropdownVisible,
      setDropdownVisible,
      submitForm,
    }),
    [dropdownVisible, lastTypedText, submitForm, typedText],
  );

  return (
    <SearchContext.Provider value={contextState}>
      {children}
    </SearchContext.Provider>
  );
};
