import { css } from '@emotion/react';
import { RatingIcon, responsive, spacing, useTheme } from '@instacart/ids-core';
import { memo, useMemo } from 'react';
import { RatingsConfig } from 'widget-wrappers/types';
import { getColor } from '../../utils';
import { useIsMobile } from '../../hooks';
import { ProductUnitRating } from 'shop-api-sdk';

export type RatingItemProps = {
  data: ProductUnitRating;
  configOverrides?: RatingsConfig;
};

const useStyles = (percent: number, overrides?: RatingsConfig) => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing.s4}px;
        margin: ${spacing.s12}px 0px;

        ${responsive.down('c')} {
          margin: 0px;
        }
      `,
      value: css`
        ${theme.typography.bodyMedium1};
        display: flex;
        align-items: center;
        gap: ${spacing.s4}px;
      `,
      star: css`
        width: ${overrides?.styles?.icon?.width ?? `${spacing.s16}px`};
        height: ${overrides?.styles?.icon?.height ?? `${spacing.s16}px`};
        fill: ${getColor(
          theme,
          overrides?.styles?.filled?.fill ?? 'brandExpressRegular',
        )};
      `,
      amount: css`
        ${theme.typography.bodyMedium2};
        margin-left: ${spacing.s8}px;
        color: ${theme.colors.systemGrayscale50};
        text-align: right;
        width: ${spacing.s32}px;
      `,
      wrapper: css`
        flex-grow: 1;
      `,
      bar: css`
        height: ${spacing.s8}px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: ${spacing.s24}px;
        background: ${getColor(
          theme,
          overrides?.styles?.unfilled?.fill ?? 'systemGrayscale30',
        )};
      `,
      percent: css`
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: ${percent ?? 0}%;
        border-radius: ${spacing.s24}px;
        background: ${getColor(
          theme,
          overrides?.styles?.filled?.fill ?? 'brandExpressRegular',
        )};
      `,
    }),
    [percent, theme, overrides],
  );
};

export default memo(function RatingItem({
  data,
  configOverrides,
}: RatingItemProps) {
  const starRating = Number((data.value / 20).toFixed(0));
  const styles = useStyles(data.percent, configOverrides);
  const isMobile = useIsMobile();

  return (
    <div css={styles.root}>
      <div css={styles.value}>
        <span>{starRating}</span>
        <RatingIcon css={styles.star} />
      </div>
      <div css={styles.wrapper}>
        <div css={styles.bar}>
          <div css={styles.percent} />
        </div>
      </div>
      {!isMobile && <div css={styles.amount}>{data.amount}</div>}
    </div>
  );
});
