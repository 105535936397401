import {
  EmptyState,
  EmptyStateArtwork,
  EmptyStateDescription,
  EmptyStateGenericImage,
  EmptyStateTitle,
} from '@instacart/ids-core';

export default function EmptyProduct() {
  return (
    <EmptyState>
      <EmptyStateArtwork src={EmptyStateGenericImage} />
      <EmptyStateTitle>Item Unavailable</EmptyStateTitle>
      <EmptyStateDescription>
        Sorry, the item is not available at the current retailer or in the
        current location.
      </EmptyStateDescription>
    </EmptyState>
  );
}
