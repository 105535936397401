import { useMemo } from 'react';
import { css } from '@emotion/react';
import {
  AccountIcon,
  ButtonBase,
  useTheme,
  responsive,
  spacing,
} from '@instacart/ids-core';
import { AuthConfig } from 'widget-wrappers/types';
import { imageConfigToComponent } from '../utils';

const hoverStyles = css`
  &:after {
    bottom: 6px;
    left: 0px;
    content: '';
    cursor: pointer;
    display: block;
    height: 1px;
    position: absolute;
    transition: width 0.25s ease-in-out;
    width: 0;
  }

  &:hover {
    &:after {
      background-color: currentColor;
      width: 100%;
    }
  }
`;

const useStyles = (overrides?: AuthConfig) => {
  const theme = useTheme();

  return useMemo(() => {
    const { styles, showUnderlineOnHover } = overrides ?? {};
    const {
      colors: { systemGrayscale00, brandPrimaryRegular },
    } = theme;

    const buttonBg = styles?.button?.backgroundColor ?? systemGrayscale00;
    return {
      button: css`
        background-color: ${buttonBg};
        color: ${styles?.button?.color ?? brandPrimaryRegular};
        font-family: inherit;
        font-size: ${styles?.button?.fontSize ?? '14px'};
        font-weight: ${styles?.button?.fontWeight ?? '700'};
        line-height: ${styles?.button?.lineHeight || '20px'};
        width: ${styles?.button?.width ?? '172px'};
        height: ${styles?.button?.height ?? '48px'};
        border-color: ${styles?.button?.borderColor ?? brandPrimaryRegular};
        border-width: ${styles?.button?.borderWidth ?? '1px'};
        border-style: ${styles?.button?.borderStyle ?? 'solid'};
        border-radius: ${`${styles?.button?.borderRadius ?? `${theme.radius.r24}px`}`};
        padding: ${styles?.button?.padding ?? '10px'};

        ${responsive.down('c')} {
          font-size: ${styles?.button?.mobile?.fontSize ?? '12px'};
          padding: ${styles?.button?.mobile?.padding};
        }

        ${showUnderlineOnHover ? hoverStyles : ''}

        & svg {
          width: ${styles?.icon?.width ?? '24px'};
          height: ${styles?.icon?.height ?? '24px'};
          fill: ${styles?.icon?.fill ?? brandPrimaryRegular};
        }
      `,
      content: css`
        display: flex;
        align-items: center;
        gap: ${spacing.s8}px;
        justify-content: center;
      `,
    };
  }, [overrides, theme]);
};

export type AuthWidgetProps = {
  onPress: () => void;
  configOverrides?: AuthConfig;
};

export default function AuthWidget({
  onPress,
  configOverrides,
}: AuthWidgetProps) {
  const styles = useStyles(configOverrides);

  const Icon = useMemo(
    () => imageConfigToComponent(configOverrides?.avatarIcon, AccountIcon),
    [configOverrides?.avatarIcon],
  );

  return (
    <ButtonBase styles={styles} onClick={onPress}>
      {configOverrides?.showAvatarIcon ? <Icon aria-hidden /> : null}
      {configOverrides?.label}
    </ButtonBase>
  );
}
