import { fetchJson } from './fetch-utils';

const apiBaseUrl =
  import.meta.env.DEV && window.location.hostname.includes('localhost')
    ? ''
    : import.meta.env.VITE_SHOP_HOST_URL;

export type CartsPersonalType = {
  id: string;
  item_count: number;
};

export type CartsPersonalData = {
  carts: CartsPersonalType[];
};

export type CartItem = {
  product_id: string;
  quantity: number;
  quantity_type?: string;
};

export type CartTotal = {
  amount_cents: number;
  currency: string;
};

/** Note - this may have additional items added as siblings in the future */
export type CartItems = {
  items: CartItem[];
};

export const getCartsPersonal = async () => {
  return await fetchJson<CartsPersonalData>(
    `${apiBaseUrl}/idp/v1/carts/personal`,
    {
      credentials: 'include',
    },
  );
};

/** From External Shopping API Specification ERD:
 *
 * API to update a list of items to the shopping cart. The request only
 * contains the cart items which need to be updated (however, the quantity of
 * the item is the target qty instead of the delta. For example, if item A in
 * the cart has qty as 5, and we want to add one more count to item A, in the
 * update cart item API request, we should pass the qty value as 6 instead of
 * 1). If the quantity is set to 0, the item will be removed from the cart.
 */
export const updateCartItems = async (
  cartId: string,
  shopId: string,
  cartItem: CartItem,
) => {
  return await fetchJson<CartsPersonalType>(
    `${apiBaseUrl}/idp/v1/carts/${cartId}/items`,
    {
      method: 'PUT',
      credentials: 'include',
      // NOTE: this PUT endpoint only updates items provided, by product_id
      body: JSON.stringify({ shop_id: shopId, cart_items: [cartItem] }),
    },
  );
};

export const getCartItems = async (cartId: string) => {
  return await fetchJson<CartItems>(
    `${apiBaseUrl}/idp/v1/carts/${cartId}/items`,
    {
      credentials: 'include',
    },
  );
};

export const getCartTotal = async (cartId: string, shopId: string) => {
  return await fetchJson<{ total: CartTotal }>(
    `${apiBaseUrl}/idp/v1/carts/${cartId}/total?shop_id=${shopId}`,
    {
      credentials: 'include',
    },
  );
};
