import { css } from '@emotion/react';
import {
  ButtonBase,
  ConfirmIcon,
  DecreaseIcon,
  VisuallyHidden,
  elevation,
  spacing,
  useTheme,
} from '@instacart/ids-core';
import { PrimaryButton } from '@instacart/ids-customers';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useProductDetail } from '../../context/useProductDetail';
import { useDetectCloseEvent } from '../../../hooks/useDetectCloseEvent';

const useStyles = (isInCartValue: boolean) => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        position: relative;
        margin: ${spacing.s16}px 0px;
      `,
      selectButton: {
        button: css`
          width: 100%;
          height: ${spacing.s48}px;
          color: ${isInCartValue
            ? theme.colors.systemGrayscale00
            : theme.colors.systemGrayscale70};
          border-radius: ${theme.radius.r12}px;
          background-color: ${isInCartValue
            ? theme.colors.brandPrimaryRegular
            : theme.colors.systemGrayscale10};
          margin-bottom: ${spacing.s8}px;
          ${theme.typography.bodyLarge1};

          &:hover {
            background-color: ${isInCartValue
              ? theme.colors.brandPrimaryDark
              : theme.colors.systemGrayscale20};
          }
        `,
        content: css`
          display: flex;
          algin-items: center;
          justify-content: space-between;
          padding: 0px ${spacing.s12}px;
        `,
      },
      dropdownWrapper: css`
        position: relative;
      `,
      dropdown: css`
        ${elevation.low.shadow};
        color: ${theme.colors.systemGrayscale50};
        padding: ${spacing.s8}px;
        display: flex;
        flex-direction: column;
        z-index: 101;
        border-radius: ${theme.radius.r12}px;
        background-color: ${theme.colors.systemGrayscale00};
        list-style: none;
        margin: 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
      `,
      selectOption: css`
        height: 38px;
        border: none;
        border-radius: ${theme.radius.r4}px;
        color: ${theme.colors.systemGrayscale50};
        background-color: ${theme.colors.systemGrayscale00};
        list-style: none;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: ${spacing.s8}px;
        ${theme.typography.bodyMedium1};

        &:focus,
        &:hover {
          background-color: ${theme.colors.systemGrayscale20};
        }
      `,
      selectedOption: css`
        color: ${theme.colors.systemGrayscale70};
      `,
      inputWrapper: css`
        width: 100%;
        position: relative;
        margin-bottom: ${spacing.s8}px;
      `,
      input: css`
        height: ${spacing.s48}px;
        width: 100%;
        border-radius: ${theme.radius.r12}px;
        background-color: ${theme.colors.systemGrayscale00};
        padding: ${spacing.s4}px ${spacing.s12}px;
        color: ${theme.colors.systemGrayscale70};
        ${theme.typography.bodyLarge1};
        box-sizing: border-box;

        &:placeholder {
          ${theme.typography.bodyMedium2}
        }
      `,
    }),
    [isInCartValue, theme],
  );
};

export default function QuantityDropdown() {
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { addItemDetails } = useProductDetail();

  const {
    currentQty,
    customInput,
    disableSubmitButton,
    increment,
    isInCartValue,
    onSelectQuantity,
    onSubmit,
    selectButtonLabel,
    selectOptions,
    submitButtonLabel,
  } = addItemDetails;
  const styles = useStyles(isInCartValue);

  const handleClickButton = () => setIsDropdownOpen(!isDropdownOpen);

  const handleClickOption = useCallback(
    (value: number | string) => {
      onSelectQuantity(value);
      setIsDropdownOpen(false);
    },
    [onSelectQuantity],
  );

  const handleHideDropdown = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  useDetectCloseEvent(
    {
      ref: dropdownWrapperRef,
      attachEvent: isDropdownOpen,
    },
    handleHideDropdown,
  );

  return (
    <div css={styles.root} ref={dropdownWrapperRef}>
      <VisuallyHidden id="qty-label">Quantity</VisuallyHidden>
      {!customInput ? (
        <ButtonBase
          styles={styles.selectButton}
          role="combobox"
          aria-haspopup="listbox"
          aria-labelledby="qty-label selected-text"
          aria-controls="quantity-dropdown"
          aria-expanded={isDropdownOpen}
          onClick={handleClickButton}
        >
          <span />
          <span>{selectButtonLabel}</span>
          <DecreaseIcon
            size={24}
            color={isInCartValue ? 'systemGrayscale00' : 'systemGrayscale70'}
          />
        </ButtonBase>
      ) : (
        <div css={styles.inputWrapper}>
          <input
            type="number"
            placeholder="Add amount"
            aria-label="Add amount"
            onChange={(e) => {
              e.preventDefault();
              onSelectQuantity(e.target.value);
            }}
            min={0}
            max={100}
            step={increment}
            maxLength={5}
            css={styles.input}
            data-testid="custom-input"
          />
        </div>
      )}

      {isDropdownOpen && !customInput ? (
        <div css={styles.dropdownWrapper}>
          <ul css={styles.dropdown} id="quantity-dropdown">
            {selectOptions.map((option) => {
              const selected = option.value === currentQty;
              return (
                <li
                  id={`option-${option.value}`}
                  key={`option-${option.value}`}
                  role="option"
                  value={option.value}
                  onClick={() => void handleClickOption(option.value)}
                  css={[styles.selectOption, selected && styles.selectedOption]}
                >
                  <span>{option.label}</span>
                  {selected && (
                    <ConfirmIcon size={20} color="systemSuccessRegular" />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {!isInCartValue || customInput ? (
        <PrimaryButton
          disabled={disableSubmitButton}
          onClick={onSubmit}
          data-testid="submit-button"
        >
          {submitButtonLabel}
        </PrimaryButton>
      ) : null}
    </div>
  );
}
