import ProductDetailCardWrapper from '../../common/components/ProductDetailCardWrapper';

const getProductIdFormURL = () => {
  let match = window.location.href.match(/\/products\/(\d+)(\?.*)?$/);
  if (match) {
    return match[1];
  }
  match = window.location.href.match(/#ic-pdp-(\d+)/);
  if (match) {
    return match[1];
  }
  return null;
};

export default function ProductDetailWrapper() {
  const productId = getProductIdFormURL();

  if (!productId) {
    return null;
  }

  return <ProductDetailCardWrapper productId={productId} />;
}
