import { PropsWithChildren, useMemo } from 'react';
import { Product } from 'shop-api-sdk';
import { CartContextValue } from 'widget-wrappers/types';
import { ProductDetailContext } from './ProductDetailContext';
import { useQuantitySize } from '../hooks/useQuantitySize';
import { useItemDetails } from '../hooks/useItemDetails';
import { useAddItem } from '../hooks/useAddItem';
import { usePriceDetails } from '../hooks/usePriceDetails';

type Props = {
  product: Product;
  cartContext: CartContextValue;
};

export default function ProductDetailProvider({
  children,
  cartContext,
  product,
}: PropsWithChildren<Props>) {
  const addItemDetails = useAddItem(
    product.id,
    cartContext,
    product.shop_level_attributes,
  );
  const detailAttrs = useItemDetails(product.shop_level_attributes?.details);
  const quantitySize = useQuantitySize(product.shop_level_attributes);
  const priceDetails = usePriceDetails(product.shop_level_attributes);

  const state = useMemo(
    () => ({
      availability: product.shop_level_attributes?.availability,
      addItemDetails,
      detailAttrs,
      priceDetails,
      productName: product.name,
      quantitySize,
    }),
    [
      addItemDetails,
      detailAttrs,
      priceDetails,
      product.name,
      product.shop_level_attributes?.availability,
      quantitySize,
    ],
  );

  return (
    <ProductDetailContext.Provider value={state}>
      {children}
    </ProductDetailContext.Provider>
  );
}
