import { Unit } from 'shop-api-sdk';

export const unitToText = (
  count: number,
  unit?: Unit,
  showEachUnit?: boolean,
) => {
  let unitText = '';
  if (unit === 'EACH') {
    unitText = showEachUnit ? 'ct' : '';
  } else if (unit === 'PACKAGE') {
    unitText = count > 1 ? 'pkgs' : 'pkg';
  } else if (unit == 'LB') {
    unitText = count > 1 ? 'lbs' : 'lb';
  }
  return unitText.toLowerCase();
};

export const valueWithUnit = (count: number, unit?: Unit) => {
  const unitText = unitToText(count, unit, true);

  return `${count} ${unitText.toLowerCase()}`;
};

/** Rounds a number to a precise decimal number or fraction, defaulting to 4
 * digits (10000)
 */
export function roundToPrecision(n: number, roundingFactor = 10000) {
  return Math.round(n * roundingFactor) / roundingFactor;
}

export function centsToDollarsString(n: number) {
  return (n / 100).toString();
}
