import { fetchJson } from './fetch-utils';

const apiBaseUrl =
  import.meta.env.DEV && window.location.hostname.includes('localhost')
    ? ''
    : import.meta.env.VITE_SHOP_HOST_URL;

export type Autosuggestion = {
  text: string;
  image_url: string;
  type?: string;
};

export type Autosuggestions = Array<Autosuggestion>;

type AutosuggestionParams = {
  term: string;
  limit: number;
};
type AutosuggestionResponse = {
  autosuggestions: Autosuggestions;
};
export const getAutosuggestions = async (
  params: AutosuggestionParams,
  init: RequestInit,
): Promise<Autosuggestions> => {
  const { term, limit } = params;
  const url = `${apiBaseUrl}/idp/v1/autosuggestions?query=${term}&limit=${limit}`;
  const { autosuggestions } = await fetchJson<AutosuggestionResponse>(url, {
    ...init,
    credentials: 'include',
  });

  return autosuggestions;
};
