import { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  AccountIcon,
  ChevronRightIcon,
  responsive,
  spacing,
  useTheme,
} from '@instacart/ids-core';
import { ProfileMenuConfig } from 'widget-wrappers/types';
import { UserData } from 'shop-api-sdk';
import { imageConfigToComponent } from '../utils';

export type AvatarContainerProps = {
  configOverrides?: ProfileMenuConfig;
  user: UserData | null;
};

const hoverStyle = css`
  &:after {
    bottom: 6px;
    left: 0px;
    content: '';
    cursor: pointer;
    display: block;
    height: 1px;
    position: absolute;
    transition: width 0.25s ease-in-out;
    width: 0;
  }

  &:hover {
    &:after {
      background-color: currentColor;
      width: 100%;
    }
  }
`;

const useStyles = (overrides?: ProfileMenuConfig) => {
  const theme = useTheme();
  return useMemo(() => {
    const { styles, showUserName, showUnderlineOnHover } = overrides ?? {};
    const {
      colors: { systemGrayscale60, brandPrimaryRegular },
    } = theme;
    return {
      avatarContainer: css`
        position: relative;
        width: ${showUserName
          ? 'fit-content'
          : styles?.container?.width ?? '28px'};
        height: ${showUserName
          ? 'fit-content'
          : styles?.container?.height ?? '28px'};
        align-items: center;
        padding: ${styles?.container?.padding ?? '10px'};
        cursor: pointer;
        display: ${showUserName ? 'flex' : 'block'};
        background-color: ${styles?.container?.backgroundColor};

        ${responsive.down('c')} {
          padding: ${styles?.container?.mobile?.padding ?? '10px'};
        }

        ${showUnderlineOnHover ? hoverStyle : ''}
      `,
      avatarIcon: css`
        height: ${styles?.avatar?.width ?? '24px'};
        width: ${styles?.avatar?.height ?? '24px'};
        fill: ${styles?.avatar?.fill ?? brandPrimaryRegular};
      `,
      chevronIcon: css`
        display: none;
        margin-left: ${spacing.s32}px;
        height: ${styles?.chevronIcon?.width ?? '18px'};
        width: ${styles?.chevronIcon?.height ?? '18px'};
        fill: ${styles?.chevronIcon?.fill ?? systemGrayscale60};

        ${responsive.down('c')} {
          display: block;
        }
      `,
      nameLabel: css`
        color: ${styles?.name?.color ?? 'inherit'};
        font-size: ${styles?.name?.fontSize ?? '14px'};
        font-weight: ${styles?.name?.fontWeight ?? 700};
        padding-left: ${spacing.s8}px;
        line-height: 20px;

        ${responsive.down('c')} {
          line-height: 15px;
        }
      `,
    };
  }, [overrides, theme]);
};

const AvatarContainer = forwardRef<HTMLDivElement, AvatarContainerProps>(
  ({ configOverrides, user }, ref) => {
    const styles = useStyles(configOverrides);

    const username = useMemo(() => {
      if (user && configOverrides?.showUserName) {
        const capitalizeFirstLetter = (str: string) =>
          str.charAt(0).toUpperCase() + str.slice(1);
        const first = capitalizeFirstLetter(user.first_name || '');
        const last = capitalizeFirstLetter(user.last_name?.charAt(0) || '');
        return `${first} ${last}.`;
      }
      return null;
    }, [configOverrides?.showUserName, user]);

    const { profileIcon, showProfileIcon } = configOverrides ?? {};
    const Icon = useMemo(
      () => imageConfigToComponent(profileIcon, AccountIcon),
      [profileIcon],
    );

    return (
      <div
        data-testid="avatar-container"
        css={styles.avatarContainer}
        tabIndex={0}
        ref={ref}
      >
        {showProfileIcon ? <Icon aria-hidden css={styles.avatarIcon} /> : null}
        {username ? <span css={styles.nameLabel}>{username}</span> : null}
        {configOverrides?.showChevronIcon && (
          <ChevronRightIcon css={styles.chevronIcon} />
        )}
      </div>
    );
  },
);
AvatarContainer.displayName = 'AvatarContainer';

export default AvatarContainer;
