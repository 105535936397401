import { ReactNode, HTMLProps, forwardRef, MouseEvent, useMemo } from 'react';
import { spacing, useTheme, responsive, elevation } from '@instacart/ids-core';
import { css, SerializedStyles } from '@emotion/react';

export const SEARCH_DROPDOWN_TEST_ID = 'search-bar-dropdown';

const useStyles = (enableDropdownOnMobile?: boolean) => {
  const theme = useTheme();

  return useMemo(() => {
    const dropdownStyle = css`
      position: absolute;
      top: ${spacing.s4}px;
      left: 0;
      right: 0;
      z-index: ${elevation.high.depth.zIndex + 10};
      margin-top: ${spacing.s8}px;
    `;

    return {
      root: css`
        position: relative;
      `,
      container: css`
        ${responsive.up('r')} {
          ${dropdownStyle}
        }
        ${responsive.down('c')} {
          ${enableDropdownOnMobile ? dropdownStyle : ''}
        }
      `,
      dropdown: css`
        padding: ${spacing.s8}px 0px;
        margin-bottom: ${spacing.s8}px;
        color: ${theme.colors.systemGrayscale99};
        background-color: ${theme.colors.systemGrayscale00};
        box-shadow: ${elevation.low.shadow.boxShadow};
        border-radius: ${theme.radius.r8}px;
        ${responsive.down('c')} {
          ${enableDropdownOnMobile ? '' : 'box-shadow: none;'}
        }
      `,
    };
  }, [enableDropdownOnMobile, theme]);
};

const preventEvt = (evt: MouseEvent) => {
  // Prevent onBlur from firing from any mouse events inside the autocomplete dropdown
  evt.preventDefault();
};

type Props = HTMLProps<HTMLDivElement> & {
  children: ReactNode | Array<ReactNode>;
  enableDropdownOnMobile?: boolean;
  style?: Record<keyof ReturnType<typeof useStyles>, SerializedStyles>;
};

export const SearchDropdown = forwardRef<HTMLDivElement, Props>(
  ({ children, enableDropdownOnMobile, ...rest }, ref) => {
    const styles = useStyles(enableDropdownOnMobile);

    return (
      <div
        ref={ref}
        css={styles.root}
        onMouseDown={preventEvt}
        data-testid={SEARCH_DROPDOWN_TEST_ID}
        {...rest}
      >
        <div css={styles.container}>
          <div css={styles.dropdown} {...rest}>
            {children}
          </div>
        </div>
      </div>
    );
  },
);

SearchDropdown.displayName = 'SearchBarDropdown';
