import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { MouseEvent as ReactMouseEvent } from 'react';
import { AddItemStepperBase } from '@instacart/ids-customers';
import {
  AddIcon,
  ListAddIcon,
  useTheme,
  responsive,
  spacing,
} from '@instacart/ids-core';
import { css } from '@emotion/react';
import { Unit } from 'shop-api-sdk';
import { useIsMobile } from '../hooks';
import { valueWithUnit } from '../utils/units';

const useStyles = (enableCollapse: boolean | undefined) => {
  const theme = useTheme();
  return useMemo(
    () => ({
      buttonContainer: css`
        position: absolute;
        top: 10px;
        right: 5px;
        border-radius: 20px;
        background-color: ${theme.colors.brandPrimaryRegular};
      `,
      expandItemButton: {
        decrementButton: css`
          border-bottom-left-radius: 18px !important;
          ${responsive.down('c')} {
            border-bottom-left-radius: 16px !important;
          }
          background-color: ${theme.colors.brandPrimaryRegular};
          &:hover {
            background-color: ${theme.colors.brandPrimaryExtraDark};
          }
          &:disabled > svg {
            fill: ${theme.colors.systemGrayscale30};
          }
          &:disabled {
            background-color: ${theme.colors.brandPrimaryRegular};
            & > svg {
              fill: ${theme.colors.systemGrayscale30};
            }
          }
          & > svg {
            fill: ${theme.colors.systemGrayscale00};
          }
          &:focus {
            outline: -webkit-focus-ring-color auto 1px;
          }
        `,
        incrementButton: css`
          border-bottom-right-radius: 18px !important;
          ${responsive.down('c')} {
            border-bottom-right-radius: 16px !important;
          }
          background-color: ${theme.colors.brandPrimaryRegular};
          &:hover {
            background-color: ${theme.colors.brandPrimaryExtraDark};
          }
          &:disabled {
            background-color: ${theme.colors.brandPrimaryRegular};
            & > svg {
              fill: ${theme.colors.systemGrayscale30};
            }
          }
          & > svg {
            fill: ${theme.colors.systemGrayscale00};
          }
          &:focus {
            outline: -webkit-focus-ring-color auto 1px;
          }
        `,
        text: css`
          color: ${theme.colors.systemGrayscale00};
          font-size: 15px;
          font-weight: 600;
        `,
      },
      addItemButton: {
        button: css`
          display: flex;
          align-items: center;
          background-color: ${theme.colors.brandPrimaryRegular};
          border-radius: ${theme.radius.r24}px;
          border: 2px solid ${theme.colors.brandPrimaryRegular};
          color: ${theme.colors.systemGrayscale00};
          padding: ${enableCollapse ? '6px' : '6px 12px'};
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
          font-size: 15px;
          font-weight: 600;

          &:hover {
            background-color: ${theme.colors.brandPrimaryExtraDark};
          }

          > svg {
            fill: ${theme.colors.systemGrayscale00};
          }
        `,
        text: css`
          color: ${theme.colors.systemGrayscale00};
          font-size: 15px;
          font-weight: 600;
        `,
      },
      disclaimerWrapper: css`
        display: flex;
        flex-direction: column;
        gap: ${spacing.s4 / 2}px;
        text-align: center;
        padding-bottom: ${spacing.s8}px;
        color: ${theme.colors.systemGrayscale00};
        ${theme.typography.bodySmall2}
      `,
      disclaimerText: css`
        cursor: default;
        font-weight: 600;
      `,
      collapsedViewButton: css`
        display: flex;
        align-items: center;
        border-radius: ${theme.radius.r24}px;
        border: 2px solid ${theme.colors.brandPrimaryRegular};
        color: ${theme.colors.systemGrayscale00};
        background-color: ${theme.colors.brandPrimaryRegular};
        height: ${spacing.s40}px;
        min-width: ${spacing.s40}px;
        padding: 0 ${spacing.s4}px;
        font-size: 15px;
        font-weight: 600;
      `,
    }),
    [enableCollapse, theme],
  );
};

export type AddToCartWidgetProps = {
  incrementCount: number;
  description: string;
  disclaimer?: string[];
  itemCount: number;
  onIncrement: () => void;
  onDecrement: () => void;
  isHoveredCard?: boolean;
  isInStore?: boolean;
  alwaysEnableCollapse?: boolean;
  unit?: Unit;
};

export default function AddToCartWidget({
  incrementCount,
  onIncrement,
  onDecrement,
  description,
  disclaimer,
  itemCount,
  isInStore = false,
  alwaysEnableCollapse = false,
  isHoveredCard,
  unit,
}: AddToCartWidgetProps) {
  const isMobile = useIsMobile();
  const enableCollapse = alwaysEnableCollapse || isMobile;
  const styles = useStyles(enableCollapse);
  const [active, setActive] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const valueText = useMemo(() => {
    return itemCount > 0 ? valueWithUnit(itemCount, unit) : undefined;
  }, [itemCount, unit]);

  const handleAddClick = () => {
    onIncrement();
    if (enableCollapse && !active) {
      setActive(true);
    }
  };

  const handleClickOutside = useCallback(
    (evt: MouseEvent) => {
      const target = evt?.target as Node;
      if (
        enableCollapse &&
        !containerRef.current?.contains(target) &&
        // TODO: fix me!
        // the target element always INSTACART-COLLECTION element on clicking the AddItemStepperBase element...
        target?.nodeName !== 'INSTACART-COLLECTION'
      ) {
        setActive(false);
      }
    },
    [enableCollapse],
  );

  const showStepper = useCallback((evt: ReactMouseEvent<HTMLButtonElement>) => {
    evt?.stopPropagation();
    evt?.preventDefault();
    setActive(true);
  }, []);

  useLayoutEffect(() => {
    if (active && enableCollapse) {
      document.addEventListener('click', handleClickOutside, { passive: true });
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [active, handleClickOutside, enableCollapse]);

  const alwaysExpanded = !enableCollapse;

  return (
    <div css={styles.buttonContainer} ref={containerRef}>
      {itemCount > 0 ? (
        alwaysExpanded || active ? (
          <>
            <AddItemStepperBase
              styles={styles.expandItemButton}
              allowIncrement={true}
              allowDecrement={true}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
              value={itemCount}
              valueText={valueText}
              ariaIncrement={`Increment quantity of ${description}`}
              ariaDecrement={
                itemCount > 1
                  ? `Decrement quantity of ${description}`
                  : `Remove ${description}`
              }
              ariaValue={`${itemCount}`}
              decrementType={itemCount > incrementCount ? 'subtract' : 'trash'}
            />
            {disclaimer ? (
              <div css={styles.disclaimerWrapper}>
                {disclaimer.map((text, i) => (
                  <span key={i} aria-live="polite" css={styles.disclaimerText}>
                    {text}
                  </span>
                ))}
              </div>
            ) : null}
          </>
        ) : (
          <button css={styles.collapsedViewButton} onClick={showStepper}>
            {valueText}
          </button>
        )
      ) : (
        <button
          css={styles.addItemButton.button}
          onClick={handleAddClick}
          aria-label={`Add ${incrementCount} item ${description}`}
        >
          {isInStore ? <ListAddIcon size={24} /> : <AddIcon size={24} />}
          {alwaysExpanded && (
            <span css={styles.addItemButton.text}>
              {isHoveredCard
                ? isInStore
                  ? 'Add to list'
                  : 'Add to cart'
                : 'Add'}
            </span>
          )}
        </button>
      )}
    </div>
  );
}
