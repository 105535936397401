import {
  type RootConfigs,
  type ThemeWithoutStyles,
} from 'widget-wrappers/types';
import { fetchJson } from './fetch-utils';

const apiBaseUrl =
  import.meta.env.DEV && window.location.hostname.includes('localhost')
    ? ''
    : import.meta.env.VITE_SHOP_HOST_URL;

type Config = Pick<RootConfigs, 'styles'> & ThemeWithoutStyles;

export const getConfig = () => {
  let url = `${apiBaseUrl}/idp/v1/widgets/config`;
  // Append a cache busting parameter to bypass caching in `/widgets/config`
  // during development.
  if (import.meta.env.DEV) {
    const timestamp = Date.now().toString();
    const searchParams = new URLSearchParams({ timestamp });
    url += `?${searchParams.toString()}`;
  }

  const promise = fetchJson<Config>(url);
  return promise;
};
