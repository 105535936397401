import { css } from '@emotion/react';
import { HTMLAttributes, MouseEvent, useCallback, useRef } from 'react';

export type MagnifierProps = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  movementStrength?: number;
  zoomStrength?: number;
};

const rootStyles = css`
  position: relative;
  overflow: hidden;
`;

// clone and update of
// https://github.com/instacart/carrot/blob/master/customers/store/client/disorganized/store_app/components/shared/ImageZoomer.jsx
export default function Magnifier({
  children,
  className,
  disabled,
  movementStrength = 150,
  zoomStrength = 1.4,
}: MagnifierProps) {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);

  const setPositioning = useCallback(
    (evt: MouseEvent) => {
      if (!disabled && rootRef.current && innerRef.current) {
        const { clientX, clientY } = evt;

        const elmPositioning = rootRef.current.getBoundingClientRect();
        const elmCenter = {
          x: elmPositioning.width / 2,
          y: elmPositioning.height / 2,
        };
        const mouseElmPosition = {
          x: clientX - elmPositioning.left,
          y: clientY - elmPositioning.top,
        };
        const mousePosFromCenter = {
          x: mouseElmPosition.x - elmCenter.x,
          y: mouseElmPosition.y - elmCenter.y,
        };

        // move image opposite of mouse position from center of image
        const x = -(movementStrength / (elmCenter.x / mousePosFromCenter.x));
        const y = -(movementStrength / (elmCenter.y / mousePosFromCenter.y));
        innerRef.current.style.transform = `translate(${x}px, ${y}px) scale(${zoomStrength})`;
      }
    },
    [disabled, movementStrength, zoomStrength],
  );

  const resetPositioning = useCallback(() => {
    if (innerRef.current) {
      innerRef.current.style.transform = '';
    }
  }, []);

  return (
    <div
      onMouseMove={setPositioning}
      onMouseLeave={resetPositioning}
      ref={rootRef}
      css={rootStyles}
      className={className}
    >
      <div ref={innerRef}>{children}</div>
    </div>
  );
}
