import { RefObject, useContext, useEffect } from 'react';
import { ShadowRootContext } from '../context/ShadowRootContext';

type UserOutsideEventOptions = {
  ref: RefObject<HTMLElement>;
  attachEvent: boolean;
};

export const useDetectCloseEvent = (
  options: UserOutsideEventOptions,
  callback?: VoidFunction,
) => {
  const { attachEvent, ref } = options;
  const { shadowRoot } = useContext(ShadowRootContext);

  useEffect(() => {
    if (attachEvent && typeof callback === 'function') {
      const handleEvent = (evt: MouseEvent | KeyboardEvent) => {
        const target = evt.target as Node;

        if (
          ('key' in evt && evt.key === 'Escape') ||
          !ref.current?.contains(target)
        )
          callback();
      };

      const handleDocumentEvents = (event: MouseEvent | KeyboardEvent) => {
        const target = event.target as Node & { container?: HTMLElement };

        // clicked inside the fragment (ex: searchWidgetWrapper).
        if (
          shadowRoot &&
          target?.container &&
          shadowRoot === target?.container
        ) {
          return;
        }
        handleEvent(event);
      };

      const handleShadowRootEvents = (event: unknown) => {
        handleEvent(event as MouseEvent | KeyboardEvent);
      };

      document.addEventListener('mousedown', handleDocumentEvents);
      document.addEventListener('keyup', handleDocumentEvents);

      if (shadowRoot) {
        shadowRoot.addEventListener('mousedown', handleShadowRootEvents);
        shadowRoot.addEventListener('keyup', handleShadowRootEvents);
      }
      return () => {
        document.removeEventListener('mousedown', handleDocumentEvents);
        document.addEventListener('keyup', handleDocumentEvents);

        if (shadowRoot) {
          shadowRoot.removeEventListener('mousedown', handleShadowRootEvents);
          shadowRoot.removeEventListener('keyup', handleShadowRootEvents);
        }
      };
    }
  }, [attachEvent, callback, shadowRoot, ref]);
};
