import { css } from '@emotion/react';
import { useTheme, spacing } from '@instacart/ids-core';
import { useMemo } from 'react';
import { CartWidgetConfig } from 'widget-wrappers/types';

type CartShopCtaProps = {
  ctaString: string;
  ctaRedirect: () => void;
  configOverrides?: CartWidgetConfig;
};

const useStyles = (overrides?: CartWidgetConfig) => {
  const theme = useTheme();
  return useMemo(() => {
    const { colors } = theme;
    const { styles } = overrides ?? {};
    const bgColor =
      styles?.ctaButton?.backgroundColor ?? colors.brandPrimaryRegular;
    const color = styles?.ctaButton?.color ?? colors.brandPrimaryLight;
    const width = styles?.ctaButton?.width ?? '160px';
    const fontSize = styles?.ctaButton?.fontSize ?? '16px';
    return {
      button: css`
        background-color: ${bgColor};
        color: ${color};
        border-radius: ${theme.radius.r24 + 2}px;
        padding-left: ${spacing.s8}px;
        padding-right: ${spacing.s8}px;
        min-width: ${width};
        min-height: 52px;
        font-size: ${fontSize};
        font-weight: 600;
        border: 1px solid ${color};

        &:hover {
          background-color: ${color};
          color: ${bgColor};
          border: 1px solid ${bgColor};
        }
      `,
    };
  }, [theme, overrides]);
};

export default function CartShopCta({
  ctaString,
  ctaRedirect,
  configOverrides,
}: CartShopCtaProps) {
  const styles = useStyles(configOverrides);
  return (
    <button
      css={styles.button}
      role="button"
      onClick={ctaRedirect}
      aria-label={ctaString}
    >
      {ctaString}
    </button>
  );
}
