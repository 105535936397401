import { CSSProperties } from 'react';
import { Theme, ThemeColors } from '@instacart/ids-core';

type Colors = keyof ThemeColors;

export const getColor = (
  theme: Theme,
  color?: Colors | CSSProperties['color'],
) => {
  if (!color) return undefined;

  if (color in theme.colors) {
    return theme.colors[color as Colors];
  }

  return color;
};
