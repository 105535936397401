import { useMemo } from 'react';
import { ProductPrice, ProductShopLevelAttributes } from 'shop-api-sdk';
import { PriceDetails } from '../context/ProductDetailContext';
import { getCurrencySymbol } from '../../utils';

const transformPrice = (p?: ProductPrice) => {
  if (!p) return null;
  const symbol = getCurrencySymbol(p.unit_price?.currency);
  const price = (Number(p.unit_price?.amount_cents) / 100).toFixed(2);
  return `${symbol}${price}`;
};

export const usePriceDetails = (attributes?: ProductShopLevelAttributes) => {
  return useMemo<PriceDetails>(() => {
    const finalPrice = transformPrice(
      attributes?.pricing?.prices?.find((price) => price.type === 'FINAL'),
    );

    const basePrice = transformPrice(
      attributes?.pricing?.prices?.find((price) => price.type === 'BASE'),
    );

    const parWeight = attributes?.quantity_attributes.variable_weight
      ? attributes.quantity_attributes.par_weight
      : null;
    const parWeightStr = parWeight
      ? `About ${parWeight.quantity} ${parWeight.measurement_unit} each`
      : null;
    const unitSize = attributes?.size || null;

    return {
      basePrice,
      finalPrice,
      offerLabel: attributes?.pricing?.offer_label_string || null,
      pricePerMeasure: parWeightStr || unitSize,
    };
  }, [attributes]);
};
