import { createContext } from 'react';
import { CartItems, CartTotal } from 'shop-api-sdk';

export type CartContextValue = {
  cartItemsCount: number;
  /** undefined indicates unfetched or unknown */
  cartId: string | undefined;
  /** undefined indicates unfetched or unknown */
  cartItems: CartItems | undefined;
  cartItemsUiQuantities: Record<string, number>;
  cartTotal?: CartTotal;
  adjustItemCartQuantity: (
    productId: string,
    delta: number,
    /** Required for the PUT request body */
    quantityType: string,
  ) => Promise<void>;
};

export const CartContext = createContext<CartContextValue>({
  cartItemsCount: 0,
  cartId: undefined,
  cartItems: undefined,
  /** Provides optimistic-update UI values for quantity to be used in the AddToCart widget component */
  cartItemsUiQuantities: {},
  adjustItemCartQuantity: () => Promise.resolve(),
});
