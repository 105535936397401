import { css } from '@emotion/react';
import { responsive, spacing, useTheme } from '@instacart/ids-core';
import { useMemo } from 'react';
import ItemPrice from './ItemPrice';
import Availability from './Availability';
import QuantityDropdown from './QuantityDropdown';
import { useIsMobile } from '../../../hooks';
import QuantityStepper from './QuantityStepper';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        height: 100%;
      `,
      container: css`
        border: 1px solid ${theme.colors.systemGrayscale30};
        border-radius: ${theme.radius.r12}px;
        padding: ${spacing.s16}px;
        margin-bottom: ${spacing.s16}px;
        ${responsive.down('c')} {
          border: none;
          padding: 0px;
        }
      `,
    }),
    [theme],
  );
};

export default function AddProductToList() {
  const styles = useStyles();
  const isMobile = useIsMobile();

  return (
    <div css={styles.root}>
      <div css={styles.container}>
        <ItemPrice />
        <Availability />
        {isMobile ? <QuantityStepper /> : <QuantityDropdown />}
      </div>
    </div>
  );
}
