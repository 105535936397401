import { useEffect, useState } from 'react';
import deepmerge from 'deepmerge';
import { init, getConfig } from 'shop-api-sdk';
import { makeIcSessionReadyEvent } from '../../public-custom-events';
import theme from '../../retailer-theme-link';
import defaultConfig from '../../default-config';

export default function useAppInitializer(): boolean {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const asyncInitialisation = async () => {
      try {
        const [, config] = await Promise.all([
          // Fetch backend auth status here and set the cookie for Connect/IDP API
          init(),
          // Fetch the widgets config if `enableRemoteConfig` is set
          theme.enableRemoteConfig ? getConfig() : undefined,
        ]);

        // Deep-merge the remote config with the retailer config.
        if (config !== undefined) {
          // The values in `retailer-configs/*` take precedence over the remote config,
          // so that we can enforce some values. Values that we don't want to enforce
          // need to be removed from `retailer-configs/*` config once the remote config
          // is enabled with `enableRemoteConfig`.
          const mergedConfig = deepmerge.all([defaultConfig, config, theme]);
          // Override the `retailer-configs/*` config with the resulting remote config.
          // So all imports from `./retailer-theme-link` and calls to `useConfig` start
          // returning the remote config.
          const mergedTheme = deepmerge(theme, mergedConfig);
          Object.assign(theme, mergedTheme);
        }

        setIsInitialized(true);
        document.dispatchEvent(
          makeIcSessionReadyEvent({
            apiBaseUrl: import.meta.env.VITE_SHOP_HOST_URL,
          }),
        );
      } catch (error) {
        const errStr = error instanceof Error ? error.message : String(error);
        console.error(`Failed to initialize the application: ${errStr}`);
      }
    };

    void asyncInitialisation();
  }, []);

  return isInitialized;
}
