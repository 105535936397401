import { type R2WCOptions } from '@r2wc/core';

/**
 * WARNING: This (along with `public-custom-events.ts` is the public interface
 * for using widgets on external retailers' site. Treat this like an API spec for
 * third parties - renames or removals of existing components and props are
 * breaking changes.
 */

export const domElementComponentMap = {
  'instacart-auth': {
    props: {},
    componentLoader: () => import('./src/authWidgetWrapper/AuthWidgetWrapper'),
  },
  'instacart-shopping-cart': {
    props: {},
    componentLoader: () => import('./src/cartButtonWrapper/CartButtonWrapper'),
  },
  'instacart-search': {
    // SFX lookalike: ../../../carrot/customers/landing/client/landing/pages/ProductLandingPage/components/SearchInput/SearchInput.tsx
    props: {},
    componentLoader: () =>
      import('./src/searchBarWidgetWrapper/SearchBarWidgetWrapper'),
  },
  'instacart-collection': {
    props: {
      collectionId: 'string',
      hideViewAll: 'boolean',
      viewAllCustomLink: 'string',
    },
    componentLoader: () =>
      import('./src/productCollectionsWrapper/CollectionWrapper'),
  },
  'instacart-product-detail': {
    props: {},
    componentLoader: () => import('./src/productDetailWrapper'),
  },
  'instacart-side-nav': {
    props: {},
    componentLoader: () => import('./src/sideNavWrapper/SideNavWrapper'),
  },
  // TODO: Special case for BTF only, temporarily to avoid blocking the release,
  /// will remove once the PR for GFH change is merged post 04/10 release
  'instacart-fulfillment-selector': {
    props: {},
    componentLoader: () =>
      import('./src/fulfillmentSelectorWrapper/FulFillmentSelectorWrapper'),
  },
  // TODO: Special case for GFH banners (BTF, LZA, NSM, NWL) only,
  /// Added back as GFH reverted their commit in prod due to issues
  'instacart-auth-widget': {
    props: {},
    componentLoader: () => import('./src/authWidgetWrapper/AuthWidgetWrapper'),
  },
} as const;

/**
 * Internal widgets for using only in IPP previews.
 */
if (import.meta.env.VITE_RETAILER_NAME === 'the-garden') {
  Object.assign(domElementComponentMap, {
    'ipp-preview-add-rating-review': {
      props: {},
      componentLoader: () => import('./src/ippPreviews/addRatingReviewWrapper'),
    },
    'ipp-preview-item-card': {
      props: {
        item: 'json',
        quantity: 'number',
      },
      componentLoader: () => import('./src/ippPreviews/itemCardWrapper'),
    },
  } as Record<string, WidgetComponentTagEntry<object>>);
}

// Type checks

export type ComponentLoader<PropT extends object> = () => Promise<{
  default: React.ComponentType<PropT>;
}>;

// "container" - R2WC provides a "container" prop to every widget, we don't
// need to specify it. If specified though, it must be a DocumentFragment

type WidgetComponentTagEntry<
  PropT extends object & { container?: DocumentFragment },
> = {
  props: Record<keyof PropT, string> & R2WCOptions<PropT>['props'];
  componentLoader: ComponentLoader<PropT>;
};

// Check for all prop types declared for each imported component prop
// To fix errors, ensure all component props are defined in the domElementComponentMap object
domElementComponentMap satisfies {
  [tag in keyof typeof domElementComponentMap]: (typeof domElementComponentMap)[tag]['componentLoader'] extends ComponentLoader<
    infer PropT
  >
    ? WidgetComponentTagEntry<PropT>
    : never;
};
