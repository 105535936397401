import {
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
  RefObject,
  useMemo,
} from 'react';
import { spacing } from '@instacart/ids-core';
import { css } from '@emotion/react';
import { useResizeObserver } from 'usehooks-ts';
import { DEFAULT_ITEM_CARD_WIDTH } from 'sfx-app/ItemCard';
import { useIsMobile } from 'sfx-app/hooks';
import { Product, getCollections, getProducts } from 'shop-api-sdk';

export const ITEM_GAP = spacing.s16;

/** Custom Event, currently fired on the document whenever a product item within a collection is clicked (and pdp config is true)
 *
 * TODO: investigate a way to do this without exposing the event to
 * `document` where inquisitive retailers might be tempted to use it.
 * */
export const COLLECTION_ITEM_CLICK_EVENT = 'ic-collection-item-click';

export const useListComponentStyles = () => {
  const isMobile = useIsMobile();
  return useMemo(
    () => ({
      wrapper: css`
        position: relative;
        min-width: 0;
      `,
      buttonBar: css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: ${spacing.s24}px;
      `,
      list: css`
        list-style: none;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: ${isMobile ? 'auto' : 'hidden'};
        touch-action: pan-x;
        scrollbar-width: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: ${spacing.s8}px;
        margin-top: 0;
        margin-bottom: 0;
        gap: ${ITEM_GAP}px;

        > li {
          flex-shrink: 0;
        }
      `,
      noScrollbar: css`
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        ::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      `,
    }),
    [isMobile],
  );
};

export const useProductIdList = (collectionid: string, shopId?: string) => {
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    void (async () => {
      if (shopId) {
        const response = await getCollections(shopId, collectionid);
        setData(response.product_ids ?? []);
      }
    })();
  }, [collectionid, shopId]);

  return data;
};

type UseTileWidthOptions<T extends HTMLElement = HTMLElement> = {
  ref: RefObject<T>;
  productIds: string[];
  gap?: number;
};
export const useTileWidth = <T extends HTMLElement = HTMLElement>({
  ref,
  productIds,
  gap = 0,
}: UseTileWidthOptions<T>) => {
  const { width = 0 } = useResizeObserver({ ref, box: 'border-box' });
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [itemWidth, setItemWidth] = useState(DEFAULT_ITEM_CARD_WIDTH);

  useLayoutEffect(() => {
    if (productIds.length > 0 && width > 0) {
      const initialCount = Math.round((width + gap) / DEFAULT_ITEM_CARD_WIDTH);
      setItemWidth(Math.ceil((width + gap) / initialCount - gap));
      setItemsPerPage(initialCount);
    }
  }, [gap, productIds.length, width]);
  return { itemsPerPage, itemWidth };
};

export const useCachedProducts = (shopId?: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProductsMap] = useState<Record<string, Product>>({});

  const getProductInfo = useCallback(
    async (ids: string[]) => {
      if (ids.length > 0 && shopId) {
        try {
          setLoading(true);
          const { products: data } = await getProducts(shopId, ids);
          setProductsMap((prev) => {
            data.forEach((p) => {
              prev[p.id] = p;
            });
            return { ...prev };
          });
        } catch (error) {
          console.error('Failed to fetch product info:', error);
        } finally {
          setLoading(false);
        }
      }
    },
    [shopId],
  );

  return { loading, getProductInfo, products };
};

export const useProductsPagination = (
  productIds: string[],
  itemsPerPage: number,
  shopId?: string,
) => {
  const [pages, setPages] = useState<string[][]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { loading, getProductInfo, products } = useCachedProducts(shopId);

  useEffect(() => {
    if (productIds.length > 0 && itemsPerPage > 0) {
      const pages = Array.from(
        { length: Math.ceil(productIds.length / itemsPerPage) },
        (_, i) =>
          productIds.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage),
      );

      setPages(pages);
    }
  }, [getProductInfo, itemsPerPage, productIds]);

  useEffect(() => {
    if (pages[currentPage]) {
      void getProductInfo(pages[currentPage].filter((id) => !products[id]));
    }
  }, [currentPage, getProductInfo, pages, products]);

  return {
    currentPage,
    loading,
    products,
    setCurrentPage,
    totalPage: pages.length,
  };
};
