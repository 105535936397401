import { MouseEvent, useMemo, useRef, useLayoutEffect } from 'react';
import { useTheme, spacing } from '@instacart/ids-core';
import { css } from '@emotion/react';
import { useHover } from 'usehooks-ts';
import { ItemCardConfig, RatingsConfig } from 'widget-wrappers/types';
import { Product } from 'shop-api-sdk';
import AddToCartWidget from '../addToCart/AddToCartWidget';
import DietaryTagBadge from './DietaryTagBadge';
import ItemCardRatings from '../RatingsReviews/ItemCardRatings';
import {
  centsToDollarsString,
  roundToPrecision,
  unitToText,
  getCurrencySymbol,
} from '../utils';

export const DEFAULT_ITEM_CARD_WIDTH = 200;

type UseStyleOptions = {
  configOverrides?: ItemCardConfig;
  cardWidth?: number;
  noPadding?: boolean;
};

export type ItemCardProps = {
  heightResizeObserver?: ResizeObserver;
  item: Product;
  cartQuantity: number;
  isInStore?: boolean;
  onIncrement: () => void;
  onDecrement: () => void;
  onClickProduct?: (event: MouseEvent<HTMLAnchorElement>) => void;
  cardWidth?: number;
  noPadding?: boolean;
  itemConfigOverrides?: ItemCardConfig;
  ratingsConfigOverrides?: RatingsConfig;
};

const useStyles = ({
  cardWidth,
  noPadding,
  configOverrides,
}: UseStyleOptions) => {
  const theme = useTheme();
  return useMemo(
    () => ({
      product: css`
        position: relative;
        width: ${cardWidth ?? DEFAULT_ITEM_CARD_WIDTH}px;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: ${noPadding ? 0 : spacing.s12}px;
      `,
      productImageContainer: css`
        position: relative;
        width: 100%;
        margin-bottom: ${spacing.s8}px;
      `,
      productImage: css`
        display: block;
        width: 100%;
        aspect-ratio: 1;
        border-radius: ${theme.radius.r12}px;
      `,
      dietaryTags: css`
        position: absolute;
        bottom: ${spacing.s4}px;
        left: 0;
      `,
      productPrice: css`
        display: flex;
        align-items: stretch;
      `,
      productSalePrice: css`
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 11px;
        line-height: 11px;
        margin-right: ${spacing.s4}px;
        color: ${theme.colors.systemGrayscale80};
      `,
      productSaleYellowBox: css`
        background-color: ${theme.colors.brandMaxLight};
        color: ${configOverrides?.useLightSalePriceBoxColor
          ? theme.colors.systemGrayscale00
          : 'inherit'};
        padding: ${spacing.s4}px;
        border-radius: ${spacing.s4}px;
      `,
      priceLabel: css`
        font-size: 21px;
        font-weight: 700;
        line-height: 17px;
        padding: 0px 1px;
      `,
      basePricePrefixReg: css`
        font-size: 15px;
        line-height: 22px;
        font-weight: 600;
      `,
      basePriceLineThrough: css`
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        text-decoration-line: line-through;
      `,
      productDescription: css`
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: ${theme.colors.systemGrayscale80};
        margin: ${spacing.s4}px 0px;
      `,
      productLabel: css`
        font-size: 10px;
        font-weight: 700;
      `,
      productUnit: css`
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: ${theme.colors.systemGrayscale60};
      `,
      offerTagsSection: css`
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      `,
      offerLabel: css`
        background-color: ${theme.colors.brandMaxLight};
        padding: ${spacing.s4}px;
        border-radius: ${spacing.s4}px;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: ${configOverrides?.useLightSalePriceBoxColor
          ? theme.colors.systemGrayscale00
          : 'inherit'};
      `,
      snapLabel: css`
        background-color: ${theme.colors.systemGrayscale70};
        color: ${theme.colors.systemGrayscale00};
      `,
      basePriceWrapper: css`
        display: flex;
        flex-direction: column;
      `,
      unitText: css`
        ${theme.typography.bodyRegular};
      `,
    }),
    [cardWidth, configOverrides, noPadding, theme],
  );
};

export default function ItemCard({
  item,
  cartQuantity,
  onIncrement,
  onDecrement,
  onClickProduct,
  cardWidth,
  isInStore,
  noPadding,
  heightResizeObserver,
  itemConfigOverrides = {},
  ratingsConfigOverrides,
}: ItemCardProps) {
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(cardContainerRef);
  const {
    alwaysCollapseAddToCart,
    showSnapEligibility = false,
    basePriceStyle = 'line-through',
    dietaryTagIcons,
  } = itemConfigOverrides;

  const styles = useStyles({
    cardWidth,
    noPadding,
    configOverrides: itemConfigOverrides,
  });

  const { shop_level_attributes: attributes } = item;

  const finalPrice = useMemo(() => {
    const resultPrice = attributes?.pricing?.prices?.find(
      (price) => price.type === 'FINAL',
    );
    if (resultPrice?.unit_price) {
      const price = centsToDollarsString(resultPrice.unit_price.amount_cents);
      const symbol = getCurrencySymbol(resultPrice.unit_price?.currency);
      const arr = price.toString().split('.');
      // To add a zero to the decimal if it is only one digit to complete the cent value
      if (arr[1] && arr[1].length === 1) {
        arr[1] += '0';
      }
      return {
        currency: symbol,
        wholeNum: arr[0],
        decimal: arr[1],
      };
    }
    return null;
  }, [attributes]);

  const basePrice = useMemo(() => {
    const resultPrice = attributes?.pricing?.prices?.find(
      (price) => price.type === 'BASE',
    );
    if (resultPrice?.unit_price) {
      const price = centsToDollarsString(resultPrice.unit_price.amount_cents);
      const currency = getCurrencySymbol(resultPrice.unit_price?.currency);
      return `${currency}${price.toString()}`;
    }
    return null;
  }, [attributes]);

  const unitSize = attributes?.size;

  const unitText = unitToText(1, attributes?.quantity_attributes.unit);

  const parWeight = useMemo(() => {
    if (attributes?.quantity_attributes?.variable_weight) {
      const weight = attributes?.quantity_attributes?.par_weight;
      return `About ${weight?.quantity} ${weight?.measurement_unit} each`;
    }
    return null;
  }, [attributes]);

  const disclaimer = useMemo(() => {
    const { variable_weight, par_weight } =
      attributes?.quantity_attributes || {};
    if (variable_weight && par_weight) {
      const suffix = cartQuantity > 1 ? 's' : '';
      const unit = `${par_weight.measurement_unit}${suffix}`.toLowerCase();
      const weight = roundToPrecision(
        parseFloat(par_weight.quantity) * cartQuantity,
      );
      return [`About ${weight} ${unit}`, 'Final cost by weight'];
    }
    return;
  }, [attributes?.quantity_attributes, cartQuantity]);

  const offerLabel = attributes?.pricing?.offer_label_string;

  const incrementCount = attributes?.quantity_attributes?.increment || 1;

  useLayoutEffect(() => {
    // Notify parent container of the height this component needs - for
    // react-window since it uses absolute positioning to achieve its goals,
    // which means that the container's height needs to be fixed
    if (!heightResizeObserver) return;
    const containerDiv = cardContainerRef.current;
    if (!containerDiv)
      throw new Error('Expected cardContainerRef to be defined if mounted');
    heightResizeObserver.observe(cardContainerRef.current);
    return () => {
      heightResizeObserver.unobserve(containerDiv);
    };
  }, [heightResizeObserver]);

  const itemDietaryLabels = attributes?.details?.dietary_labels;
  const itemDietaryTags = attributes?.details?.dietary_attributes;

  return (
    <div css={styles.product} ref={cardContainerRef}>
      <div css={styles.productImageContainer}>
        <a
          href={item.permalink_url}
          target="_blank"
          data-testid="product-permalink-url"
          rel="noreferrer"
          onClick={onClickProduct}
        >
          <img
            src={item.image_url}
            css={styles.productImage}
            alt="product image"
          />
        </a>
        {itemDietaryLabels && dietaryTagIcons && (
          <DietaryTagBadge
            iconConfig={dietaryTagIcons}
            labels={itemDietaryLabels}
            tags={itemDietaryTags}
            css={styles.dietaryTags}
          />
        )}
        <AddToCartWidget
          incrementCount={incrementCount}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          description={item.name}
          itemCount={cartQuantity}
          isHoveredCard={isHover}
          isInStore={isInStore}
          alwaysEnableCollapse={alwaysCollapseAddToCart}
          unit={attributes?.quantity_attributes.unit}
          disclaimer={disclaimer}
        />
      </div>
      <div css={styles.productPrice}>
        {finalPrice && (
          <div
            css={[
              styles.productSalePrice,
              basePrice ? styles.productSaleYellowBox : '',
            ]}
          >
            {finalPrice.currency ? <span>{finalPrice.currency}</span> : null}
            {finalPrice.wholeNum ? (
              <span css={styles.priceLabel}>{finalPrice.wholeNum}</span>
            ) : null}
            {finalPrice.decimal ? <span>{finalPrice.decimal}</span> : null}
          </div>
        )}
        <div css={styles.basePriceWrapper}>
          <span css={styles.unitText}>{unitText ? `/ ${unitText}` : null}</span>
          {basePrice ? (
            <div
              css={[
                basePriceStyle === 'line-through' &&
                  styles.basePriceLineThrough,
                basePriceStyle === 'prefix-reg' && styles.basePricePrefixReg,
              ]}
            >
              {basePriceStyle === 'prefix-reg' && 'reg. '}
              {basePrice}
            </div>
          ) : null}
        </div>
      </div>
      <div css={styles.offerTagsSection}>
        {showSnapEligibility && attributes?.details?.usa_snap_eligible && (
          <div css={[styles.offerLabel, styles.snapLabel]}>SNAP</div>
        )}
        {offerLabel && <div css={styles.offerLabel}>{offerLabel}</div>}
      </div>
      <div css={styles.productDescription}>{item.name}</div>

      {item.product_rating ? (
        <ItemCardRatings
          configOverrides={ratingsConfigOverrides}
          productRating={item.product_rating}
        />
      ) : null}

      <div css={styles.productUnit}>{parWeight || unitSize}</div>
    </div>
  );
}
