import { ActionSheetDropdownItem } from '@instacart/ids-core';
import { useMemo } from 'react';
import { imageConfigToComponent } from '../../utils';
import { type Image } from '../../../../widget-wrappers/config';
import { css } from '@emotion/react';

type IProps = {
  displayName: string;
  link: string;
  isActive: boolean;
  actionPrompt?: string;
  fulfillmentAddress?: string;
  icon?: Image;
};

const useStyles = (isActive: boolean) => {
  return useMemo(() => {
    return {
      mainContainer: css`
        background-color: ${isActive ? '#f6f7f8' : '#ffffff'};
      `,
      contentContainer: css`
        display: flex;
        align-items: left;
        gap: 12px;
      `,
      optionContainer: css`
        display: flex;
        align-items: left;
        flex-direction: column;

        & > span {
          font-size: 14px;
          line-height: 20px;
          color: #242529;
        }
      `,
      option: css`
        font-weight: 600;
      `,
      actionPrompt: css`
        font-weight: 400;
      `,
      address: css`
        text-decoration: underline;
        font-weight: 600;
        margin-top: 8px;
        white-space: pre-wrap;
      `,
    };
  }, [isActive]);
};

export default function FulfillmentDropdownItem({
  displayName,
  link,
  isActive,
  actionPrompt,
  fulfillmentAddress,
  icon,
}: IProps) {
  const styles = useStyles(isActive);
  const Icon = useMemo(() => imageConfigToComponent(icon), [icon]);

  return (
    <ActionSheetDropdownItem
      data-testid="fulfillment-dropdown-option"
      onClick={() => {
        window.open(link, '_self');
      }}
      css={isActive ? styles.mainContainer : null}
    >
      <div css={styles.contentContainer}>
        <Icon size={24} />
        <div css={styles.optionContainer}>
          <span css={styles.option}>{displayName}</span>
          {actionPrompt && (
            <span css={styles.actionPrompt}>{actionPrompt}</span>
          )}
          {isActive && <span css={styles.address}>{fulfillmentAddress}</span>}
        </div>
      </div>
    </ActionSheetDropdownItem>
  );
}
