import {
  LogOutIcon,
  GearIcon,
  OrderIcon,
  MarkerIcon,
  CardIcon,
  NotificationIcon,
  PromotionIcon,
  ThemeColors,
} from '@instacart/ids-core';
import { RootConfigs } from 'widget-wrappers/types';

const themeColors: Partial<ThemeColors> = {
  brandPrimaryRegular: '#A5CC3D',
  brandLoyaltyRegular: '#D43684',
  brandMaxLight: '#E97300',
};

const cashTheme: RootConfigs = {
  styles: { colors: themeColors },
  authWidget: {
    label: 'Sign In / Register',
  },
  profileMenu: {
    profileOptions: [
      {
        icon: OrderIcon,
        label: 'Your orders',
        link: '/store/account/orders',
      },
      {
        icon: GearIcon,
        label: 'Account settings',
        link: '/store/account/profile',
      },
      {
        icon: MarkerIcon,
        label: 'Addresses',
        link: '/store/account/addresses',
      },
      {
        icon: CardIcon,
        label: 'Payment methods',
        link: '/store/account/payment',
      },
      {
        icon: PromotionIcon,
        label: 'Credits, promos and gift cards',
        link: '/store/account/manage_promos',
      },
      {
        icon: NotificationIcon,
        label: 'Notifications settings',
        link: '/store/account/notifications',
      },
      {
        icon: LogOutIcon,
        label: 'Log out',
        link: '/accounts/logout',
        type: 'logout',
      },
    ],
    showProfileIcon: true,
    showUserName: true,
    showChevronIcon: false,
    styles: {
      avatar: {
        fill: themeColors.brandPrimaryRegular,
      },
      sheet: {
        right: '0px',
      },
      name: {
        color: '#fff',
        fontSize: '14px',
        fontWeight: '700',
      },
    },
  },
  searchBar: {
    placeholder: 'Search Products...',
  },
  cartWidget: {},
  productCollections: {},
  itemCard: {
    openProductDetailModalOnItemClick: true,
  },
  ratings: {},
  fulfillmentSelector: {},
  sideNav: {},
};

export default cashTheme;
