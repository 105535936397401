import { useMemo } from 'react';
import { responsive, spacing, useTheme } from '@instacart/ids-core';
import { css } from '@emotion/react';
import { useIsMobile } from '../../hooks';
import Magnifier from './Magnifier';

type Props = {
  imageSrc: string;
  altText?: string;
};

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      magnifierStyle: css`
        max-height: 416px;
        max-width: 416px;
        margin: ${spacing.s16}px auto;

        ${responsive.down('c')} {
          max-height: 195px;
          max-width: 195px;
        }
      `,
      imageStyle: css`
        max-width: 100%;
        border-radius: ${theme.radius.r12}px;
        height: auto;
      `,
    }),
    [theme],
  );
};

export default function ProductImage({ altText = '', imageSrc }: Props) {
  const styles = useStyles();

  return useIsMobile() ? (
    <div css={styles.magnifierStyle}>
      <img css={styles.imageStyle} src={imageSrc} alt={altText} />
    </div>
  ) : (
    <Magnifier css={styles.magnifierStyle}>
      <img css={styles.imageStyle} src={imageSrc} alt={altText} />
    </Magnifier>
  );
}
