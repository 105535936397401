/**
 * WARNING: Public API ahead - get team approval before adding to this list!
 * This documents publicly-exposed events that our retailers may depend on,
 * in addition to any event data in the `detail` field.
 */

// NOTE - try to keep this file alphabetized as it grows...

/** Indicates when the cart is updated with products,
 * can be used to build custom components that rely on displaying the cart */
export type IcCartChangeDetail = { totalUniqueItems: number };
export const makeIcCartChangeEvent = (detail: IcCartChangeDetail) =>
  new CustomEvent('ic-cart-change', { detail });

/** Indicates the IDP API's session has been set up and is ready to be called by
 * custom (usually retailer) code outside the IcWidgetsLibrary project */
export type IcSessionReadyDetail = { apiBaseUrl: string };
export const makeIcSessionReadyEvent = (detail: IcSessionReadyDetail) =>
  new CustomEvent('ic-session-ready', { detail });
