import { useContext } from 'react';
import { StoreContext } from '../../common/context/store/StoreContext';
import FulfillmentSelector from 'sfx-app/fulfillmentSelector';
import { useConfig } from '../../common/hooks/useConfig';

export default function FulFillmentSelectorWrapper() {
  const {
    configOverride: { fulfillmentSelector },
  } = useConfig();
  const { activeFulfillmentOption, postalCode, shops } =
    useContext(StoreContext) ?? {};
  const lastActiveShop = shops?.[0];

  if (!activeFulfillmentOption || !postalCode || !lastActiveShop) return null;

  return (
    <FulfillmentSelector
      activeFulfillmentOption={activeFulfillmentOption}
      postalCode={postalCode}
      lastActiveShop={lastActiveShop}
      configOverride={fulfillmentSelector}
    />
  );
}
