import { useCallback, useEffect, useMemo, useState } from 'react';
import { CartContextValue } from 'widget-wrappers/types';
import { ProductShopLevelAttributes } from 'shop-api-sdk';
import { AddItemDetails } from '../context/ProductDetailContext';
import { useIsMobile } from '../../hooks';
import { valueWithUnit } from '../../utils';

const CUSTOM_INPUT = -1;
const REMOVE_FORM_LIST = -2;

export const useAddItem = (
  productId: string,
  cartContext: CartContextValue,
  attributes?: ProductShopLevelAttributes,
): AddItemDetails => {
  const isMobile = useIsMobile();
  const [currentQty, setCurrentQty] = useState<number>();
  const [customInput, setCustomInput] = useState(false);
  const { cartQuantity } = useMemo(() => {
    const { cartItemsUiQuantities } = cartContext;

    return {
      cartQuantity: cartItemsUiQuantities[productId] || 0,
    };
  }, [cartContext, productId]);

  const { unit, quantityType, increment } = useMemo(
    () => ({
      unit: attributes?.quantity_attributes.unit,
      quantityType: attributes?.quantity_attributes?.quantity_type || '',
      increment: attributes?.quantity_attributes.increment || 1,
    }),
    [attributes?.quantity_attributes],
  );

  const selectOptions = useMemo(() => {
    const options = Array.from({ length: 9 }, (_, i) => {
      const value = (i + 1) * increment;
      const valueStr = valueWithUnit((i + 1) * increment, unit);
      const label = cartQuantity === value ? `${valueStr} on list` : valueStr;
      return { label, value };
    });

    if (!isMobile) {
      options.push({
        label: 'Custom Amount',
        value: CUSTOM_INPUT,
      });

      if (cartQuantity) {
        options.push({
          label: 'Remove from list',
          value: REMOVE_FORM_LIST,
        });
      }
    }

    return options;
  }, [cartQuantity, increment, isMobile, unit]);

  const disableSubmitButton = useMemo(() => {
    const invalidCustomInput = customInput && currentQty === undefined;
    const currentQtyOverRange = currentQty !== undefined && currentQty > 100;
    const currentQtyBelowRange = currentQty !== undefined && currentQty < 0;
    // disable the submit button if the custom value is invalid
    return invalidCustomInput || currentQtyOverRange || currentQtyBelowRange;
  }, [currentQty, customInput]);

  const isInCartValue = useMemo(
    () => cartQuantity > 0 && cartQuantity === currentQty,
    [cartQuantity, currentQty],
  );

  const selectButtonLabel = useMemo(() => {
    // "5lb in cart/order" or "5lb"
    if (isInCartValue && !isMobile)
      return `${valueWithUnit(cartQuantity, unit)} on list`;
    if (currentQty) return valueWithUnit(currentQty, unit);
    return isMobile ? '0' : 'Select option';
  }, [cartQuantity, currentQty, isInCartValue, isMobile, unit]);

  const submitButtonLabel = useMemo(() => {
    if (cartQuantity && !isInCartValue)
      return !currentQty ? 'Remove from List' : 'Update quantity';
    return isMobile && isInCartValue ? 'On List' : 'Add to List';
  }, [cartQuantity, currentQty, isInCartValue, isMobile]);

  useEffect(() => {
    if (selectOptions.length > 0) {
      setCurrentQty(cartQuantity || selectOptions[0].value);
    }
  }, [cartQuantity, selectOptions]);

  const onSelectQuantity = useCallback(
    (value: number | string) => {
      if (value === CUSTOM_INPUT) {
        setCustomInput(true);
        setCurrentQty(undefined);
      } else if (value === REMOVE_FORM_LIST) {
        void cartContext.adjustItemCartQuantity(
          productId,
          -cartQuantity,
          quantityType,
        );
        setCurrentQty(undefined);
      } else {
        const qty = Number(value);
        // Prevent user enters float but it requires integer
        setCurrentQty(
          increment === 1 ? Math.round(qty) : parseFloat(qty.toFixed(2)),
        );
      }
    },
    [cartContext, cartQuantity, increment, productId, quantityType],
  );

  const onSubmit = useCallback(() => {
    const { adjustItemCartQuantity } = cartContext;
    if (!disableSubmitButton && currentQty) {
      const qty = -cartQuantity + currentQty;
      void adjustItemCartQuantity(productId, qty, quantityType);
    } else if (!currentQty) {
      // Empty cart
      void adjustItemCartQuantity(productId, -cartQuantity, quantityType);
    }
  }, [
    cartContext,
    cartQuantity,
    currentQty,
    disableSubmitButton,
    productId,
    quantityType,
  ]);

  return useMemo(() => {
    return {
      cartQuantity,
      currentQty,
      customInput,
      disableSubmitButton,
      increment,
      isInCartValue,
      onSelectQuantity,
      onSubmit,
      selectButtonLabel,
      selectOptions,
      submitButtonLabel,
    };
  }, [
    cartQuantity,
    currentQty,
    customInput,
    disableSubmitButton,
    increment,
    isInCartValue,
    onSelectQuantity,
    onSubmit,
    selectButtonLabel,
    selectOptions,
    submitButtonLabel,
  ]);
};
