import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { KeyboardEvent, FormEvent, ChangeEvent } from 'react';
import {
  useTheme,
  SearchInputForm,
  SearchInput,
  responsive,
  CloseIcon,
  spacing,
  ArrowLeftIcon,
  SearchIcon,
  type Theme,
} from '@instacart/ids-core';
import { IconButton, type IconButtonProps } from '@instacart/ids-customers';
import { css } from '@emotion/react';
import { SearchBarConfig } from 'widget-wrappers/types';
import { Autosuggestions } from 'shop-api-sdk';
import { useSearchContext } from '../searchContext';
import { SearchDropdown } from './SearchDropdown';
import { useDetectCloseEvent } from '../../hooks/useDetectCloseEvent';
import { SuggestionList } from './SuggestionList';
import { useHighlightedEntry } from '../hooks/useHighlightedEntry';
import { SearchBarA11Y } from './SearchBarA11Y';

type SearchFromProps = {
  configOverride?: SearchBarConfig;
  autosuggestions: Autosuggestions;
  isLoadingSuggestions: boolean;
  onChangeValue: (value: string) => void;
};

const useStyles = (
  theme: Theme,
  contentColor: string,
  overrides?: SearchBarConfig,
) => {
  const { enableDropdownOnMobile, ippPreview, styles } = overrides ?? {};
  const { dropdownVisible } = useSearchContext();

  return useMemo(() => {
    const mobileOverlayWrapper = css`
      ${responsive.down('c')} {
        position: ${!ippPreview?.isDropdownOpen ? 'fixed' : undefined};
        top: 0px;
        left: 0px;
        height: ${!ippPreview?.isDropdownOpen ? '100vh' : undefined};
        z-index: 10;

        form {
          height: auto;
          width: auto;
          align-items: center;
          gap: ${spacing.s8}px;
          margin-top: ${spacing.s24}px;
          padding: 0 ${spacing.s24}px;
        }
      }
    `;

    return {
      searchFormWrapper: css`
        width: 100%;
        ${dropdownVisible && !enableDropdownOnMobile
          ? mobileOverlayWrapper
          : ''}
      `,
      searchForm: css`
        margin: 0;
      `,
      searchInput: css`
        width: 100%;
        height: ${styles?.inputField?.height ?? '56px'};
        background: ${styles?.inputField?.backgroundColor ??
        theme.colors.systemGrayscale00};
        border-radius: ${styles?.inputField?.borderRadius ?? theme.radius.r8}px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
        font-size: ${styles?.text?.fontSize ?? '15px'};
        line-height: 22px;
        font-weight: ${styles?.text?.fontWeight ?? '500'};
        box-sizing: border-box;
        color: ${contentColor};
        padding-right: ${spacing.s40}px;
        border: 1px solid ${styles?.inputField?.borderColor ?? 'inherit'};
        &:focus {
          outline: none;
          border: 1px solid inherit;
        }
        &::placeholder {
          color: inherit;
        }
        ${responsive.down('c')} {
          height: 40px;
        }
      `,
      clearSearch: css`
        position: absolute;
        top: 50%;
        right: ${spacing.s8}px;
        transform: translateY(-50%);
        & svg {
          fill: ${contentColor};
        }
        ${responsive.down('c')} {
          height: 32px;
          width: 32px;
          right: ${!enableDropdownOnMobile && dropdownVisible
            ? spacing.s32
            : spacing.s8 - 2}px;
        }
      `,
      backButton: css`
        display: none;
        & svg {
          fill: ${contentColor};
        }
        ${responsive.down('c')} {
          display: block;
          flex-shrink: 0;
        }
      `,
      searchIconWrapper: css`
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        right: ${spacing.s12}px;
        & > svg {
          fill: ${contentColor};
        }
      `,
    };
  }, [
    dropdownVisible,
    enableDropdownOnMobile,
    ippPreview?.isDropdownOpen,
    styles,
    theme,
    contentColor,
  ]);
};

export function SearchBarForm({
  autosuggestions,
  isLoadingSuggestions,
  onChangeValue,
  configOverride,
}: SearchFromProps) {
  const { placeholder, enableDropdownOnMobile } = configOverride ?? {};
  const theme = useTheme();
  const contentColor = configOverride?.styles?.text?.color ?? 'inherit';
  const styles = useStyles(theme, contentColor, configOverride);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    typedText,
    lastTypedText,
    setTypedText,
    setLastTypedText,
    dropdownVisible,
    setDropdownVisible,
    submitForm,
  } = useSearchContext();
  const {
    highlightedEntryIndex,
    setNextHighlightEntry,
    setPrevHighlightEntry,
    resetHighlightEntry,
  } = useHighlightedEntry(autosuggestions);

  const handleFormSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    submitForm(typedText);
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setTypedText(evt.target.value);
    setLastTypedText(evt.target.value);
    onChangeValue(evt.target.value);
  };

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const handleClose = () => {
    setDropdownVisible(false);
    resetHighlightEntry();
  };

  const handleKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      submitForm(typedText);
      resetHighlightEntry();
      setDropdownVisible(false);
    } else if (evt.key === 'Escape') {
      handleClose();
    } else if (evt.key === 'ArrowUp') {
      evt.preventDefault(); // Keep input cursor from jumping to start
      setPrevHighlightEntry();
    } else if (evt.key === 'ArrowDown') {
      evt.preventDefault();
      setNextHighlightEntry();
    }
  };

  const clearSearch = () => {
    setTypedText('');
    setLastTypedText('');
    inputRef.current?.focus();
  };

  const handleHideDropdown = useCallback(() => {
    setDropdownVisible(false);
  }, [setDropdownVisible]);

  useDetectCloseEvent(
    {
      ref: wrapperRef,
      attachEvent: dropdownVisible,
    },
    handleHideDropdown,
  );

  useEffect(() => {
    if (!dropdownVisible) {
      inputRef.current?.blur();
    }
  }, [dropdownVisible, inputRef]);

  return (
    <div ref={wrapperRef} css={styles.searchFormWrapper}>
      <SearchInputForm css={styles.searchForm} onSubmit={handleFormSubmit}>
        {dropdownVisible && !enableDropdownOnMobile ? (
          <IconButton
            css={styles.backButton}
            type="button"
            icon={ArrowLeftIcon}
            accessibleLabel="close search popup"
            onClick={handleClose}
            color={contentColor as IconButtonProps['color']}
          />
        ) : null}
        {dropdownVisible ? (
          <SearchBarA11Y
            entryCount={autosuggestions.length}
            lastTypedValue={lastTypedText}
          />
        ) : null}
        <SearchInput
          value={typedText}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onFocus={handleFocus}
          placeholder={placeholder}
          aria-label={placeholder}
          aria-expanded={false}
          css={styles.searchInput}
          ref={inputRef}
        />
        {typedText ? (
          <IconButton
            css={styles.clearSearch}
            icon={CloseIcon}
            accessibleLabel="Clear search bar"
            type="button"
            onClick={clearSearch}
            color={contentColor as IconButtonProps['color']}
          />
        ) : (
          <div css={styles.searchIconWrapper}>
            <SearchIcon size={24} />
          </div>
        )}
      </SearchInputForm>
      {dropdownVisible && autosuggestions.length > 0 ? (
        <SearchDropdown enableDropdownOnMobile={enableDropdownOnMobile}>
          <SuggestionList
            items={autosuggestions}
            activeIndex={highlightedEntryIndex}
            isPending={isLoadingSuggestions}
          />
        </SearchDropdown>
      ) : null}
    </div>
  );
}
