import { useMemo } from 'react';
import { css } from '@emotion/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  spacing,
  useTheme,
} from '@instacart/ids-core';
import { ButtonPill } from '@instacart/ids-customers';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      button: css`
        display: flex;
        align-items: center;
      `,
      buttonLabel: css`
        ${theme.typography.bodyMedium1};
        margin-right: ${spacing.s4}px;
      `,
    }),
    [theme],
  );
};

type Props = {
  active: boolean;
  label: string;
  onClick: () => void;
};

export const InfoHead = ({ active, label, onClick }: Props) => {
  const styles = useStyles();

  return (
    <ButtonPill active={active} onClick={onClick}>
      <div css={styles.button}>
        <span css={styles.buttonLabel}>{label}</span>
        {active ? (
          <ChevronUpIcon size={12} color="systemGrayscale70" />
        ) : (
          <ChevronDownIcon size={12} color="systemGrayscale70" />
        )}
      </div>
    </ButtonPill>
  );
};
