import { useMemo } from 'react';
import { ProductDetails } from 'shop-api-sdk';
import { DetailLineItem } from '../context/ProductDetailContext';

const DETAIL_KEYS = ['details_description', 'ingredients'] as const;

const SECTION_HEADINGS: Record<(typeof DETAIL_KEYS)[number], string> = {
  details_description: 'Details',
  ingredients: 'Ingredients',
};

export const useItemDetails = (details?: ProductDetails) => {
  return useMemo<Array<DetailLineItem>>(() => {
    if (!details) return [];

    const bodyAttributes = DETAIL_KEYS.filter((key) => key in details)
      .map((key) => {
        return {
          headerString: SECTION_HEADINGS[key],
          bodyString: details[key] || '',
        };
      })
      .filter((x) => !!x.bodyString);
    return bodyAttributes;
  }, [details]);
};
