import {
  useState,
  useMemo,
  useRef,
  KeyboardEvent,
  useEffect,
  useCallback,
} from 'react';
import { css } from '@emotion/react';
import { ProfileMenuConfig } from 'widget-wrappers/types';
import { UserData } from 'shop-api-sdk';
import AvatarContainer from './AvatarContainer';
import { useIsMobile } from '../hooks';
import ProfileWidgetSheet from './common/ProfileWidgetSheet';

const useStyles = () =>
  useMemo(
    () => ({
      profileContainer: css`
        position: relative;
        width: fit-content;
      `,
    }),
    [],
  );

export type ProfileMenuProps = {
  baseUrl: string;
  configOverrides?: ProfileMenuConfig;
  user: UserData | null;
};

export default function ProfileWidget({
  baseUrl,
  configOverrides,
  user,
}: ProfileMenuProps) {
  const [menuShown, setMenuShown] = useState(
    configOverrides?.ippPreview?.isMenuOpen ?? false,
  );
  const dropDownRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const styles = useStyles();

  const handleKeyPress = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.code) {
      case 'Enter':
        setMenuShown(true);
        return;
      case 'Escape':
        setMenuShown(false);
        return;
      default:
        return;
    }
  }, []);

  const handleMouseEnter = useCallback(() => {
    dropDownRef.current?.blur();
    setMenuShown(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (configOverrides?.ippPreview?.isMenuOpen) return;

    dropDownRef.current?.focus();
    setMenuShown(false);
  }, [configOverrides?.ippPreview?.isMenuOpen]);

  useEffect(() => {
    if (isMobile) {
      if (menuShown) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isMobile, menuShown]);

  return (
    <div
      css={styles.profileContainer}
      data-testid="profile-widget-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyPress}
    >
      <AvatarContainer
        configOverrides={configOverrides}
        ref={dropDownRef}
        user={user}
      />
      {menuShown && (
        <ProfileWidgetSheet
          baseUrl={baseUrl}
          onClose={setMenuShown}
          overrides={configOverrides}
        />
      )}
    </div>
  );
}
