import { useMemo } from 'react';
import { css } from '@emotion/react';
import { ChevronUpIcon, spacing, useTheme } from '@instacart/ids-core';
import { DetailLineItem } from '../../context/ProductDetailContext';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        margin-top: ${spacing.s16}px;
      `,
      heading: css`
        ${theme.typography.bodyMedium1}
      `,
      content: css`
        ${theme.typography.bodyMedium2};
      `,
      seeLess: css`
        background: transparent;
        padding: 0px;
        border: none;
        margin-top: ${spacing.s16}px;
        ${theme.typography.bodyMedium1};
        color: ${theme.colors.systemGrayscale70};

        span {
          margin-right: ${spacing.s4}px;
        }
      `,
    }),
    [theme],
  );
};

type Props = {
  item: DetailLineItem;
  onShowLess: () => void;
};

export const InfoBody = ({ item, onShowLess }: Props) => {
  const styles = useStyles();

  return (
    <div css={styles.root}>
      <div css={styles.heading}>{item.headerString}</div>
      <div css={styles.content}>{item.bodyString}</div>

      <button onClick={onShowLess} css={styles.seeLess}>
        <span>See less</span>
        <ChevronUpIcon size={12} color="systemGrayscale70" />
      </button>
    </div>
  );
};
