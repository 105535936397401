import UAParser from 'ua-parser-js';
import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useWindowSize } from 'usehooks-ts';
import { responsive } from '@instacart/ids-core';

const DeviceTypes = {
  Mobile: 'mobile',
  Tablet: 'tablet',
};

type ContextState = {
  isMobile: boolean;
};

export const DeviceInfoContext = createContext<ContextState>({
  isMobile: false,
});

type Props = {
  mobileBreakpoint?: number;
};

const getDeviceInfo = () => {
  const ClientUAInstance = new UAParser();
  return ClientUAInstance.getDevice();
};

export default function DeviceInfoProvider({
  children,
  mobileBreakpoint,
}: PropsWithChildren<Props>) {
  const { width = 0 } = useWindowSize();
  const [device, setDevice] = useState(getDeviceInfo());

  const isMobile = useMemo(() => {
    // On rendering the IPP preview inside `<iframe>`, consider it mobile if
    // the window width is less than 768px.
    if (window.frameElement) {
      const isMobile =
        !(window.frameElement as HTMLIFrameElement).hidden &&
        window.innerWidth <= responsive.breakpoints.c.max;
      return isMobile;
    }

    if (
      device.type === DeviceTypes.Mobile ||
      device.type === DeviceTypes.Tablet
    ) {
      return true;
    }

    if (mobileBreakpoint) {
      return width < mobileBreakpoint;
    }

    return false;
  }, [device.type, mobileBreakpoint, width]);

  useEffect(() => {
    setDevice(getDeviceInfo());
  }, [width]);

  return (
    <DeviceInfoContext.Provider value={{ isMobile }}>
      {children}
    </DeviceInfoContext.Provider>
  );
}
