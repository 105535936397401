import { createContext } from 'react';
import { ShopContext, ShopsData } from 'shop-api-sdk';

/** Undefined indicates unfetched (following react-query's conventions) */
export type StoreContextValue =
  | (ShopsData & {
      activeShopId: string;
      activeFulfillmentOption: ShopContext;
      postalCode: string;
    })
  | undefined;

export const StoreContext = createContext<StoreContextValue>(undefined);
