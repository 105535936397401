import { css } from '@emotion/react';
import {
  InventoryBetterIcon,
  InventoryGoodIcon,
  InventoryLowIcon,
  StopIcon,
  spacing,
  useTheme,
} from '@instacart/ids-core';
import { ReactNode, useMemo } from 'react';
import { ProductAvailability } from 'shop-api-sdk';
import { useProductDetail } from '../../context/useProductDetail';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        display: flex;
        align-items: center;
        gap: ${spacing.s4}px;
        margin: ${spacing.s4}px 0px;
        ${theme.typography.bodySmall1}
      `,
      text: css`
        color: ${theme.colors.systemGrayscale70};
      `,
    }),
    [theme],
  );
};

const ICONS_MAP: Record<ProductAvailability, ReactNode> = {
  HIGHLY_IN_STOCK: <InventoryBetterIcon color="systemSuccessDark" size={16} />,
  IN_STOCK: <InventoryGoodIcon color="systemSuccessRegular" size={16} />,
  LOW_STOCK: <InventoryLowIcon color="brandLoyaltyRegular" size={16} />,
  OUT_OF_STOCK: <StopIcon color="systemGrayscale70" size={16} />,
};

const LABEL_MAP: Record<ProductAvailability, string> = {
  HIGHLY_IN_STOCK: 'Many in stock',
  IN_STOCK: 'In stock',
  LOW_STOCK: 'Likely out of stock',
  OUT_OF_STOCK: 'Out of stock',
};

export default function Availability() {
  const styles = useStyles();
  const { availability } = useProductDetail();

  if (!availability) return null;

  return (
    <div css={styles.root}>
      {ICONS_MAP[availability]}
      <span css={styles.text}>{LABEL_MAP[availability]}</span>
    </div>
  );
}
