import { css } from '@emotion/react';
import { memo, useMemo, useRef } from 'react';
import { ButtonPill, useModalState } from '@instacart/ids-customers';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ConfirmIcon,
  elevation,
  responsive,
  spacing,
  useTheme,
} from '@instacart/ids-core';
import { useDetectCloseEvent } from '../../hooks';

const options = [
  {
    value: 'mostRelevant',
    label: 'Most Relevant',
  },
  {
    value: 'mostRecent',
    label: 'Most Recent',
  },
  {
    value: 'highestRated',
    label: 'Highest Rated',
  },
  {
    value: 'lowestRelevant',
    label: 'Lowest Relevant',
  },
];

type IProps = {
  onSelect: (value: string) => void;
  value: string;
};

const useStyles = () => {
  const theme = useTheme();

  return useMemo(
    () => ({
      root: css`
        position: relative;
      `,
      noButtonStyle: css`
        background: none;
        border: none;
        text-align: left;
      `,
      menuButton: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing.s4}px;
      `,
      menuTitle: css`
        ${theme.typography.bodyMedium1};
        font-weight: 600;
      `,
      menuPanel: css`
        background: ${theme.colors.systemGrayscale00};
        box-shadow: ${elevation.high.shadow.boxShadow};
        border-radius: ${theme.radius.r12}px;
        z-index: 1;
        right: 0;
        left: 0;
        top: 0;
        position: absolute;
      `,
      menuItem: css`
        padding: ${spacing.s12}px ${spacing.s16}px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        white-space: nowrap;
        ${theme.typography.bodyMedium1};

        &:active,
        &:hover,
        &:focus {
          background: ${theme.colors.brandSecondaryLight};
        }

        svg {
          flex-shrink: 0;
        }
      `,
      menuItems: css`
        max-height: 350px;
        overflow-y: auto;
        overscroll-behavior: contain;

        ${responsive.down('c')} {
          max-height: 300px;
        }
      `,
      greyText: css`
        color: ${theme.colors.systemGrayscale50};
      `,
    }),
    [theme],
  );
};

export default memo(function SortSelection({ value, onSelect }: IProps) {
  const state = useModalState({ visible: false });
  const ref = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  const selectedValueLabel = useMemo(
    () => options?.find((option) => option.value === value)?.label,
    [value],
  );

  useDetectCloseEvent({ ref, attachEvent: state.visible }, state.hide);

  return (
    <div css={styles.root} data-testid="dropdown_container" ref={ref}>
      <ButtonPill
        onClick={state.show}
        css={styles.noButtonStyle}
        active={state.visible}
        data-testid="reviews-sort-dropdown-trigger"
      >
        <div css={styles.menuButton}>
          <span css={styles.menuTitle}>{selectedValueLabel}</span>
          <ChevronDownIcon color="systemGrayscale30" size={20} />
        </div>
      </ButtonPill>
      {state.visible ? (
        <div css={styles.menuPanel} data-testid="sheet">
          <button
            css={[styles.menuItem, styles.menuTitle, styles.noButtonStyle]}
            onClick={state.hide}
            data-testid="sheet_title"
          >
            <span>Sort reviews by</span>
            <ChevronUpIcon color="systemGrayscale30" size={20} />
          </button>
          <div css={styles.menuItems}>
            {options.map((option) => (
              <button
                key={option.value}
                css={[styles.menuItem, styles.noButtonStyle]}
                onClick={(evt) => {
                  evt.preventDefault();
                  onSelect(option.value);
                  state.hide();
                }}
                aria-label={option.label}
              >
                <span css={value !== option.value && styles.greyText}>
                  {option.label}
                </span>
                {option.value === value ? (
                  <ConfirmIcon
                    color="brandPrimaryRegular"
                    data-testid="sheet_option_checkmark"
                    size={16}
                  />
                ) : null}
              </button>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
});
