import { MouseEvent, memo, useCallback, useContext, useMemo } from 'react';
import { LoadingLockupGridItem } from '@instacart/ids-core';
import { useTheme } from '@instacart/ids-core';
import { css } from '@emotion/react';
import ItemCard, { type ItemCardProps } from 'sfx-app/ItemCard';
import { StoreContext } from '../../common/context/store/StoreContext';
import { Product } from 'shop-api-sdk';
import { CartContextValue } from '../../common/context/carts/CartContext';
import { COLLECTION_ITEM_CLICK_EVENT } from './custom-hooks';
import { useConfig } from '../../common/hooks/useConfig';

type Props = {
  item: Product | undefined;
  cartItemsUiQuantities: CartContextValue['cartItemsUiQuantities'];
  adjustItemCartQuantity: CartContextValue['adjustItemCartQuantity'];
} & Pick<
  ItemCardProps,
  | 'heightResizeObserver'
  | 'isInStore'
  | 'cardWidth'
  | 'noPadding'
  | 'item'
  | 'itemConfigOverrides'
  | 'ratingsConfigOverrides'
>;

const useStyles = (width?: number) => {
  const theme = useTheme();
  return useMemo(
    () => ({
      loadingBar: {
        container: css`
          ${width ? `width: ${width}px` : ''};
          background-color: ${theme.colors.systemGrayscale00};
          border-radius: ${theme.radius.r12}px;
          padding-bottom: ${theme.radius.r24}px;
        `,
      },
    }),
    [theme, width],
  );
};

export default memo(function ProductCard({
  item,
  cartItemsUiQuantities,
  adjustItemCartQuantity,
  cardWidth,
  heightResizeObserver,
}: Props) {
  const styles = useStyles(cardWidth);
  const {
    configOverride: {
      itemCard: itemConfigOverrides,
      ratings: ratingsConfigOverrides,
    },
  } = useConfig();

  const shopContext = useContext(StoreContext)?.activeFulfillmentOption;
  const isInStore = shopContext === 'instore';
  const cartQuantity = cartItemsUiQuantities[item?.id || ''] ?? 0;

  const delta =
    item?.shop_level_attributes?.quantity_attributes?.increment || 1;
  const quantity_type =
    item?.shop_level_attributes?.quantity_attributes?.quantity_type || '';

  const handleIncrement = useCallback(() => {
    item && void adjustItemCartQuantity(item.id, +delta, quantity_type);
  }, [item, delta, quantity_type, adjustItemCartQuantity]);

  const handleDecrement = useCallback(() => {
    item && void adjustItemCartQuantity(item.id, -delta, quantity_type);
  }, [item, delta, quantity_type, adjustItemCartQuantity]);

  const handleClickProduct = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      if (itemConfigOverrides?.openProductDetailModalOnItemClick) {
        evt.stopPropagation();
        evt.preventDefault();

        document.dispatchEvent(
          new CustomEvent(COLLECTION_ITEM_CLICK_EVENT, {
            detail: item?.id,
          }),
        );
      }
    },
    [itemConfigOverrides, item?.id],
  );

  if (!item) {
    return <LoadingLockupGridItem styles={styles.loadingBar} />;
  }

  return (
    <ItemCard
      heightResizeObserver={heightResizeObserver}
      item={item}
      cartQuantity={cartQuantity}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
      onClickProduct={handleClickProduct}
      itemConfigOverrides={itemConfigOverrides}
      ratingsConfigOverrides={ratingsConfigOverrides}
      cardWidth={cardWidth}
      isInStore={isInStore}
      noPadding
    />
  );
});
