import { useMemo, useState, PropsWithChildren } from 'react';
import { ConfigContext } from './ConfigContext';
import { RootConfigs, config } from '../../../config';

type Props = {
  theme: RootConfigs;
};
function ConfigProvider({ children, theme }: PropsWithChildren<Props>) {
  const [configOverride, setConfigOverride] = useState<RootConfigs | null>(
    null,
  );

  const contextValue = useMemo(
    () => ({
      retailerName: config.retailerName,
      configOverride: configOverride ?? theme,
      setConfigOverride,
    }),
    [configOverride, theme],
  );

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
}

export default ConfigProvider;
