import { MenuIcon } from '@instacart/ids-core';
import { IconButton } from '@instacart/ids-customers';

type SideNavButtonProps = {
  onToggle: () => void;
};

export default function SideNavButton({ onToggle }: SideNavButtonProps) {
  return (
    <div data-testid="side-nav-button">
      <IconButton
        accessibleLabel="toggle side menu"
        icon={MenuIcon}
        onClick={onToggle}
      />
    </div>
  );
}
