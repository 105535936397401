import { useMemo } from 'react';
import { css } from '@emotion/react';
import { spacing, useTheme } from '@instacart/ids-core';
import { Autosuggestions } from 'shop-api-sdk';
import { SuggestionItem } from './SuggestionItem';

interface Props {
  isPending: boolean;
  items: Autosuggestions;
  activeIndex: number;
}

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      wrapper: css`
        margin: 0px;
        padding: 0px ${spacing.s16}px;
        list-style: none;
      `,
      item: css`
        cursor: pointer;
        border-radius: ${theme.radius.r4}px;
        padding: ${spacing.s4}px ${spacing.s8}px;

        &:hover {
          background: ${theme.colors.systemGrayscale20};
        }
      `,
      activeItem: css`
        background: ${theme.colors.systemGrayscale20};
      `,
    }),
    [theme],
  );
};

export const SuggestionList = ({ items, activeIndex }: Props) => {
  const styles = useStyles();

  return (
    <ul css={styles.wrapper}>
      {items.map((item, i) => (
        <li
          role="option"
          key={i}
          css={[styles.item, i === activeIndex && styles.activeItem]}
        >
          <SuggestionItem item={item} />
        </li>
      ))}
    </ul>
  );
};
