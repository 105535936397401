import { css } from '@emotion/react';
import { responsive, spacing } from '@instacart/ids-core';
import { CartContextValue, RatingsConfig } from 'widget-wrappers/types';
import {
  AddRatingReviewParams,
  Product,
  ProductReviews,
  ProductUnitRatings,
} from 'shop-api-sdk';
import ProductImage from './common/ProductImage';
import Details from './common/Details';
import AddProductToList from './common/AddProductToList';
import ProductDetailProvider from './context/ProductDetailProvider';
import ExpandableInfo from './common/ExpandableInfo';
import { useIsMobile } from '../hooks';
import LoadingProduct from './common/LoadingProduct';
import EmptyProduct from './common/EmptyProduct';
import ItemRatingsReviews from '../RatingsReviews/ItemRatingsReviews';
import AddRatingReview from '../RatingsReviews/AddRatingReview';

export type ProductDetailProps = {
  item?: Product | null;
  loading: boolean;
  cartContext: CartContextValue;
  ratingsConfigOverrides?: RatingsConfig;
  authorized: boolean;
  productReviews?: ProductReviews;
  productUnitRatings?: ProductUnitRatings;
  onSubmitRatingReview: (data?: AddRatingReviewParams) => void;
  onLoadMoreReviews: () => void;
  onChangeOrderForReviews: (option: string) => void;
};

const styles = {
  root: css`
    padding: ${`${spacing.s32}px ${spacing.s16}px ${spacing.s16}px`};
  `,
  contentWrapper: css`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: ${spacing.s24}px;
    ${responsive.down('r')} {
      grid-template-columns: 4fr 6fr;
    }
    ${responsive.down('c')} {
      display: block;
    }
  `,
  contentRight: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spacing.s24}px;
    ${responsive.down('r')} {
      display: block;
    }
  `,
};

export default function ProductDetail({
  authorized,
  cartContext,
  item,
  loading,
  ratingsConfigOverrides,
  productReviews,
  productUnitRatings,
  onSubmitRatingReview,
  onLoadMoreReviews,
  onChangeOrderForReviews,
}: ProductDetailProps) {
  const isMobile = useIsMobile();

  if (loading) {
    return <LoadingProduct />;
  }

  if (!item) {
    return <EmptyProduct />;
  }

  return (
    <ProductDetailProvider product={item} cartContext={cartContext}>
      <div css={styles.root}>
        <div css={styles.contentWrapper}>
          <ProductImage imageSrc={item.image_url} altText={item.name} />
          <div css={styles.contentRight}>
            <Details
              productRating={item.product_rating}
              ratingsConfigOverrides={ratingsConfigOverrides}
            />
            <AddProductToList />
          </div>
        </div>
        {isMobile ? <ExpandableInfo /> : null}
        {ratingsConfigOverrides?.enableRatingsAndReviews && (
          <>
            <AddRatingReview
              authorized={authorized}
              configOverrides={ratingsConfigOverrides}
              onSubmitRatingReview={onSubmitRatingReview}
            />
            <ItemRatingsReviews
              configOverrides={ratingsConfigOverrides}
              productReviews={productReviews}
              productRating={item.product_rating}
              productUnitRatings={productUnitRatings}
              onLoadMoreReviews={onLoadMoreReviews}
              onChangeOrderForReviews={onChangeOrderForReviews}
            />
          </>
        )}
      </div>
    </ProductDetailProvider>
  );
}
