import { useIsMobile } from 'sfx-app/hooks';
import AuthWidget from 'sfx-app/AuthWidget';
import AvatarContainer from 'sfx-app/AvatarContainer';
import ProfileWidget from 'sfx-app/ProfileWidget';
import { css } from '@emotion/react';
import { useAuth } from '../../common/hooks/useAuth';
import { useConfig } from '../../common/hooks/useConfig';

const styles = {
  anchor: css`
    text-decoration: none;
  `,
};

export default function AuthWidgetWrapper() {
  const { authState, user, handleAuth } = useAuth();
  const { configOverride } = useConfig();
  const baseUrl = import.meta.env.VITE_SHOP_HOST_URL;
  const isMobile = useIsMobile();

  if (!authState) {
    return (
      <AuthWidget
        onPress={handleAuth}
        configOverrides={configOverride.authWidget}
      />
    );
  }

  if (isMobile && configOverride.profileMenu?.clickUrlOnMobile) {
    return (
      <a
        css={styles.anchor}
        href={`${baseUrl}/${configOverride.profileMenu?.clickUrlOnMobile}`}
      >
        <AvatarContainer
          configOverrides={configOverride.profileMenu}
          user={user}
        />
      </a>
    );
  }

  return (
    <ProfileWidget
      configOverrides={configOverride.profileMenu}
      baseUrl={baseUrl}
      user={user}
    />
  );
}
