import { css } from '@emotion/react';
import { spacing, useTheme } from '@instacart/ids-core';
import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import StarRatings, { StarRatingsProps } from './StarRatings';
import { ProductReview } from 'shop-api-sdk';

const reviewLineLimit = 6;

const useStyles = () => {
  const theme = useTheme();

  return useMemo(
    () => ({
      root: css`
        padding: ${spacing.s24}px 0;
      `,
      contentWrapper: css`
        max-width: 600px;
      `,
      starRating: css`
        display: inline-block;
      `,
      metadata: css`
        ${theme.typography.bodySmall2};
        color: ${theme.colors.systemGrayscale50};
      `,
      title: css`
        ${theme.typography.bodyMedium1};
      `,
      reviewContent: css`
        ${theme.typography.bodyMedium2};
        margin-top: ${spacing.s24}px;
      `,
      clampLines: css`
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${reviewLineLimit};
        overflow: hidden;
        text-overflow: ellipsis;
      `,
      seeMore: css`
        ${theme.typography.bodySmall2};
        border: none;
        background: none;
        color: ${theme.colors.systemSuccessDark};
        padding: 0px;
      `,
    }),
    [theme],
  );
};

export type ItemReviewItemProps = Omit<StarRatingsProps & ProductReview, 'id'>;

export default memo(function ReviewItem({
  title,
  content,
  meta,
  rating,
  configOverrides,
}: ItemReviewItemProps) {
  const styles = useStyles();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [clampLines, setClampLines] = useState(false);
  const [seeMoreTouched, setSeeMoreTouched] = useState(false);

  useLayoutEffect(() => {
    // heightPerLine is derived from the lineHeight in theme.typography.bodyMedium2
    // due to type restrictions and variations the lineHeight may be set (string vs number)
    // best to set it manually to keep consistent
    const heightPerLine = 22;
    const contentHeight = contentRef?.current?.clientHeight;
    if (
      contentHeight &&
      !clampLines &&
      !seeMoreTouched &&
      contentHeight / heightPerLine > reviewLineLimit
    ) {
      setClampLines(true);
    }
  }, [contentRef, seeMoreTouched, clampLines]);

  return (
    <div css={styles.root}>
      <div css={styles.starRating}>
        <StarRatings rating={rating} configOverrides={configOverrides} />
      </div>
      <div css={styles.contentWrapper}>
        {title ? <div css={styles.title}>{title}</div> : null}
        <div css={styles.metadata}>{meta}</div>
        {content ? (
          <div
            css={[
              styles.reviewContent,
              !seeMoreTouched && clampLines && styles.clampLines,
            ]}
            ref={contentRef}
          >
            {content}
          </div>
        ) : null}
        {clampLines && !seeMoreTouched && (
          <button css={styles.seeMore} onClick={() => setSeeMoreTouched(true)}>
            See more
          </button>
        )}
      </div>
    </div>
  );
});
