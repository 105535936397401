import { StrictMode } from 'react';
import DeviceInfoProvider from 'sfx-app/DeviceInfoContext';
import ConfigProvider from '../common/context/config/ConfigProvider';
import IdsThemeOverridesProvider from './IdsThemeOverridesProvider';
import AuthProvider from '../common/context/auth/AuthProvider';
import CartProvider from '../common/context/carts/CartProvider';
import StoreProvider from '../common/context/store/StoreProvider';
import useAppInitializer from '../common/hooks/useAppInitializer';
import ProductDetailModalWrapper from '../common/components/ProductDetailModalWrapper';
import theme from '../retailer-theme-link';

function AppWrappers({ children }: { children: React.ReactNode }) {
  const isInitialized = useAppInitializer();

  // Note: All components depend on init to complete before rendering to avoid race conditions
  if (!isInitialized) return null;

  return (
    <ConfigProvider theme={theme}>
      <DeviceInfoProvider mobileBreakpoint={theme.mobileBreakpoint}>
        <AuthProvider>
          <StoreProvider>
            <CartProvider>
              <IdsThemeOverridesProvider>
                <StrictMode>
                  {children}
                  {theme.itemCard?.openProductDetailModalOnItemClick ? (
                    <ProductDetailModalWrapper />
                  ) : null}
                </StrictMode>
              </IdsThemeOverridesProvider>
            </CartProvider>
          </StoreProvider>
        </AuthProvider>
      </DeviceInfoProvider>
    </ConfigProvider>
  );
}

export default AppWrappers;
