import { ReactNode, useState, useEffect, useMemo, useCallback } from 'react';
import { getUser, UserData } from 'shop-api-sdk';
import { AuthContext } from './AuthContext';
import { useConfig } from '../../hooks/useConfig';

function AuthProvider({ children }: { children: ReactNode }) {
  const { retailerName } = useConfig();
  const [authState, setAuthState] = useState(false);
  const [user, setUser] = useState<UserData>({
    user_id: '',
    first_name: '',
    last_name: '',
    guest: true,
  });

  useEffect(() => {
    async function fetchUser() {
      try {
        const userData = await getUser();
        setUser(userData);
        // Set auth state based on if the user is a guest or not
        if (userData.guest) {
          setAuthState(false);
        } else {
          setAuthState(true);
        }
      } catch (error) {
        setAuthState(false);
        if (error instanceof Error) {
          console.error(`Failed to verify user: ${error.message}`);
        } else {
          console.error(`Failed to verify user: ${String(error)}`);
        }
      }
    }
    void fetchUser();
  }, [setAuthState, setUser]);

  const handleAuth = useCallback(() => {
    // The Garden environment does not have a external sso enabled
    // TODO: review support/scope for non external sso retailers
    const baseUrl = import.meta.env.VITE_SHOP_HOST_URL;
    if (retailerName === 'the-garden') {
      window.open(`${baseUrl}/login`, '_self');
    } else {
      const url = `${baseUrl}/rest/sso/auth/${retailerName}/init?return_to=${encodeURIComponent(
        window.location.href,
      )}`;
      window.open(url, '_self');
    }
  }, [retailerName]);

  const authContextValue = useMemo(
    () => ({
      authState,
      setAuthState,
      user,
      setUser,
      handleAuth,
    }),
    [authState, user, handleAuth],
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
