import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { spacing, useTheme } from '@instacart/ids-core';
import { InfoHead } from './InfoHead';
import { DetailLineItem } from '../../context/ProductDetailContext';
import { useProductDetail } from '../../context/useProductDetail';
import { InfoBody } from './InfoBody';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        border-top: 1px solid;
        border-color: ${theme.colors.systemGrayscale20};
      `,
      heading: css`
        ${theme.typography.bodyMedium1};
        margin-bottom: ${spacing.s12}px;
      `,
      pills: css`
        margin: 0 -${spacing.s4}px;
      `,
    }),
    [theme],
  );
};

export default function ExpandableInfo() {
  const styles = useStyles();
  const { detailAttrs } = useProductDetail();
  const [selected, setItem] = useState<DetailLineItem | null>(null);

  const toggleItem = useCallback((item: DetailLineItem) => {
    setItem((prev) => {
      if (prev?.headerString === item.headerString) {
        return null;
      }
      return item;
    });
  }, []);

  return (
    <div css={styles.root}>
      <h4 css={styles.heading}>Product information</h4>
      <div css={styles.pills}>
        {detailAttrs.map((attr, index) => (
          <InfoHead
            key={index}
            active={selected?.headerString === attr.headerString}
            label={attr.headerString}
            onClick={() => toggleItem(attr)}
          />
        ))}
      </div>
      {selected ? (
        <InfoBody item={selected} onShowLess={() => setItem(null)} />
      ) : null}
    </div>
  );
}
