import { useEffect, useState } from 'react';
import {
  ModalBase,
  ModalContent,
  ModalHeader,
  useModalState,
} from '@instacart/ids-customers';
import { responsive } from '@instacart/ids-core';
import { css } from '@emotion/react';
import { COLLECTION_ITEM_CLICK_EVENT } from '../../src/productCollectionsWrapper/custom-hooks';
import { useConfig } from '../hooks/useConfig';
import ProductDetailCardWrapper from './ProductDetailCardWrapper';

const modalStyles = {
  modal: css`
    width: 90%;

    ${responsive.down('c')} {
      width: 100%;
    }

    ${responsive.up('xl')} {
      width: 80%;
    }
  `,
};

const accessibleLabels = { close: 'Close' };

export default function ProductDetailModalWrapper() {
  const [productId, setProductId] = useState<string | null>(null);
  const {
    configOverride: { itemCard: { openProductDetailModalOnItemClick } = {} },
  } = useConfig();

  const modal = useModalState({
    visible: false,
  });

  useEffect(() => {
    if (openProductDetailModalOnItemClick) {
      const onReceiveEvent = ((evt: CustomEvent<string>) => {
        if (evt.detail) {
          setProductId(evt.detail);
          modal.show();
        }
      }) as EventListener;

      document.addEventListener(COLLECTION_ITEM_CLICK_EVENT, onReceiveEvent);

      return () => {
        document.removeEventListener(
          COLLECTION_ITEM_CLICK_EVENT,
          onReceiveEvent,
        );
      };
    }
  }, [modal, openProductDetailModalOnItemClick]);

  useEffect(() => {
    if (!modal.visible) {
      setProductId(null);
    }
  }, [modal.visible]);

  return (
    <>
      {openProductDetailModalOnItemClick && productId ? (
        <ModalBase
          modal={modal}
          aria-label="product detail dialog"
          styles={modalStyles}
        >
          <ModalHeader hide={modal.hide} accessibleLabels={accessibleLabels} />
          <ModalContent>
            <ProductDetailCardWrapper productId={productId} />
          </ModalContent>
        </ModalBase>
      ) : null}
    </>
  );
}
