import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useIsMobile } from '../../hooks';

const styles = {
  visuallyHidden: css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    height: 1px;
    width: 1px;
  `,
};

const TEXT = {
  searchTermInstructionsId: 'search-term-accessibility',
  searchAccessibilityMobileString:
    'Typing in the search field will populate autocomplete results',
  searchAccessibilityString:
    'Begin typing in the search field, and the app will offer suggestions. Use the arrow keys to navigate to a suggestion and then use the Enter key to select it',
  noResultsFoundString: 'Show results for %{term}',
  entriesString: 'entries',
};

export const SearchBarA11Y = ({
  entryCount,
  lastTypedValue,
}: {
  entryCount: number;
  lastTypedValue: string;
}) => {
  const isMobile = useIsMobile();

  const { accessibilityString, entriesFoundText } = useMemo(() => {
    const accessibilityString = isMobile
      ? TEXT.searchAccessibilityMobileString
      : TEXT.searchAccessibilityString;

    const termString = TEXT.noResultsFoundString.split('%{term}');
    let entriesFoundText = `${entryCount} ${TEXT.entriesString}`;

    if (entryCount === 0 && termString?.length) {
      entriesFoundText = `${termString?.[0]}${lastTypedValue}`;
    }
    return {
      entriesFoundText,
      accessibilityString,
    };
  }, [entryCount, isMobile, lastTypedValue]);

  return (
    <>
      <p
        role="presentation"
        id={TEXT.searchTermInstructionsId}
        css={styles.visuallyHidden}
      >
        {accessibilityString}
      </p>
      <p role="presentation" css={styles.visuallyHidden} aria-live="assertive">
        {entriesFoundText}
      </p>
    </>
  );
};
