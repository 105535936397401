/** Throws on a fetch error */
export async function fetchJson<T>(url: string, init?: RequestInit) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
    },
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  if (
    import.meta.env.DEV &&
    !response.headers.get('content-type')?.includes('application/json')
  ) {
    console.warn('fetchJson: response is not JSON', response);
  }
  return response.json() as Promise<T>;
}
