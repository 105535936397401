import { useContext, useEffect } from 'react';
import CartWidget from 'sfx-app/CartWidget';
import CartShopCta from 'sfx-app/CartShopCta';
import { CartContext } from '../../common/context/carts/CartContext';
import { config } from '../../config';
import { useConfig } from '../../common/hooks/useConfig';
import { makeIcCartChangeEvent } from '../../public-custom-events';
import { useIsMobile } from 'sfx-app/hooks';

export default function CartButtonWrapper() {
  const { configOverride } = useConfig();
  const { cartItemsCount, cartTotal } = useContext(CartContext);
  const isMobile = useIsMobile();

  const openCart = () => {
    window.open(`${config.apiBaseUrl}?cart_open`, '_self');
  };

  const redirectToShop = () => {
    window.open(`${config.apiBaseUrl}`, '_self');
  };

  useEffect(() => {
    // Dispatch an event to inform the Host Site that the cart has changed
    // Gated behind a config to avoid this for every retailer
    if (!configOverride.publicApi?.enableIcCartChangeEvent) return;
    document.dispatchEvent(
      makeIcCartChangeEvent({
        totalUniqueItems: cartItemsCount,
      }),
    );
  }, [cartItemsCount, configOverride.publicApi?.enableIcCartChangeEvent]);

  if (
    !isMobile &&
    configOverride.cartWidget?.showShopCtaOnEmptyCart &&
    cartItemsCount === 0
  ) {
    return (
      <CartShopCta
        ctaString={configOverride.cartWidget?.showShopCtaOnEmptyCart}
        ctaRedirect={redirectToShop}
        configOverrides={configOverride.cartWidget}
      />
    );
  }

  return (
    <CartWidget
      cartItemsCount={cartItemsCount}
      openCart={openCart}
      cartTotal={cartTotal}
      configOverrides={configOverride.cartWidget}
    />
  );
}
