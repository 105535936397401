import { css } from '@emotion/react';
import {
  ActionSheetDropdownItem,
  responsive,
  spacing,
  useTheme,
} from '@instacart/ids-core';
import { useCallback, useMemo } from 'react';
import { IProfileOptions, ProfileMenuConfig } from 'widget-wrappers/types';
import { imageConfigToComponent } from '../../utils';

type IProps = {
  item: IProfileOptions;
  overrides?: ProfileMenuConfig;
  onClick: (link?: string, type?: string) => void;
};

const useStyles = (overrides?: ProfileMenuConfig) => {
  const theme = useTheme();

  return useMemo(() => {
    const {
      colors: {
        systemGrayscale00,
        brandHighlightLight,
        systemGrayscale80,
        brandSecondaryDark,
      },
    } = theme;
    const { styles } = overrides ?? {};
    const contentAlign = styles?.sheetItem?.mobile?.justifyContent ?? 'left';

    return {
      sheetItem: {
        button: css`
          background-color: ${systemGrayscale00};
          border-radius: ${spacing.s8}px;
          font-family: inherit;
          padding: ${spacing.s8}px ${spacing.s16}px;
          width: 100%;

          ${responsive.down('c')} {
            display: flex;
            padding-top: ${spacing.s4}px;
            padding-bottom: ${spacing.s4}px;
            justify-content: ${contentAlign};
          }
        `,
        hover: css`
          background-color: ${brandHighlightLight};
        `,
        focusRing: css`
          border: ${spacing.s4 / 2}px solid ${systemGrayscale80};
          border-radius: ${spacing.s8}px;
          content: '';
          display: block;
          position: absolute;
          top: 0px;
          left: 7px;
          right: 7px;
          bottom: 0px;
        `,
        content: css`
          display: flex;
          align-items: center;
        `,
      },
      sheetItemIcon: css`
        margin-left: -2px;
        margin-right: ${spacing.s8}px;
        vertical-align: text-bottom;
        height: ${styles?.sheetItemIcon?.height ?? '24px'};
        width: ${styles?.sheetItemIcon?.width ?? '24px'};
        fill: ${styles?.sheetItemIcon?.fill ?? brandSecondaryDark};
      `,
      sheetItemLabel: css`
        font-size: ${styles?.sheetItemLabel?.fontSize ?? '14px'};
        font-weight: ${styles?.sheetItemLabel?.fontWeight ?? '500'};
        color: ${styles?.sheetItemLabel?.color ?? brandSecondaryDark};
        text-align: left;
        white-space: normal;

        ${responsive.down('c')} {
          line-height: 40px;
        }
      `,
    };
  }, [overrides, theme]);
};

export default function ProfileWidgetSheetItem({
  item,
  onClick,
  overrides,
}: IProps) {
  const { label, icon, link, type } = item;
  const styles = useStyles(overrides);

  const Icon = useMemo(() => imageConfigToComponent(icon), [icon]);

  const handleOnClick = useCallback(() => {
    onClick(link, type);
  }, [link, onClick, type]);

  return (
    <ActionSheetDropdownItem
      title={label}
      onClick={handleOnClick}
      role="menuitem"
      styles={styles.sheetItem}
    >
      {overrides?.showProfileIcon && (
        <Icon aria-hidden css={styles.sheetItemIcon} />
      )}

      <span css={styles.sheetItemLabel}>{label}</span>
    </ActionSheetDropdownItem>
  );
}
