import { useState } from 'react';
import SideNavButton from 'sfx-app/SideNavButton';
import SideNavDrawer from './SideNavDrawer';
import { useConfig } from '../../common/hooks/useConfig';

export default function SideNavWrapper() {
  const {
    configOverride: { sideNav },
  } = useConfig();
  const [isOpen, setIsOpen] = useState(sideNav?.ippPreview?.isOpen ?? false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  return (
    <>
      <SideNavButton onToggle={onOpen} />
      {isOpen && (
        <SideNavDrawer isOpen={isOpen} onClose={onClose} {...sideNav} />
      )}
    </>
  );
}
