import { useMemo } from 'react';
import { ProductShopLevelAttributes } from 'shop-api-sdk';

export const useQuantitySize = (attributes?: ProductShopLevelAttributes) => {
  return useMemo(
    () =>
      attributes?.quantity_attributes?.par_weight?.quantity || attributes?.size,
    [attributes],
  );
};
