import { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@instacart/ids-core';
import { css } from '@emotion/react';
import { useTheme } from '@instacart/ids-core';
import { useConfig } from '../../common/hooks/useConfig';
import ViewAllCollection from './ViewAllCollection';

const useStyles = () => {
  const theme = useTheme();
  const {
    configOverride: { productCollections: overrides },
  } = useConfig();

  return useMemo(() => {
    const { styles } = overrides ?? {};
    const { systemGrayscale20, systemGrayscale70 } = theme.colors;
    const chevronIconWidth = styles?.chevronIcon?.width as string;
    const chevronIconHeight = styles?.chevronIcon?.height as string;
    const chevronIconSize =
      chevronIconWidth || chevronIconHeight
        ? Math.max(
            parseInt(chevronIconWidth ?? '0'),
            parseInt(chevronIconHeight ?? '0'),
          )
        : 20;

    return {
      button: css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${systemGrayscale20};
        border: none;
        cursor: pointer;
        border-radius: 100%;
        margin: 0 5px;
        padding: 6px;

        &:disabled {
          opacity: 0.5;
        }
      `,
      chevronIcon: css`
        fill: ${styles?.chevronIcon?.fill ?? systemGrayscale70};
        width: ${chevronIconSize}px;
        height: ${chevronIconSize}px;
      `,
    };
  }, [overrides, theme]);
};

type PagerProps = {
  viewAllLink: string | null;
  currentPage: number;
  disabled: boolean;
  totalCount: number;
  totalPage: number;
  onNext?: () => void;
  onPrev?: () => void;
};

export const CollectionPager = ({
  viewAllLink,
  currentPage,
  disabled,
  totalCount,
  totalPage,
  onNext,
  onPrev,
}: PagerProps) => {
  const styles = useStyles();

  return (
    <>
      {viewAllLink ? (
        <ViewAllCollection viewAllLink={viewAllLink} totalCount={totalCount} />
      ) : null}
      <button
        css={styles.button}
        onClick={onPrev}
        disabled={currentPage <= 0 || disabled}
      >
        <ChevronLeftIcon css={styles.chevronIcon} />
      </button>
      <button
        css={styles.button}
        onClick={onNext}
        disabled={currentPage >= totalPage - 1 || disabled}
      >
        <ChevronRightIcon css={styles.chevronIcon} />
      </button>
    </>
  );
};
