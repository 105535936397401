import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import useCartsPersonal from '../../hooks/useCartsPersonal';
import { CartContext } from './CartContext';
import {
  getCartItems,
  updateCartItems,
  CartItems,
  CartItem,
  getCartTotal,
  CartTotal,
} from 'shop-api-sdk';
import { StoreContext } from '../store/StoreContext';
import { assertIsDefined, roundToPrecision } from '../../utils';

type Props = { children: React.ReactNode };

export default function CartProvider({ children }: Props) {
  const shopId = useContext(StoreContext)?.activeShopId;
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [cartTotal, setCartTotal] = useState<CartTotal | undefined>();
  const [cartItems, setCartItems] = useState<CartItems | undefined>(undefined);
  // Represents our best intent of what the user sees, and is used through a
  // current ref to indicate the user's desired cart quantity (e.g., this is the
  // value they see when they click + or -)
  const [cartItemsUiQuantities, setCartItemsUiQuantities] = useState<
    Record<string, number>
  >({});

  const cartsPersonalData = useCartsPersonal();

  const cartId = useMemo(
    () => cartsPersonalData.data?.carts[0].id,
    [cartsPersonalData.data?.carts],
  );

  useEffect(() => {
    if (cartsPersonalData?.data?.carts) {
      const cart = cartsPersonalData.data.carts[0];
      setCartItemsCount(cart.item_count);

      void (async () => {
        const cartItems = await getCartItems(cart.id);
        if (cart.item_count !== cartItems.items.length) {
          console.warn(
            `cart.item_count (${cart.item_count}) !== cartItems.items.length (${cartItems.items.length})`,
          );
        }
        setCartItems(cartItems);
        setCartItemsUiQuantities(
          Object.fromEntries(
            cartItems.items.map((e) => [e.product_id, e.quantity]),
          ),
        );
      })();
    }
  }, [cartsPersonalData]);

  useEffect(() => {
    if (shopId && cartId) {
      void (async () => {
        try {
          setCartTotal((await getCartTotal(cartId, shopId)).total);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [shopId, cartId]);
  // Need current desired quantities to
  const quantityMapRef = useRef(cartItemsUiQuantities);
  quantityMapRef.current = cartItemsUiQuantities;
  const adjustItemCartQuantity = useCallback(
    async (productId: string, delta: number, quantityType: string) => {
      const currentQty = quantityMapRef.current[productId] ?? 0;
      const newQuantity = roundToPrecision(currentQty + delta);

      setCartItemsUiQuantities((quantityMap) => ({
        ...quantityMap,
        [productId]: newQuantity,
      }));

      // TODO: get old quantity somehow and update it with cart items
      const cartItem: CartItem = {
        product_id: productId,
        quantity: newQuantity,
        quantity_type: quantityType,
      };
      assertIsDefined(cartId);
      assertIsDefined(shopId);
      const { item_count } = await updateCartItems(cartId, shopId, cartItem);
      setCartTotal((await getCartTotal(cartId, shopId)).total);
      setCartItemsCount(item_count);
    },
    [cartId, shopId],
  );

  const contextValue = useMemo(
    () => ({
      cartItemsCount,
      cartId,
      cartItems,
      cartItemsUiQuantities,
      cartTotal,
      adjustItemCartQuantity,
    }),
    [
      cartItemsCount,
      cartId,
      cartItems,
      cartItemsUiQuantities,
      cartTotal,
      adjustItemCartQuantity,
    ],
  );

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
}
