import { fetchJson } from './fetch-utils';

const apiBaseUrl =
  import.meta.env.DEV && window.location.hostname.includes('localhost')
    ? ''
    : import.meta.env.VITE_SHOP_HOST_URL;

export type ProductCollectionData = {
  name: string;
  code: string;
  owner: string;
  nested_collections?: ProductCollectionData[];
};

export type Collections = {
  collections: ProductCollectionData[];
};

export type ProductDetails = {
  details_description?: string;
  ingredients?: string;
  warnings?: string;
  usa_snap_eligible?: boolean;
  /** Includes both retailer and instacart dietary attributes, in display
   * priority order */
  dietary_labels?: string[];
  /** Includes identifiers for the above tags to control icons, 1-to-1 match to
   above to be usable */
  dietary_attributes?: string[];
};

type Money = {
  amount_cents: number;
  currency: string;
};

export type ProductPrice = {
  unit_price?: Money;
  estimated_each_price?: Money;
  reduction_factors?: unknown;
  type?: 'BASE' | 'FINAL';
};

export type ProductPricing = {
  offer_label_string?: string;
  prices?: ProductPrice[];
  [key: string]: unknown; // other fields may be unaccounted for
};

type MeasuredQuantity = {
  measurement_unit:
    | 'COST_UNIT_UNSPECIFIED'
    | 'EACH'
    | 'LB'
    | 'KG'
    | 'OZ'
    | 'FL_OZ'
    | 'ML'
    | 'GRAM'
    | 'GALLON'
    | 'PINT'
    | 'QUART'
    | 'LITRE';
  quantity: string;
};

export type Unit = 'EACH' | 'LB' | 'KG' | 'PACKAGE';

export type QuantityAttributes = {
  quantity_type: 'EACH' | 'WEIGHT';
  variable_weight?: boolean;
  par_weight?: MeasuredQuantity;
  unit: Unit;
  increment: number;
};

export type ProductAvailability =
  | 'HIGHLY_IN_STOCK'
  | 'IN_STOCK'
  | 'LOW_STOCK'
  | 'OUT_OF_STOCK';

export type ProductShopLevelAttributes = {
  quantity_attributes: QuantityAttributes;
  availability?: ProductAvailability;
  size?: string;
  pricing?: ProductPricing;
  details?: ProductDetails;
};

export type ProductRating = {
  value: number;
  amount: number;
};

export type ProductUnitRating = ProductRating & { percent: number };

export type ProductUnitRatings = Array<ProductUnitRating>;

export type ProductReview = {
  id: string;
  rating: number;
  title?: string | null;
  content?: string | null;
  meta: string;
};

export type ProductReviews = {
  order_by: string;
  total_amount: number;
  reviews: ProductReview[];
  page_info: {
    end_cursor: string;
    has_next_page: boolean;
  };
};

export type Product = {
  id: string;
  name: string;
  permalink_url: string;
  image_url: string;
  shop_id?: string;
  shop_level_attributes?: ProductShopLevelAttributes;
  product_rating?: ProductRating;
};

export type Products = {
  products: Product[];
};

export type ProductIds = {
  product_ids: string[];
};

export const getCollections = async (shopId: string, code: string) => {
  return await fetchJson<ProductIds>(
    `${apiBaseUrl}/idp/v1/collections/${code}/product_ids?shop_id=${shopId}`,
    {
      credentials: 'include',
    },
  );
};

export const getProducts = async (
  shopId: string,
  productIds: ProductIds['product_ids'],
) => {
  const queryString = productIds.map((id) => `&product_ids[]=${id}`).join('');
  return await fetchJson<Products>(
    `${apiBaseUrl}/idp/v1/products?shop_id=${shopId}${queryString}&expands[]=price&expands[]=details&expands[]=availability`,
    {
      credentials: 'include',
    },
  );
};
