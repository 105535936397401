import { type SearchBarConfig } from 'widget-wrappers/types';
import { Autosuggestions } from 'shop-api-sdk';
import { SearchBarForm } from './common/SearchBarForm';
import { SearchBarContextProvider } from './searchContext';

export type SearchBarWidgetProps = {
  initTerm?: string;
  configOverride?: SearchBarConfig;
  isLoadingSuggestions: boolean;
  autosuggestions: Autosuggestions;
  onSubmit: (term: string) => void;
  onChangeValue: (term: string) => void;
};

export default function SearchBarWidget({
  autosuggestions,
  configOverride,
  initTerm = '',
  onSubmit,
  isLoadingSuggestions,
  onChangeValue,
}: SearchBarWidgetProps) {
  return (
    <SearchBarContextProvider
      term={initTerm}
      configOverride={configOverride}
      onSubmit={onSubmit}
    >
      <SearchBarForm
        configOverride={configOverride}
        autosuggestions={autosuggestions}
        isLoadingSuggestions={isLoadingSuggestions}
        onChangeValue={onChangeValue}
      />
    </SearchBarContextProvider>
  );
}
