import { useMemo } from 'react';
import { css } from '@emotion/react';
import { ChevronRightIcon, spacing, useTheme } from '@instacart/ids-core';

type Props = {
  headerString: string;
  bodyString: string;
  lineLimit: number;
  showBody: boolean;
};

const useStyles = (showBody: boolean, lineLimit: number) => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        min-height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: ${spacing.s8}px;
      `,
      titleWrapper: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        ${!showBody &&
        `
            height: 100%;
            &:hover {
                cursor: pointer;
            }
        `}
      `,
      title: css`
        ${theme.typography.bodyMedium1}
        color: ${theme.colors.systemGrayscale70};
      `,
      bodyWrapper: css`
        margin-bottom: ${spacing.s8}px;
      `,
      body: css`
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: ${lineLimit};
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 200px;
      `,
      bodyText: css`
        display: content;
        margin: 0;
        color: ${theme.colors.systemGrayscale50};
        ${theme.typography.bodyMedium2}
      `,
      readMore: css`
        color: ${theme.colors.systemGrayscale50};
        background: transparent;
        text-decoration-line: underline;
        ${theme.typography.bodyMedium1};
        font-weight: 600;
      `,
    }),
    [showBody, theme, lineLimit],
  );
};

export default function DetailLine({
  headerString,
  bodyString,
  showBody,
  lineLimit,
}: Props) {
  const styles = useStyles(showBody, lineLimit);

  const breakLines = useMemo(() => {
    if (!showBody || !bodyString) return [];
    return bodyString.split('\n').map((d, idx) => (
      <p key={idx} css={styles.bodyText}>
        {d}
      </p>
    ));
  }, [bodyString, showBody, styles.bodyText]);

  return (
    <div css={styles.root}>
      <div role="button" tabIndex={0} css={styles.titleWrapper}>
        <div css={styles.title}>{headerString}</div>
        {!showBody && <ChevronRightIcon color="systemGrayscale70" size={16} />}
      </div>

      {showBody ? (
        <div css={styles.bodyWrapper}>
          <div css={styles.body}>{breakLines}</div>
        </div>
      ) : null}
    </div>
  );
}
