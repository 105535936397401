import { css } from '@emotion/react';
import {
  RatingEmptyIcon,
  RatingIcon,
  spacing,
  TextInputBase,
  useTheme,
} from '@instacart/ids-core';
import { ChangeEvent, FormEvent, memo, useMemo, useState } from 'react';
import { MAX_RATING } from './common/constants';
import { RatingsConfig } from 'widget-wrappers/types';
import { getColor } from '../utils';
import {
  PrimaryButton,
  SecondaryButton,
  TextAreaDynamic,
} from '@instacart/ids-customers';
import { AddRatingReviewParams } from 'shop-api-sdk';

export type AddRatingReviewProps = {
  authorized: boolean;
  configOverrides?: RatingsConfig;
  onSubmitRatingReview: (data?: AddRatingReviewParams) => void;
};

const getAriaLabel = (i: number) => {
  if (i === 0) return 'Select to rate product one star';
  if (i === 1) return 'Select to rate product two stars';
  if (i === 2) return 'Select to rate product three stars';
  if (i === 3) return 'Select to rate product four stars';
  return 'Select to rate product five stars';
};

const STARTS = [...Array(MAX_RATING).keys()].map((i) => ({
  value: i + 1,
  label: getAriaLabel(i),
}));

const useStyles = (overrides?: RatingsConfig) => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        border-top: 1px solid;
        border-color: ${theme.colors.systemGrayscale20};
        margin-top: ${spacing.s16}px;
        padding: ${spacing.s16}px 0;
      `,
      starRatingText: css`
        ${theme.typography.subtitle};
      `,
      startButton: css`
        border: none;
        background: transparent;
        cursor: pointer;
        padding: 0;
      `,
      starButtonGroup: css`
        display: flex;
        flex-wrap: nowrap;
        gap: ${spacing.s4}px;
      `,
      icon: css`
        width: ${spacing.s24}px;
        height: ${spacing.s24}px;
        fill: ${getColor(
          theme,
          overrides?.styles?.filled?.fill ?? 'brandExpressRegular',
        )};
      `,
      addReviewButton: css`
        margin: ${spacing.s8}px;
        width: 200px;
        height: ${spacing.s48}px;

        span {
          ${theme.typography.bodyMedium1};
        }
      `,
      reviewForm: css`
        margin-top: ${spacing.s16}px;
      `,
      titleInput: {
        wrapper: css`
          margin-bottom: ${spacing.s16}px;
        `,
        input: css`
          color: ${theme.colors.brandSecondaryDark};
        `,
      },
      messageLabel: css`
        ${theme.typography.bodyMedium2};
        display: block;
      `,
      messageHelperText: css`
        ${theme.typography.bodySmall2};
        margin-bottom: ${spacing.s4}px;
        display: block;
      `,
      formActions: css`
        display: flex;
        align-items: center;
        gap: ${spacing.s8}px;
        margin-top: ${spacing.s16}px;

        button {
          font-weight: 600;
          max-width: 250px;
        }
      `,
    }),
    [theme, overrides],
  );
};

export default memo(function AddRatingReview({
  authorized,
  configOverrides,
  onSubmitRatingReview,
}: AddRatingReviewProps) {
  const styles = useStyles(configOverrides);
  const [localRating, setLocalRating] = useState(0);
  const [rating, setRating] = useState(0);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const onMouseEnterStar = (value: number) => () => {
    setLocalRating(value);
  };

  const onMouseLeave = () => {
    setLocalRating(0);
  };

  const onClickStarButton = (value: number) => () => {
    if (authorized) {
      setRating(value);
      setShowReviewForm(true);
    } else {
      onSubmitRatingReview();
    }
  };

  const onSubmit = () => {
    if (authorized) {
      setShowReviewForm(true);
    } else {
      onSubmitRatingReview();
    }
  };

  const onChangeTitle = (evt: ChangeEvent<HTMLInputElement>) => {
    setTitle(evt.target.value);
  };

  const onChangeReviewMessage = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(evt.target.value);
  };

  const onCancelReview = () => {
    setShowReviewForm(false);
    setTitle('');
    setMessage('');
  };

  const enableSubmit = useMemo(
    () => rating > 0 && message && message.length <= 500,
    [message, rating],
  );

  const onSubmitForm = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (enableSubmit) {
      onSubmitRatingReview({ title, rating, message });
    }
  };

  return (
    <div css={styles.root} data-testid="rating-review-form">
      <span css={styles.starRatingText}>Enter star rating*</span>
      <div css={styles.starButtonGroup} onMouseLeave={onMouseLeave}>
        {STARTS.map(({ value, label }) => (
          <button
            key={value}
            aria-label={label}
            css={styles.startButton}
            onMouseEnter={onMouseEnterStar(value)}
            onClick={onClickStarButton(value)}
          >
            {value <= localRating || (value <= rating && !localRating) ? (
              <RatingIcon css={styles.icon} data-testid="filled-icon" />
            ) : (
              <RatingEmptyIcon css={styles.icon} />
            )}
          </button>
        ))}

        {!showReviewForm ? (
          <PrimaryButton
            css={styles.addReviewButton}
            data-testid="add-review-button"
            onClick={onSubmit}
          >
            Add Review
          </PrimaryButton>
        ) : null}
      </div>
      {showReviewForm ? (
        <form
          css={styles.reviewForm}
          onSubmit={onSubmitForm}
          data-testid="add-review-form"
        >
          <TextInputBase
            value={title}
            label="Title"
            onChange={onChangeTitle}
            styles={styles.titleInput}
          />
          <label htmlFor="reviewMessage">
            <span css={styles.messageLabel}>Tell Us What You Thought*</span>
            <span css={styles.messageHelperText}>Minimum 50 characters</span>
          </label>
          <TextAreaDynamic
            id="reviewMessage"
            onChange={onChangeReviewMessage}
            value={message}
            placeholder="Did you love it? Let us know! If there are improvements that could be made, we want to hear about those too! At Sprouts, we value your feedback and use it to impact the new, exciting products we're constantly sourcing."
            errorTextId="reviewMessage"
            data-testid="review-message-input"
          />
          <div css={styles.formActions}>
            <PrimaryButton
              disabled={!enableSubmit}
              data-testid="submit-review-button"
            >
              Submit Review
            </PrimaryButton>
            <SecondaryButton
              type="button"
              onClick={onCancelReview}
              data-testid="cancel-review-button"
            >
              Cancel
            </SecondaryButton>
          </div>
        </form>
      ) : null}
    </div>
  );
});
