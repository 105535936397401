import { css } from '@emotion/react';
import { responsive, spacing, useTheme } from '@instacart/ids-core';
import { memo, useMemo } from 'react';
import ItemRatings from './common/Ratings';
import ItemReviews from './common/Reviews';
import { RatingsConfig } from 'widget-wrappers/types';
import {
  ProductRating,
  ProductReviews,
  ProductUnitRatings,
} from 'shop-api-sdk';

export const ITEM_RATINGS_REVIEWS_CONTAINER_ID = 'item-ratings-reviews';

export type ItemRatingsReviewsProps = {
  configOverrides?: RatingsConfig;
  productReviews?: ProductReviews;
  productRating?: ProductRating;
  productUnitRatings?: ProductUnitRatings;
  onLoadMoreReviews?: () => void;
  onChangeOrderForReviews: (value: string) => void;
};

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      root: css`
        border-top: 1px solid;
        border-color: ${theme.colors.systemGrayscale20};
        margin-top: ${spacing.s16}px;
        padding: ${spacing.s16}px 0;
        display: flex;
        flex-wrap: wrap;
        gap: ${spacing.s24}px;

        ${responsive.down('c')} {
          flex-direction: column-reverse;
        }
      `,
    }),
    [theme],
  );
};

export default memo(function ItemRatingsReviews({
  configOverrides,
  productReviews,
  productRating,
  productUnitRatings,
  onLoadMoreReviews,
  onChangeOrderForReviews,
}: ItemRatingsReviewsProps) {
  const styles = useStyles();

  if (!productRating && !productReviews && !productUnitRatings) return null;

  return (
    <div
      css={styles.root}
      id={ITEM_RATINGS_REVIEWS_CONTAINER_ID}
      data-testid={ITEM_RATINGS_REVIEWS_CONTAINER_ID}
    >
      {productReviews ? (
        <ItemReviews
          configOverrides={configOverrides}
          productReviews={productReviews}
          onClickSeeMore={onLoadMoreReviews}
          onChangeOrderForReviews={onChangeOrderForReviews}
        />
      ) : null}
      <ItemRatings
        productRating={productRating}
        productUnitRatings={productUnitRatings}
        configOverrides={configOverrides}
      />
    </div>
  );
});
