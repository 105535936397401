import { css } from '@emotion/react';
import { useTheme } from '@instacart/ids-core';
import { memo, useMemo } from 'react';
import ItemReviewItem from './ReviewItem';
import { SecondaryButton } from '@instacart/ids-customers';
import { RatingsConfig } from 'widget-wrappers/types';
import SortSelection from './SortSelection';
import { ProductReviews } from 'shop-api-sdk';

const useStyles = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      root: css`
        flex: 7;
      `,
      reviewsHeader: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `,
      sectionTitle: css`
        ${theme.typography.titleMedium}
      `,
      sort: css`
        background: ${theme.colors.systemGrayscale00};
      `,
      noListStyle: css`
        margin: 0px;
        padding: 0px;
        list-style: none;
      `,
      seeMoreButton: css`
        width: 100%;
      `,
    };
  }, [theme]);
};

export type ItemReviewsProps = {
  onClickSeeMore?: () => void;
  onChangeOrderForReviews: (value: string) => void;
  configOverrides?: RatingsConfig;
  productReviews: ProductReviews;
};

export default memo(function ItemReviews({
  onClickSeeMore,
  configOverrides,
  productReviews,
  onChangeOrderForReviews,
}: ItemReviewsProps) {
  const styles = useStyles();

  const viewMoreString = useMemo(() => {
    const remains = productReviews.total_amount - productReviews.reviews.length;
    if (remains > 0) {
      return `See more reviews (${remains})`;
    }
    return null;
  }, [productReviews]);

  return (
    <div css={styles.root}>
      <div css={styles.reviewsHeader}>
        <div css={styles.sectionTitle}>Reviews</div>
        <div css={styles.sort}>
          <SortSelection
            onSelect={onChangeOrderForReviews}
            value={productReviews.order_by}
          />
        </div>
      </div>
      <ul data-testid="reviewsList" css={styles.noListStyle}>
        {productReviews.reviews.map((review) => (
          <li key={review.id}>
            <ItemReviewItem
              rating={review.rating}
              title={review.title}
              content={review.content}
              meta={review.meta}
              configOverrides={configOverrides}
            />
          </li>
        ))}
      </ul>
      {productReviews?.page_info?.has_next_page && viewMoreString ? (
        <SecondaryButton
          css={styles.seeMoreButton}
          data-testid="view-more-item-reviews"
          onClick={onClickSeeMore}
        >
          {viewMoreString}
        </SecondaryButton>
      ) : null}
    </div>
  );
});
