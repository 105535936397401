import { css } from '@emotion/react';
import {
  ChevronDownIcon,
  DeliveryIcon,
  PickupIcon,
  WalkIcon,
  responsive,
  spacing,
} from '@instacart/ids-core';
import { useCallback, useMemo, useState } from 'react';
import FulfillmentDropdown from './common/fulfillmentDropdown';
import FulfillmentOptions from './common/FulfillmentOptions';
import { ShopData } from 'shop-api-sdk';
import {
  type Image,
  type FulFillmentSelectorConfig,
} from '../../../widget-wrappers/config';

export type FulfillmentSelectorProps = {
  activeFulfillmentOption?: string;
  postalCode?: string;
  lastActiveShop?: ShopData;
  configOverride?: FulFillmentSelectorConfig;
};

export type FulfillmentDataType = {
  name: string;
  displayName: string;
  link: string;
  isActive: boolean;
  actionPrompt?: string;
  fulfillmentAddress?: string;
  icon?: Image;
};

const useStyles = (displayOnly?: boolean) => {
  return useMemo(
    () => ({
      button: css`
        align-items: center;
        display: grid;
        grid-template-columns: 3fr 0.5fr;
        background: transparent;
        border: none;
        padding: ${spacing.s12}px ${spacing.s16}px;
        ${!displayOnly && 'cursor: pointer;'}
      `,
      contentLeft: css`
        display: flex;
        flex-direction: column;
        justify-content: center;

        ${responsive.down('r')} {
          flex-direction: column;
          align-items: flex-start;
        }
      `,
      contextSubtext: css`
        font-size: 12px;
        line-height: 18px;
        text-align: left;
      `,
      contextText: css`
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        text-align: left;
      `,
      iconContainer: css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-left: ${spacing.s8}px;
        justify-content: end;
      `,
    }),
    [displayOnly],
  );
};
const baseUrl = import.meta.env.VITE_SHOP_HOST_URL;

const defaultFulfillmentData: Array<FulfillmentDataType> = [
  {
    name: 'delivery',
    displayName: 'Delivery',
    link: baseUrl + '/delivery',
    isActive: false,
    icon: DeliveryIcon,
  },
  {
    name: 'pickup',
    displayName: 'Pickup',
    link: baseUrl + '/pickup',
    isActive: false,
    icon: PickupIcon,
  },
  {
    name: 'instore',
    displayName: 'In-Store',
    link: baseUrl + '/in-store',
    isActive: false,
    actionPrompt: 'Plan your shop',
    icon: WalkIcon,
  },
];

export default function FulfillmentSelector({
  activeFulfillmentOption,
  postalCode,
  lastActiveShop,
  configOverride,
}: FulfillmentSelectorProps) {
  const styles = useStyles(configOverride?.displayOnly);
  const [showDropdown, setShowDropdown] = useState(
    configOverride?.ippPreview?.isDropdownOpen ?? false,
  );

  const closeDropdown = useCallback(() => setShowDropdown(false), []);

  const fulfillmentAddress = useMemo(() => {
    const shopAddress = lastActiveShop
      ? `${lastActiveShop.address.street_address} · ${lastActiveShop.address.city}`
      : '';
    return activeFulfillmentOption === FulfillmentOptions.delivery
      ? postalCode
      : activeFulfillmentOption === FulfillmentOptions.pickup
        ? shopAddress
        : activeFulfillmentOption === FulfillmentOptions.inStore
          ? shopAddress
          : '';
  }, [activeFulfillmentOption, lastActiveShop, postalCode]);

  const fulfillmentOption = useMemo(() => {
    return activeFulfillmentOption === FulfillmentOptions.delivery
      ? 'Delivery'
      : activeFulfillmentOption === FulfillmentOptions.pickup
        ? 'Pickup'
        : activeFulfillmentOption === FulfillmentOptions.inStore
          ? 'In-Store'
          : '';
  }, [activeFulfillmentOption]);

  const fulfillmentData = useMemo(
    () =>
      defaultFulfillmentData.map((data) => {
        if (data.name === activeFulfillmentOption) {
          return { ...data, isActive: true, fulfillmentAddress };
        }
        return { ...data, isActive: false };
      }),
    [activeFulfillmentOption, fulfillmentAddress],
  );

  return (
    <>
      <div data-testid="fulfillment-selector">
        <button
          css={styles.button}
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          <div css={styles.contentLeft}>
            <span css={styles.contextText}>{fulfillmentOption}</span>
            <span css={styles.contextSubtext}>{fulfillmentAddress}</span>
          </div>
          <div css={styles.iconContainer}>
            <ChevronDownIcon />
          </div>
        </button>
      </div>
      {showDropdown && !configOverride?.displayOnly && (
        <FulfillmentDropdown
          show={showDropdown}
          onClose={closeDropdown}
          data={fulfillmentData}
        />
      )}
    </>
  );
}
