import { createContext } from 'react';
import { UserData } from 'shop-api-sdk';

type AuthContextValue = {
  authState: boolean;
  setAuthState: (value: boolean) => void;
  user: UserData | null;
  setUser: (value: UserData) => void;
  handleAuth: () => void;
};

export const AuthContext = createContext<AuthContextValue>({
  authState: false,
  setAuthState: () => {},
  user: null,
  setUser: () => {},
  handleAuth: () => {},
});
