import { useState, useEffect } from 'react';
import { getCartsPersonal, CartsPersonalData } from 'shop-api-sdk';

type CartsPersonalState = {
  loading: boolean;
  data?: CartsPersonalData | undefined;
  error?: unknown;
};

export default function useCartsPersonal(): CartsPersonalState {
  const [cartsPersonal, setCartsPersonal] = useState<CartsPersonalState>({
    loading: false,
  });

  useEffect(() => {
    const fetchCartsPersonal = async () => {
      try {
        setCartsPersonal((cartsPersonal) => ({
          ...cartsPersonal,
          loading: true,
        }));
        const cartsData = await getCartsPersonal();
        setCartsPersonal({
          data: cartsData,
          loading: false,
        });
      } catch (error) {
        setCartsPersonal({
          error: error,
          loading: false,
        });
      }
    };

    void fetchCartsPersonal();
  }, []);

  return cartsPersonal;
}
