import { Dispatch, SetStateAction, createContext } from 'react';

type ContextState = {
  typedText: string;
  setTypedText: Dispatch<SetStateAction<string>>;
  lastTypedText: string;
  setLastTypedText: Dispatch<SetStateAction<string>>;
  dropdownVisible: boolean;
  setDropdownVisible: Dispatch<SetStateAction<boolean>>;
  submitForm: (value: string) => void;
};

const nope = () => {};

export const SearchContext = createContext<ContextState>({
  typedText: '',
  setTypedText: nope,
  lastTypedText: '',
  setLastTypedText: nope,
  dropdownVisible: false,
  setDropdownVisible: nope,
  submitForm: nope,
});

SearchContext.displayName = 'SearchContext';
