import { useCallback, useMemo, useRef, useState } from 'react';
import { Autosuggestion, getAutosuggestions } from 'shop-api-sdk';
import { config } from '../../config';
import { useConfig } from '../../common/hooks/useConfig';

export const useAutosuggestions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<Array<Autosuggestion>>([]);
  const ctrl = useRef<AbortController | null>();

  const doFetch = useCallback(async (term: string, limit = 10) => {
    if (ctrl.current) {
      ctrl.current.abort();
      ctrl.current = null;
    }
    if (term) {
      try {
        setIsLoading(true);
        ctrl.current = new AbortController();
        const signal = ctrl.current.signal;
        const results = await getAutosuggestions({ term, limit }, { signal });
        setSuggestions(results);
      } catch (error) {
        setSuggestions([]);
      } finally {
        setIsLoading(false);
        ctrl.current = null;
      }
    } else {
      setSuggestions([]);
    }
  }, []);

  return { isLoading, suggestions, doFetch };
};

const SEARCH_TERM_QUERY_PARAM_KEY = 'k';
export const useTermFromSearchString = () => {
  const { configOverride } = useConfig();

  const initTerm = useMemo(() => {
    const ippPreviewQuery = configOverride.searchBar?.ippPreview?.query;
    if (ippPreviewQuery) return ippPreviewQuery;

    const queryString = window.location.href.split('?')[1];
    const urlParams = new URLSearchParams(queryString || '');
    return urlParams.get(SEARCH_TERM_QUERY_PARAM_KEY) || '';
  }, [configOverride]);

  const submitTerm = useCallback((value: string) => {
    const url = `${config.searchUrl}${value}`;
    window.open(`${url}`, '_self');
  }, []);

  return useMemo(() => ({ initTerm, submitTerm }), [initTerm, submitTerm]);
};
