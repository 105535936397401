import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FulfillmentDataType } from '..';
import { css } from '@emotion/react';
import { responsive, spacing } from '@instacart/ids-core';
import FulfillmentDropdownItem from './fulfillmentDropdownItem';

type IProps = {
  show: boolean;
  onClose: () => void;
  data: Array<FulfillmentDataType>;
};

const useStyles = () => {
  return useMemo(() => {
    return {
      dropdownRoot: css`
        z-index: 9999;
        position: relative;
      `,
      dropdownContainer: css`
        position: absolute;
        right: 0;
        padding-bottom: ${spacing.s16}px;
        width: 320px;

        ${responsive.down('c')} {
          width: 100%;
        }
      `,
      dropdownPanel: css`
        align-items: flex-start;
        flex-direction: column;
        border-width: 2px;
        display: flex;
        box-shadow: 0px 0px ${spacing.s16}px rgba(0, 0, 0, 0.16);
        padding: ${spacing.s8}px;
        background-color: #ffffff;
        border-radius: 8px;
        box-sizing: border-box;

        ${responsive.down('c')} {
          border-radius: ${spacing.s12}px;
          border-radius: 0px;
        }
      `,
    };
  }, []);
};

export default function FulfillmentDropdown({ show, onClose, data }: IProps) {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  const handleOutsideClick = useCallback(
    (evt: MouseEvent) => {
      const target = evt?.target as Node;
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(target) &&
        target.nodeName !== 'INSTACART-FULFILLMENT-SELECTOR'
      ) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (show) {
      document.addEventListener('click', handleOutsideClick, true);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [show, handleOutsideClick]);

  if (!show) return null;

  return (
    <div
      data-testid="fulfillment-dropdown"
      ref={dropDownRef}
      css={styles.dropdownRoot}
    >
      <div css={styles.dropdownContainer}>
        <div
          css={styles.dropdownPanel}
          aria-labelledby="dropdown-menu-panel"
          id="dropdown"
          role="menu"
        >
          {data.map((data: Omit<FulfillmentDataType, 'name'>, index) => (
            <FulfillmentDropdownItem key={index} {...data} />
          ))}
        </div>
      </div>
    </div>
  );
}
