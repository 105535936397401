import { css } from '@emotion/react';
import { memo, useMemo } from 'react';
import { RatingIcon, useTheme } from '@instacart/ids-core';
import { RatingsConfig } from 'widget-wrappers/types';
import { getColor } from '../../utils';
import { roundRating } from '../utils';
import { MAX_RATING, RATING_FILLED_STYLES } from './constants';

export type StarRatingsProps = {
  rating: number;
  configOverrides?: RatingsConfig;
};

const useStyles = (props: StarRatingsProps) => {
  const theme = useTheme();

  return useMemo(() => {
    const { rating, configOverrides } = props;
    const { styles } = configOverrides ?? {};

    const iconWidth = styles?.icon?.width ?? '16px';
    const iconHeight = styles?.icon?.height ?? '16px';

    const filled = styles?.filled?.fill ?? 'brandExpressRegular';
    const filledColor = getColor(theme, filled);

    const unfilled = styles?.unfilled?.fill ?? 'systemGrayscale30';
    const unfilledColor = getColor(theme, unfilled);

    return {
      root: css`
        line-height: 1;
        position: relative;
        white-space: nowrap;
      `,
      icons: css`
        :nth-of-type(2) {
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: ${rating ? (rating / MAX_RATING) * 100 : '0'}%;
        }
      `,
      icon: css`
        width: ${iconWidth};
        height: ${iconHeight};

        &.unfilled {
          fill: ${unfilledColor};
        }

        &.filled {
          fill: ${filledColor};
        }
      `,
    };
  }, [props, theme]);
};

const stars = [...Array(MAX_RATING).keys()];

function StarRatings(props: StarRatingsProps) {
  const { rating } = props;
  const styles = useStyles(props);

  const label = useMemo(() => {
    const roundedRating = roundRating(rating);
    return `Rating ${roundedRating} out of ${MAX_RATING}`;
  }, [rating]);

  return (
    <div css={styles.root} aria-label={label} data-testid="rating" role="img">
      {RATING_FILLED_STYLES.map((filled) => (
        <div css={styles.icons} key={filled} aria-hidden>
          {stars.map((i) => (
            <RatingIcon key={i} css={styles.icon} className={filled} />
          ))}
        </div>
      ))}
    </div>
  );
}

const MemorizedStarRatings = memo(StarRatings);

export default MemorizedStarRatings;
